#card-module {
    padding-bottom: 150px;
}

#card-module-manipulator {
    display: flex;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    padding: 5px 0;
    font-size: 20px;
}

#card-module-manipulator #refresh-cards,
#card-module-manipulator #sort-cards,
#card-module-manipulator #filter-cards {
    display: flex;
    align-content: center;
    align-items: center;
}

#card-module-manipulator #refresh-cards button,
#card-module-manipulator #sort-cards button,
#card-module-manipulator #filter-cards button {
    color: #e2f8ff;
    font-size: 20px;
    text-decoration: none;
}

#card-module-manipulator #sort-cards {
    border-right: 1px solid grey;
}

#card-module-manipulator .label {
    color: #ffdf81
}

#card-module-manipulator #filter-groups {
    display: flex;
    align-content: center;
    align-items: center;
}

#card-module-manipulator #filter-cards .filter-group {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0 15px;
}

#card-module-manipulator #filter-cards .filter-group button {
    padding: 10px 7px;
    opacity: 0.6;
}

#card-module-manipulator #filter-cards .filter-group button.selected {
    opacity: 1;
}

#card-module-manipulator #filter-cards button.secondary.red {
    color: rgb(227, 48, 84);
}

#card-module-manipulator #filter-cards button.secondary.green {
    color: rgb(218, 235, 109);
}

#card-module-manipulator #filter-cards button.secondary.blue {
    color: #22c2d0;
}


#minted-cards-list, #preminted-cards-list {
    flex: 5;
    display: flex;
}

#minted-cards-list {
    border-right: 1px solid grey;
    /*padding-left: 37px;*/
}

#preminted-cards-list {
    /*padding-right: 37px;*/
    margin-right: calc(2.34375% * 2);
}

#card-module #pre-minted-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

#card-module .items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100%;
}

#card-module h4 {
    color: #c3cace;
    text-align: left;
    font-size: 1.6rem;
    margin-left: 3.2rem;
}

#card-module h4 span {
    display: inline;
    color: #ffdf81
}

#card-module .items .item-card {
    width: 30.72%;
    margin: 0px;
    float: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-left: 1.76%;
    margin-bottom: calc(100vw / 2560 * 11);
}

@media screen and (max-width: 1400px) {

    #card-module .items .item-card {
        width: 45.72%;
    }

    #card-module-manipulator #sort-cards {
        border-right: none;
    }

}

@media screen and (max-width: 900px) {
    #card-module-lists {
        flex-direction: column;
    }

    #card-module .items .item-card {
        width: 30.72%;
    }

    #minted-cards-list {
        border-right: none;
        border-bottom: 1px solid grey;
    }
}

@media screen and (max-width: 510px) {
    #card-module .items .item-card {
        width: 45.72%;
    }
}

@media screen and (max-width: 370px) {
    #card-module-manipulator #filter-cards .filter-group {
        flex-wrap: wrap;
        justify-content: center;
    }

    #card-module-manipulator #filter-cards .filter-group .item-filter {
        clear: right;
        flex-basis: 50%;
    }

    #card-module-manipulator #sort-cards {
        flex-wrap: wrap;
        justify-content: center;
    }

    #card-module-manipulator #sort-cards span {
        flex-basis: 100%;
        justify-content: center;
    }

    #card-module-manipulator #sort-cards button {
        flex-basis: 50%;
    }
}