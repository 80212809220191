#inventory #arsenal-selection,
#duel-result .duel-selected-set {
    display: flex;
    flex: 300;
    margin: auto;
    /*background-color: rgba(40, 231, 17, 0.41);*/
    color: #c3cace;
    font-size: 2.0rem;
    height: 100%;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

#duel-result .duel-selected-set {
    flex: 300;
    /*background-color: rgba(40, 231, 17, 0.41);*/
}

#arsenal-selection .square-inventory-wrap,
.duel-selected-set .square-inventory-wrap {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

#arsenal-selection .square-inventory-wrap img {
    margin-left: 15px;
    width: 170px;
    transition: all 0.6s ease;
    /*animation:  0.6s ease;*/
    /*animation: flipHeads 3s ease-out forwards;*/
}

.duel-selected-set .square-inventory-wrap img {
    margin-left: 15px;
    width: 155px;
}

#arsenal-selection .square-inventory-wrap .square-inventory-damage,
.duel-selected-set .square-inventory-wrap .square-inventory-damage {
    width: 115px;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    align-content: center;
    font-size: 2.4rem;
    color: #cecece;
}

.duel-selected-set .square-inventory-wrap .square-inventory-damage {
    /*width: 75px;*/
    font-size: 2.2rem;
}

#arsenal-selection .selected-items-list,
.duel-selected-set .selected-items-list {
    display: flex;
    margin: auto;
}

#arsenal-selection .selected-items-list {
    margin-bottom: -45px;
}

#arsenal-selection .selected-uid-info {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: auto auto 15px;
    width: 100%;
    /*background-color: rgba(255, 0, 0, 0.27);*/
}

.duel-selected-set .selected-uid-info {
    display: none;
}

#arsenal-selection .square-inventory-wrap .square-inventory-damage.red,
.duel-selected-set .square-inventory-wrap .square-inventory-damage.red {
    color: #ff5b67;
}

#arsenal-selection .square-inventory-wrap .square-inventory-damage.green,
.duel-selected-set .square-inventory-wrap .square-inventory-damage.green {
    color: #d0ff7e;
}

#arsenal-selection .square-inventory-wrap .square-inventory-damage.blue,
.duel-selected-set .square-inventory-wrap .square-inventory-damage.blue {
    color: #6cc9ff;
}


@media screen and (max-width: 1400px) {
    #arsenal-selection .square-inventory-wrap .square-inventory-damage span {
        display: none;
    }
}

@media screen and (max-width: 900px) {

    #arsenal-selection .square-inventory-wrap {
        width: 25%;
    }

    /*.duel-selected-set .square-inventory-wrap {*/
    /*    width: 25%;*/
    /*}*/

    #arsenal-selection .square-inventory-wrap img {
        width: 95%;
        width: calc(100% - 30px);
        margin-right: 15px;
    }
}

@media screen and (max-width: 725px) {
    #arsenal-selection {
        width: 100%;
    }
}

@media screen and (max-width: 620px) {
    #arsenal-selection .square-inventory-wrap, .duel-selected-set .square-inventory-wrap {
        width: 24.5%;
    }
}

@media screen and (max-width: 510px) {
    #arsenal-selection .square-inventory-wrap {
        width: 18%;
    }
}