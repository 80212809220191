#arena-result-page {
    background-color: rgba(133, 65, 62, 0.7);
    /* background: url("https://asset-host.b-cdn.net/assets/inventory/plants_top_left.png") left top no-repeat, url("https://asset-host.b-cdn.net/assets/inventory/plants_top_right.png")  right top no-repeat, url("https://asset-host.b-cdn.net/assets/arena-res/BG.png") center top no-repeat; */
    /*background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;*/
    background:url("https://asset-host.b-cdn.net/assets/arena-res/BG.png") center top no-repeat;
    background-size: auto, auto, cover;
    height: 100%;
    flex: 1;
    display: flex;
    /*flex-direction: column;*/
    overflow-x: hidden;
    overflow-x: clip;
    /*overflow: clip;*/
}

#arena-result-page #footer {
    height: 33vw;
    margin-top: auto;
    bottom: 0;
    position: absolute;
    width: 100vw;
    background-image: url("/public/assets/land_arens_2.png");
    background-repeat: no-repeat;
    background-size: 100% 205%;
    /* background-position-y: bottom; */
    background-position: bottom -35vw center;
    z-index: 3;
}


#left-portal, #right-portal {
    /*background-color: rgba(142, 159, 196, 0.44);*/
    width: 34%;
    height: 100%;
}

#middle-panel {
    width: 31.25%;
    margin: 0 -7.984375%; /*-8.984375*/
    z-index: 30;
    display: flex;
    align-items: center;
}

#left-portal .stone-wrapper,
#right-portal .stone-wrapper {
    height: 100%;
    /* width: 83.10344827586207%; */
    width: 100%;
    display: flex;
    margin-left: auto;
    position: relative;
    flex-direction: column;
}

/*

    */

#right-portal, #left-portal {
    /* min-width: 880px; */
    /*width: 83.10344827586207%;*/
    height: 100%;
    margin-left: auto;
    /* background-image: url(/public/assets/inventory/aspect-ratio/portal.png); */
    /* background-image: url(/public/assets/inventory/inventory_portal.png); */
    background-image: url(https://asset-host.b-cdn.net/assets/arena/portal_right.png);
    background-size: 100% 27%;
    background-position: 0 102.9%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    opacity: 0.1;
}

#left-portal {
    transform: rotateY(180deg);
}

.banana-id-wrapper {
    margin-right: 15px;
    margin-left: auto;
    margin-top: 5%;
    background-color: rgba(59, 10, 10, 0.37);
    color: #ce4540;
    font-size: 17pt;
    padding: 1px 7px;
    font-family: "MusticaPro", sans-serif, Serif;
}

#left-portal .banana-id-wrapper {
    /*margin-left: 15px;*/
    /*margin-right: auto;*/
    transform: rotateY(180deg);
}


#middle-buffer-top {
    height: 0;
    width: 100%;
    /*background-color: rgba(255, 255, 255, 0.2);*/
}

#middle-panel-contents {
    width: 90%;
    aspect-ratio: 398 / 495;
    background: rgb(98,31,46);
    background: radial-gradient(circle, rgba(98,31,46,0.9374124649859944) 9%, rgba(49,8,18,0.9262079831932774) 59%);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 10% 0 0 0 ;

    font-family: MusticaPro, courier, sans-serif, Serif;
    border: 0.15vw solid #ffb371;
}

#middle-panel-contents .flex-row {
    width: 100%;
}

#middle-panel-contents #game-result-title {
    height: 24.44%;
    /*background-color: rgba(138, 43, 226, 0.42);*/
}

#middle-panel-contents #game-result-title h2 {
    font-family: "PassionOne", sans-serif, Serif;
    font-weight: normal;
    text-align: center;
    font-size: 7vw;
    margin: auto;
    /*margin-top: 1%;*/
    flex: 1 1 0%;
    background: -webkit-linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background: -moz-linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background: linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

#middle-panel-contents #game-result-numbers {
    height: 46.97%;
    /*background-color: rgba(255, 127, 80, 0.47);*/
}

#middle-panel-contents #game-result-numbers .flex-row {
    width: 100%;
}

#middle-panel-contents #game-result-numbers #numbers-top-buffer {
    height: 3.5%;
}

#middle-panel-contents .divider {
    height: 3px;
    background: rgb(255, 252, 240);
    background: linear-gradient(90deg, rgba(255, 252, 240, 0) 12.5%, rgba(255, 185, 100, 1) 51%, rgba(255, 252, 240, 0) 87.5%);
}


#middle-panel-contents #game-result-numbers #numbers-first-row {
    /*background-color: rgba(255, 255, 0, 0.44);*/
    height: 40%;
}

#middle-panel-contents #game-result-numbers #numbers-total-result {
    height: 13%;
    margin-top: -1%;
    margin-bottom: -1%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

#middle-panel-contents #game-result-numbers #numbers-second-row {
    /*background-color: rgba(0, 255, 4, 0.44);*/
    height: 40%;
}

#middle-panel-contents .damage-circle {
    width: 25%;
    margin: 0 6%;
    /*background-color: rgba(220, 20, 60, 0.55);*/
}

#middle-panel-contents .damage-circle.left {
    margin-right: 0;
}

#middle-panel-contents .damage-circle.right {
    margin-left: 0;
}

#middle-panel-contents .damage-circles-middle .damage-line, #middle-panel-contents .damage-circle {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#middle-panel-contents .damage-line {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: red;
    font-size: 24pt;
}

#middle-panel-contents .damage-number {
    font-size: 1vw;
}

#middle-panel-contents .damage-number.green {
    color: #afcf77;
}

#middle-panel-contents .damage-number.blue {
    color: #91c1cf;
}

#middle-panel-contents .damage-number.red {
    color: #f16072;
}

#middle-panel-contents .damage-circle.defence {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/circle_shield_1.png");
    /*background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/arrows_1.png");*/
}

/*#middle-panel-contents .damage-circle.defence.right {*/
/*    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/circle_shield_2.png");*/
/*}*/
/*#middle-panel-contents .damage-circle.attack.left {*/
/*    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/circle_sword_1.png");*/
/*}*/

#middle-panel-contents .damage-circle.attack {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/circle_sword_2.png");
    /*background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/arrows_2.png");*/
}

#middle-panel-contents .damage-circles-middle .damage-line.left.green {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/line_green_left.png");
}

#middle-panel-contents .damage-circles-middle .damage-line.left.red {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/line_red_left.png");
}

#middle-panel-contents .damage-circles-middle .damage-line.right.green {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/line_green_right.png");
}

#middle-panel-contents .damage-circles-middle .damage-line.right.red {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/line_red_right.png");
}

#middle-panel-contents .damage-circle {
    display: flex;
    flex-direction: column;
}

#middle-panel-contents .damage-circle .damage-number-wrap.top {
    margin-top: 0;
}

#middle-panel-contents .damage-circle .damage-number-wrap.bottom {
    margin-bottom: 17.5%;
    margin-top: auto;
    display: flex;
}

#middle-panel-contents .damage-circle .damage-number-wrap .damage-number.left {
    margin-left: 5%;
    margin-right: auto;
}

#middle-panel-contents .damage-circle .damage-number-wrap .damage-number.right {
    margin-left: auto;
    margin-right: 7%;
}

#middle-panel-contents .damage-circles-middle {
    flex: 1;
}

#middle-panel-contents #numbers-total-result {
    font-size: 3vw;
    color: #fec96f;
}

#middle-panel-contents .damage-circles-middle .damage-line-text {
    font-size: 2vw;
    display: flex;
    padding: 0 3%;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #fec96f;
}

#middle-panel-contents .damage-circles-middle .damage-line-text.green {
    color: #afcf77;
}

#middle-panel-contents .damage-circles-middle .damage-line-text.red {
    color: #f16072;
}

.damage-circles-middle .damage-line {
    flex: 1;
}

#right-portal #banan-full-size,
#left-portal #banan-full-size,
#right-portal #banan-full-size .assembly,
#left-portal #banan-full-size .assembly {
    width: 100%;
}

#right-portal #banan-full-size .assembly,
#left-portal #banan-full-size .assembly {
    height: 845px;
}

#game-result-button-container, #game-result-button-wrapper, #game-result-button-wrapper button {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

/* 796*990 */

#game-result-button-container {
    height: 35.455%;
    margin-bottom: -8.865%; /* should be 6.865 */
    width: 100%;
    /*background-color: rgba(255, 255, 0, 0.44);*/
}

#game-result-button-wrapper { /* 332*351 */
    /*background-color: aliceblue;*/
    width: 41.70854271356784%;
    height: 100%;
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/button_PlayAgain.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

#game-result-button-wrapper button {
    height: 80%;
    width: 80%;
    border-radius: 50%;
    margin: 10% 10%;
    background: none;
    border: none;
    font-family: "PassionOne", sans-serif;
    font-size: 2.5vw;
    padding-left: 5%;
    padding-top: 3%;
    color: #fecb70;
    text-shadow: rgb(0, 0, 0) 2px 2px 2px;
}

#game-result-button-wrapper button:hover {
    cursor: pointer;
    color: #ffe3b1;
    /*text-shadow: rgb(255, 227, 177) 2px 2px 2px;*/
}

#game-result-button-wrapper button span.play {
    font-size: 3vw;
    line-height: 4vw;
}
#arena-result-page #footer {
    display: flex;
}

.banana-wrapper {
    width: 41.015625%;
    height: 100%;
    position: relative;
    margin-right: 0;
    margin-left: auto;
    z-index: 33;
    pointer-events: none;
}

.banana-wrapper#left-arena-portal {
    transform: rotateY(180deg);
    margin-left: 0;
    margin-right: auto;
}

.banana-wrapper img {
    width: 100%;
    height: auto;
}

.banana-container {
    width: 95%;
    height: auto;
    position: absolute;
    bottom: 8.872727272727273%;
    left: 3vw;

    z-index: 15;
}


.banana-wrapper .ground-front {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/stones_front_right.png");
    width: 100%;
    height: 25%;
    bottom: 0;
    z-index: 22;
    margin: auto 0 0 auto;
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: auto;
}

.banana-wrapper#left-arena-portal .ground-front {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-res/stones_front_left.png");
    transform: rotateY(180deg);
    background-position-x: left;
}

.banana-wrapper .coconut {
    /*width: 100%;*/
    width: auto;
    height: 15vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: absolute;
    bottom: 1vh;
    z-index: 33;

}

.banana-wrapper#left-arena-portal .coconut {
    transform: rotateY(180deg);
}

.banana-inner-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    text-align: end;
}

.banana-inner-container .assembly-container {
    height: 91.77972865123703%;
    width: 100%;
}

.assembly-container #banan-full-size {
    width: 100%;
    height: 100%;
}

.assembly-container #banan-full-size .assembly {
    width: 100%;
    height: 100%;
}

.banana-inner-container .portal-arena {
    width: 84.57142857142857%;
    margin-left: auto;
    margin-right: -4.76190476190476%;
    margin-top: -15.4828411811652%;
}
@media (max-aspect-ratio: 1 / 1) and (min-aspect-ratio: 103 / 151) {
    #middle-panel {
        display: flex;
        align-items: center;
    }
    #arena-result-page #footer {
        height: 83vw;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: contain;
        /* background-position-y: bottom; */
        background-position: bottom -30vw center;
        z-index: 3;
    }
    #middle-panel-contents {
        margin: 10% 0 0 0;
        width: auto;
        height: 45%;
    }
    #middle-panel-contents .damage-number {
        font-size: 1.5vw;
    }
    #middle-panel-contents .damage-circle .damage-number-wrap .damage-number.left {
        margin-left: -5%;
    }
    #game-result-button-wrapper button span.play {
        font-size: 5vw;
    }
    #game-result-button-wrapper button {
        font-size: 4vw;
    }

}

@media screen and (max-aspect-ratio: 103 / 151) and (min-aspect-ratio: 82 / 151){
    #middle-panel-contents {
        aspect-ratio: 6/5;
        width: 70vw;
    }
    .banana-container {
        width: 120%;
        height: auto;
        z-index: 15;
    }
    #arena-result-page #footer {
        height: 100vh;
        margin-top: auto;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        background-position: bottom center;
        z-index: 3;
    }
    .banana-wrapper .coconut {
        height: 20vw;
        bottom: 5vh;
        left: -5vw;
    }
    #game-result-button-wrapper { /* 332*351 */
        /*background-color: aliceblue;*/
        width: 20vw;
        height: 20vw;

    }
    #middle-panel-contents #game-result-title h2 {
        font-size: 12vw;
    }
    #middle-panel-contents #game-result-numbers {
        height: 50%;
        display: flex;
        justify-content: space-around;
    }
    #middle-panel-contents .damage-number {
        font-size: 2vw;
        line-height: 0;
    }
    #middle-panel-contents .damage-circle .damage-number-wrap .damage-number.right {
        margin-right: 14%;
    }
    #game-result-button-wrapper button span.play {
        font-size: 5vw;
    }
    #game-result-button-wrapper button {
        font-size: 4vw;
    }
}
@media  (max-aspect-ratio: 82 / 151) {
    #middle-panel-contents {
        width: 70vw;
        aspect-ratio: 6/5;
        margin-top: 30%;
    }
    .banana-container {
        width: 140%;
        height: auto;
        position: absolute;
        bottom: 15.872727272727273%;
        left: -25%;
        z-index: 15;
    }
    #arena-result-page #footer {
        height: 100vh;
        margin-top: auto;
        bottom: 0;
        position: absolute;
        width: 100vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        background-position: bottom center;
        z-index: 3;
    }
    #middle-panel-contents #game-result-numbers #numbers-first-row {
        /*background-color: rgba(255, 255, 0, 0.44);*/
        height: 14vw;
    }
    #middle-panel-contents #game-result-numbers #numbers-second-row {
        /*background-color: rgba(0, 255, 4, 0.44);*/
        height: 14vw;
    }
    #game-result-button-wrapper { /* 332*351 */
        /*background-color: aliceblue;*/
        width: 20vw;
        height: 20vw;

    }
    #middle-panel-contents .damage-number {
        font-size: 2vw;
    }
    #middle-panel-contents .damage-circle .damage-number-wrap.bottom {
        margin-bottom: 10%;
    }
    #middle-panel-contents #game-result-numbers {
        height: 70%;
        display: flex;
        justify-content: space-around;
    }
    #middle-panel-contents .damage-circles-middle .damage-line-text {
        font-size: 3vw;
    }
    #middle-panel-contents #numbers-total-result {
        font-size: 4vw;
    }
    #middle-panel-contents #game-result-title h2 {
        font-size: 12vw;
    }
    #game-result-button-wrapper button span.play {
        font-size: 6vw;
    }
    #game-result-button-wrapper button {
        font-size: 4.5vw;
    }
    .banana-wrapper .coconut {
        height: 20vw;
        bottom: 10vh;
        left: -5vw;
    }
    #middle-panel-contents #game-result-title h2 {
        padding: 0;
    }
    #middle-panel-contents .damage-circle .damage-number-wrap .damage-number.right {
        margin-right: 14%;
    }

} 