.faction-box-opener h3 {
    font-size: 2.6rem;
    text-shadow: 0px 2px 4px rgba(24,43,58,0.45);
    text-shadow: 0px 1px 3px rgba(18,20,52,1);
    text-shadow: 0px 1px 5px rgba(18,20,52,1);
    margin: 5px auto;
}

.faction-box-opener h3.red {
    color: #fe4f74;
}
.faction-box-opener h3.green {
    color: #cee561;
}
.faction-box-opener h3.blue {
    color: #81f1f4;
}

.faction-box-opener {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /*font-family: "MusticaPro", sans-serif, Serif;*/
}

.faction-box-opener button.primary,
#rookie-box-open button.primary {
    width: 83%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    /*align-items: center;*/
    height: auto;
    font-size: 2rem;
    border-radius: 13px;
    line-height: 2.1rem;
    /*font-weight: bold;*/
    padding: 5px 20px;
    -webkit-box-shadow: 0px 2px 8px 4px rgba(24, 43, 58, 0.45);
    -moz-box-shadow: 0px 2px 8px 4px rgba(24, 43, 58, 0.45);
    box-shadow: 0px 2px 8px 4px rgba(24, 43, 58, 0.45);
}

.faction-box-opener button.primary .price,
#rookie-box-open  button.primary .price{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
}

.faction-box-opener button.primary .price img,
#rookie-box-open button.primary .price img {
    margin: auto 9px;
}

.faction-box-opener:hover,
#rookie-box-open:hover {
    cursor: pointer;
}

.faction-box-opener:hover img.card-placeholder,
#rookie-box-open:hover img.card-placeholder {
    animation-name: shakeFactionBox;
    animation-duration: 0.9s;
    animation-iteration-count: infinite;
}

@keyframes shakeFactionBox {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}