.duel-resolved-block h1,
.duel-resolved-block h2,
.cool-number {
    font-family: "PassionOne", sans-serif, Serif;
    font-weight: normal;
    text-align: center;
    font-size: 17.6rem;
    margin: auto;
    /* margin-top: 1%; */
    flex: 1 1;
    background: linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.duel-resolved-block h2 .yv-currency-icon {
    width: 96px;
    height: 96px;
}

.duel-resolved-block h1 {
    font-size: 12.8rem;
}

.cool-number {
    font-size: 16.8rem;
    line-height: 17.6rem;
    font-weight: bold;
    /*text-shadow: 0 0 5px #000;*/
    margin: 35px auto;
}

.cool-number {
    animation: fadeIn 1.3s linear;
    -webkit-animation: fadeIn 1.3s linear;
    -moz-animation: fadeIn 1.3s linear;
    -o-animation: fadeIn 1.3s linear;
    -ms-animation: fadeIn 1.3s linear;
}

.duel-resolution-text {
    color: #c3cace;
    font-size: 4.2rem;
}

#duel-resolved-block {
    transition: all 0.6s ease;
    animation: fadeInB 1.9s linear;
}

#duel-actions-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

#duel-actions-block .btn.primary {
        height: 45px;
        font-size: 2.6rem;
        width: auto;
        border-radius: 7px;
}

#duel-actions-block .btn.secondary {
    color: #f0dbcb;
    font-size: 1.6rem;
}


@media screen and (max-width: 768px) {
    .duel-resolved-block h1 {
        font-size: 9.8rem;
    }
    .duel-resolved-block h2 {
        font-size: 9.8rem;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .duel-resolved-block h2 .yv-currency-icon {
        width: 76px;
        height: 76px;
        margin: auto 13px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }

    30% {
        opacity: 1;
        transform: translateX(5%);
    }

    50% {
        opacity: 1;
        transform: translateX(0);
    }

    65% {
        opacity: 1;
        transform: translateX(-5%);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%);
    }
}

@keyframes fadeInB {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}