@import url('https://fonts.googleapis.com/css?family=Inclusive+Sans:regular,bold,italic,semi-bold,100,200,300,600,700,800,900&subset=latin,latin-ext');

#new-shop-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    /*background-image: url("https://asset-host.b-cdn.net/assets/inventory/BG_dim.png");*/
    /*background-image: url("https://asset-host.b-cdn.net/assets/shop/BG.png");*/
    background-image: url("https://asset-host.b-cdn.net/assets/shop/BG_dim_blue.png");
    font-family: "Inclusive Sans", "MusticaPro", sans-serif, Serif;

    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    overflow-x: hidden;
    overflow-y: auto;

}

#currency-management {
    min-height: 200px;
    /*background-color: rgba(42, 15, 23, 0.23);*/
}

#new-shop-page .divider {
    /*width: 100%;*/
    height: 3px;
    min-height: 3px;
    background: rgb(241, 225, 211);
    background: linear-gradient(90deg, rgba(255,252,240,0) 12.5%, rgb(241, 225, 211) 51%, rgba(255,252,240,0) 87.5%);
}

#new-shop-page #faction-box-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-content: center;

}

#new-shop-page #shop-items h2,
#new-shop-page #shop-rookie h2 {
    font-size: 4.8rem;
    color: #ffd75f;
    text-shadow: 0px 2px 4px rgba(24, 43, 58, 0.45);
    font-weight: 500;
    font-family: "PassionOne", sans-serif, Serif;
    margin: 9px auto 3px;
}

#new-shop-page .footer-spacer {
    height: 300px;
    min-height: 100px;
    width: 100%;
}

#new-shop-page .footer {
    height: 12px;
    /*background-color: magenta;*/
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgb(24, 48, 72) 0%, rgb(48, 72, 96) 35%, rgba(0,212,255,0) 70%);
    width: 100%;
    margin-bottom: 0;
    bottom: 0;
    margin-top: auto;
    position: fixed;
}

#new-shop-page .footer .coco {
    height: 362px;
    margin-top: -375px;
    margin-left: auto;
    margin-right: 1600px;
}


@media screen and (max-width: 1800px) {
    #new-shop-page .container-1600 {
        width: 1250px;
    }
    /*div.currency-form-inputs .action-flex {*/
    /*    width: 95%;*/
    /*}*/
    div.currency-form-inputs .action-flex p {
        flex: 7;
        flex-wrap: wrap;
        font-size: 1.7rem;
    }
    /*div.YVC-to-USD.currency-form-inputs .action-flex  {*/
    /*    margin-right: 5%;*/
    /*}*/
    /*div.USD-to-YVC.currency-form-inputs .action-flex {*/
    /*    margin-right: 5%;*/
    /*}*/
}

@media screen and (max-width: 1600px) {

    #new-shop-page .footer .coco {
        height: calc(362px / 2);
        margin-top: calc(-375px / 2);
        margin-left: auto;
        margin-right: 1250px;
    }
}

@media screen and (max-width: 1380px) {
    #new-shop-page .container-1600 {
        width: 800px;
    }
    #new-shop-page #currency-management {
        display: flex;
        flex-direction: column;
        width: 625px;
        min-height: 400px;
        margin-top: 140px;
    }

    #currency-management .USD-to-YVC.currency-form-inputs .action-flex,
    #currency-management .YVC-to-USD.currency-form-inputs .action-flex,
    #currency-management .USD-to-YVC.currency-form-inputs .upper-balance,
    #currency-management .YVC-to-USD.currency-form-inputs .upper-balance {
        margin-left: 10%;
        margin-right: 10%;
    }

    #currency-management .currency-form-inputs .upper-balance h3 {
        margin: 0 auto;
    }
    #currency-management .USD-to-YVC.currency-form-inputs .amount-selector-container,
    #currency-management .YVC-to-USD.currency-form-inputs .amount-selector-container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .faction-box-opener {
        width: 250px;
    }
    .faction-box-opener img.card-placeholder {
        width: 230px;
    }
}

@media screen and (max-width: 1024px) {
    #new-shop-page #currency-management {
        margin-top: 70px;
    }
}

@media screen and (max-width: 850px) {
    #new-shop-page .container-1600 {
        width: 630px;
    }
    #new-shop-page #faction-box-row {
        flex-direction: column;
    }

    .faction-box-opener {
        width: auto;
    }
    .faction-box-opener img.card-placeholder {
        width: auto;
    }
}

@media screen and (max-width: 690px) {
    #new-shop-page #currency-management {
        width: 100%;
    }

    #new-shop-page .container-1600 {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    #new-shop-page #currency-management {
        display: none;
    }

    #shop-items {
        margin-top: 120px;
    }


}