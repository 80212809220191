.item-card {
    /*display: inline-block;*/
    /*float: left;*/
    width: 100px;
    height: 130px;
    margin: 0px 3px 12px 8px;
    background-color: #c57e73;
    /*border: 4px solid #85413e;*/
    border-radius: 7px;
    cursor: pointer;
    float: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    font-family: "MusticaPro", sans-serif, Serif;
}

.item-card span, .item-card .thumbnail {
    display: block;
    align-content: center;
    text-align: center;
}

.item-card .thumbnail {
    width: 96px;
    height: 80px;
    margin: 0 auto;
}

.item-card span {
    display: block;
    font-weight: bold;
    text-align: center;

}

/* ============= ITEM CARD SEMI IMG ================= 824 */

.item-card-semi-img {
    background-size: contain;
    background-repeat: no-repeat;
    /*width: 198px;*/
    /*height: 250px;*/
    height: auto;
    border: none;
    background-color: rgba(0,0,0,0);
    position: relative;
}

.item-card-semi-img img.aspect-ratio {
    position: relative;
    left: 0;
    width: 100%;
    opacity: 0;
}

.item-card-semi-img .text-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.item-card-semi-img span {
    display: flex;
    font-size: 19.5pt;
    -webkit-text-stroke-width: 0.5px;
    margin-left: 21%;
    margin-right: 10%;
    width: 57%;
    padding-left: 3%;
    justify-content: flex-start;
}

span.item-card-id {
    color: #78403b;
    font-weight: 600;
    margin: auto 3px;
}

.item-card-semi-img .id {
    color: #78403b;
    /* stroke: #3c3f41; */
    -webkit-text-stroke: 0px #ffffff;
    text-stroke: 0px none;
    margin-top: 6%;
    font-size: 3.4rem;
    margin-left: 26%;
    border-top-right-radius: 9px;
    /* text-align: center; */
    /* background-color: rgba(25, 25, 112, 0.3);*/
}
.modal.unpack_box .item-card-semi-img .id {
    font-size: 3.44rem;
}

.item-card-semi-img .damage-number {
    /*margin-top: 133px;*/
    /*padding-left: 8px;*/
    height: 19%;
    margin-top: auto;
    margin-bottom: 11%;
    align-content: center;
    align-items: center;
    padding-left: 14%;
    width: 49%;
    transform: rotate(356deg);
    -ms-transform: rotate(356deg);	 /*  for IE  */
    /* 	for browsers supporting webkit (such as chrome, firefox, safari etc.). */
    -webkit-transform: rotate(356deg);
    /*background-color: rgba(222, 184, 135, 0.52);*/
    font-size: 2.9rem;
}

@media screen and (max-width: 1800px) {
    .item-card-semi-img .id {
        font-size: 2.4rem;
    }
    .item-card-semi-img .damage-number {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 900px) {
    .item-card-semi-img .damage-number {
        font-size: 2.4rem;
    }
}
@media screen and (max-width: 725px) {
    .item-card-semi-img .damage-number {
        font-size: 2.05rem;
    }
}
@media screen and (max-width: 620px) {
    .item-card-semi-img .damage-number {
        font-size: 1.85rem;
    }

    .modal.unpack_rookie .item-card-semi-img span.id {
        font-size: 2.0rem;
    }

    .modal.unpack_rookie .item-card-semi-img span.damage-number {
        font-size: 1.65rem;
    }
    .modal.unpack_box .item-card-semi-img span.damage-number {
        font-size: 2.35rem;

    }
}

@media screen and (max-width: 500px) {

    .modal.unpack_rookie .item-card-semi-img span.id {
        font-size: 2.35rem;
    }
    .modal.unpack_rookie .item-card-semi-img span.damage-number {
        font-size: 2.15rem;
    }
}
@media screen and (max-width: 370px) {
    .item-card-semi-img .id {
        font-size: 2.0rem;
    }
    .item-card-semi-img .damage-number {
        font-size: 1.85rem;
    }
    .modal.unpack_rookie .item-card-semi-img span.damage-number {
        font-size: 1.75rem;
    }
    .modal.unpack_box .item-card-semi-img .id {
        font-size: 3.2rem;
    }
}

@media screen and (max-width: 350px) {
    .item-card-semi-img .damage-number {
        font-size: 1.65rem;
    }
}

@media screen and (max-width: 320px) {
    .item-card-semi-img .damage-number {
        font-size: 1.5rem;
    }
    .modal.unpack_rookie .item-card-semi-img span.id {
        font-size: 1.9rem;
    }
    .modal.unpack_rookie .item-card-semi-img span.damage-number {
        font-size: 1.65rem;
    }
}

@media screen and (max-width: 290px) {
    .item-card-semi-img .id {
        font-size: 1.8rem;
    }
    .item-card-semi-img .damage-number {
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 260px) {
    .item-card-semi-img .id {
        font-size: 1.6rem;
    }
    .item-card-semi-img .damage-number {
        font-size: 1.2rem;
    }
}

#inventory-page .pre-minted-items .item-card {
    opacity: 0.7;
    /*color: rgba(40, 44, 52, 0.7);*/
    /*background-color: rgba(197, 126, 115, 0.7);*/
}

.item-card-BLUE span{
    color: #0b3440;
    /*animation: glowblue 2.4s ease-in-out infinite alternate;*/
    /*-webkit-animation: glowblue 2.4s ease-in-out infinite alternate;*/
    /*-moz-animation: glowblue 2.4s ease-in-out infinite alternate;*/
}

@keyframes glowblue {
    from {
        text-shadow:
                0 0 6px rgb(34, 79, 147),
                0 0 30px #ddd
    }

    to {
        text-shadow:
                0 0 2px #93b4d9,
                0 0 30px #ccc
    }
}

.item-card-RED span{
    color: #4a1219;
    /*animation: glowred 1.6s ease-in-out infinite alternate;*/
    /*-webkit-animation: glowred 1.6s ease-in-out infinite alternate;*/
    /*-moz-animation: glowred 1.6s ease-in-out infinite alternate;*/
    /*-webkit-text-stroke: 0.6px #cdcdff;*/
    /*text-stroke: 4px navy;*/


}

@keyframes glowred {
    from {
        text-shadow:
                0 0 3px #500303,
                0 0 6px #888
    }

    to {
        text-shadow:
                0 0 2px darkred,
                0 0 6px #555
    }
}

.item-card-GREEN span{
    color: #2e4308;
    /*-webkit-text-stroke-color: #353e0d;*/
    /*animation: glowred 1.6s ease-in-out infinite alternate;*/
    /*-webkit-animation: glowred 1.6s ease-in-out infinite alternate;*/
    /*-moz-animation: glowred 1.6s ease-in-out infinite alternate;*/
}




.item-card-semi-img span {
    font-size: 1vw;
    /* position: absolute; */
    /* top: 10px; */
    /*left: auto;*/
    /*margin: 15px auto 0;*/
    -webkit-text-stroke-width: 0.5px;
}

/*@media (max-aspect-ratio: 340 / 185) {*/
/*    .item-card-semi-img span {*/
        /*font-size: 1.6vw;*/
    /*}*/
/*}*/
/*@media screen and (max-aspect-ratio: 17 / 23) {*/
/*    .item-card-semi-img span {*/
        /*font-size: 3.3vw;*/
    /*}*/
/*}*/