#inventory-page {
    background-color: #0b3440;
    background:
            url("https://asset-host.b-cdn.net/assets/inventory/plants_top_left.png") left top no-repeat,
            url("https://asset-host.b-cdn.net/assets/inventory/plants_top_right.png")  right top no-repeat,
            url("https://asset-host.b-cdn.net/assets/inventory/BG.png") center top no-repeat;
    /*background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;*/
    background-size: auto, auto, cover;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

#main {
    display: flex;
    width: 100%; /* legacy */
    flex: 1;
}


#inventory-page #footer,
#arena-picker-page #footer{
    /*height: calc(100vw / 2560 * 158); !* 2560 x 158 on QHD *!*/
    height: 6.171875vw; /* 2560 x 158 on QHD */

    margin-top: auto;
    bottom: 0;
    /*background-color: magenta;*/
    position: absolute;
    width: 100%;
    background-image:
            url("https://asset-host.b-cdn.net/assets/inventory/plants_bottom_left.png"),
            url("https://asset-host.b-cdn.net/assets/inventory/plants_bottom_right.png"),
            /*url("https://asset-host.b-cdn.net/assets/inventory/land.png");*/
            url("https://asset-host.b-cdn.net/assets/inventory/land_2.png");
    background-repeat: no-repeat, no-repeat, no-repeat;
    /*background-size: 100% 100%, ;*/
    background-size:  8.5703125% 100%, 6.452830188679245% 100%, 100% 72.15189873417722%; /* land_2 height is 114px on 158px*/
    background-position-y: bottom;
    background-position-x: left, right, center;
    background-position: bottom left, bottom right, bottom center;
    pointer-events: none;
    z-index: 100;
}

#footer #ground {
    /*height: 41.11405835543767%;*/
    /*margin-top: 58.88594164456233%;*/
    /*width: 100%;*/
}


#inventory-page #coco {
    bottom: 1vw;
    width: 15.9375%;
    left: 27.3828125%;
    z-index: 100;
}

.aspect-ratio {
    position: relative;
    left: 0;
    max-height: none;
    max-width: none;
    width: 100%;
    opacity: 1;
}


/* MOBILE VIEW */
@media (max-aspect-ratio: 17 / 23) {
    #inventory-page #main {
        flex-direction: column;
    }

    #inventory-page #coco {
        left: 5.382813%;
    }

    #inventory-page #footer {
        /*height: 3%;*/
        background-size: 8.5703125% 100%, 6.452830188679245% 100%, 200% 72.15189873417722%;
        background-position: bottom left, bottom right, bottom right;
    }
}





@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 17 / 23){
    #inventory-page #footer {
        display: flex;
    }
}