#inventory-page #left-side,
#arena-picker-page #left-side,
#arena-passive-list #left-side
{
    display: flex;
    flex-direction: row;
    margin-right: -1.6015625%;
    margin-left: 0;
    width: 40vw;
    height: 100%;
    /*background-color: rgba(245, 245, 245, 0.09);*/
}
@media (max-aspect-ratio: 340 / 185) {
    #inventory-page #left-side,
    #arena-picker-page #left-side,
    #arena-passive-list #left-side
    {
        width: 45vw;
    }
}

#arena-picker-page #left-side,
#arena-passive-list #left-side {
    flex-direction: column;
}

#arena-picker-page #left-side {
    margin-left: 3%;
}

#inventory-page #left-side #backpack-horizontal-wrap,
#arena-picker-page #left-side #vertical-pre-wrap,
#arena-passive-list #left-side #vertical-pre-wrap {
    height: 100%;
    width: 71.71453437771976%;
    margin-left: 2.436901653611836%;
    /*background-color: rgba(255, 69, 0, 0.23);*/

    display: flex;
    flex-direction: column;
    position: relative;
}
@media (max-aspect-ratio: 340 / 185) {
    #inventory-page #left-side #backpack-horizontal-wrap,
    #arena-picker-page #left-side #vertical-pre-wrap,
    #arena-passive-list #left-side #vertical-pre-wrap {
        width: 100%;
    }
}


#inventory-page #left-side #backpack-vertical-pre-wrap,
#arena-picker-page #left-side #vertical-pre-wrap,
#arena-passive-list  #left-side #vertical-pre-wrap {
    height: 3.2%;
}

#inventory-page #left-side #backpack-vertical-wrap {
    height: 88.43636363636364%;
    width: 100%;
}

#inventory-page  #backpack-vertical-wrap #backpack {
    height: 100%;

    /*background-image: url("/public/assets/inventory/backpack_2.png");*/
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/backpack_2.png");
    background-size: 100% 98%;
    background-position-y: bottom;
    background-repeat: no-repeat;
}


#inventory-page #backpack #card-list {
    display: flex;
    flex-direction: row;
    height: 86.13398692810458%;
    width: 92.5%;
    /* TODO this alongside height: 96% on .scrollable needs a tighter mobile ratio adjustment.
        Somewhere in the middle we should perform readj */
    /*padding-top: 2%;*/
    padding-right: 4%;
    padding-left: 3.5%;
    /*padding-bottom: 3%;*/
}

/* ============ CARD LIST TABS (SET_PIECE) ==============*/

#inventory-page #backpack #card-list-tabs {
    width: 30.5vw;
    height: calc(30.5vw / 883 * 126);
    margin-left: calc(30.5vw / 883 * -24);
    margin-bottom: calc(30.5vw / 883 * -5);
    /*background-image: url("/public/assets/inventory/backpack_1.png");*/
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/backpack_1.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
}

#inventory-page #backpack #card-list-tabs .tab {
    width: 25%;
    /*background-color: rgba(215, 215, 215, 0.42);*/
    background-color: transparent;
    margin: 0.5% 0;
    color: #1b2c3e;
    font-weight: bold;
    font-size: 1.08vw;
    border: none;
}

@media (max-aspect-ratio: 340 / 185) {
    #inventory-page #backpack #card-list-tabs {
        width: 48vw;
        height: calc(48vw / 883 * 126);;
        margin-left: -2.79126213592233%;
        margin-bottom: -0.5718954248366013%;
    }

    #inventory-page #backpack #card-list-tabs .tab {
        font-size: 1.7vw;
    }
}

#inventory-page #backpack #card-list .scrollable {
    height: 92%;
    width: 80%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    position: relative;
    /*border-radius: 16px;*/
    border-bottom-left-radius: 16px;
    -webkit-border-bottom-left-radius: 16px;
}

@media screen and (max-aspect-ratio: 17 / 23){
    #inventory-page #backpack #card-list-tabs {
        width: 95.5vw;
        height: calc(13.62740656851642vw);
        margin-left: -2.79126213592233%;
        margin-bottom: 0;
    }

    #inventory-page #backpack #card-list {
        height: calc(100% - 13.62740656851642vw);
    }
    #inventory-page #backpack #card-list .scrollable {
        height: 96%;
    }
}

#inventory-page #backpack #card-list-tabs .tab:hover {
    color: #93b4d9;
    cursor: pointer;
}

#inventory-page #backpack #card-list-tabs .tab.active {
    color: bisque;
}

#inventory-page #backpack #card-list-tabs .tab.active:hover {
    color: #c4ffed;
}

#inventory-page #backpack #card-list-tabs .tab.skin {
    width: 20%;
    margin-left: 2%;
}

#inventory-page #backpack #card-list-tabs .tab.weapon {
    width: 31%;
}

#inventory-page #backpack #card-list-tabs .tab.body {
    width: 21%;
}

#inventory-page #backpack #card-list-tabs .tab.head {
    width: 20%;
    margin-right: 3%;
}


#inventory-page #backpack #card-list .scrollable #minted-items
,#inventory-page #backpack #card-list .scrollable #pre-minted-items {
    margin-right: 15px;
}


#inventory-page #backpack #card-list .scrollable #minted-items .items
,#inventory-page #backpack #card-list .scrollable #pre-minted-items .items
{
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-right: 0;
    padding-right: 4%;
}

/* ========== PRE MINT ======== */

#inventory-page #backpack #card-list .scrollable #pre-minted-items h4{
    font-weight: bold;
    color: #bbd0ff;
    font-size: 1.1vw;
    line-height: 1.1vw;
    height: 1.1vw;
    display: flex;
    flex-direction: column;
    /*margin-top: 0.6vw;*/
    margin-bottom: 0.5vw;
}

#inventory-page #backpack #card-list .scrollable #pre-minted-items h4 span{
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(0,0,0,0) 5%, rgb(187, 208, 255) 20%, rgb(0, 0, 0, 0) 21%, rgba(65,55,2,0) 79%, rgb(187, 208, 255) 80%, rgba(0,0,0,0) 95%);
    margin-top: -0.65vw;
}

/*@media screen and (max-width: 2300px){*/
/*    #inventory-page #backpack #card-list .scrollable #pre-minted-items h4 span {*/
/*        display: none;*/
/*    }*/
/*}*/

#inventory-page #backpack #card-list .scrollable #pre-minted-items .items .item-card{
    opacity: 0.75;
    filter: saturate(.6);
}

#inventory-page #backpack #card-list .scrollable #pre-minted-items .items .item-card:hover{
    opacity: 1;
    filter: saturate(1);
}

.item-card.finished {
    opacity: 0.3;
}

    /* =========== ITEMS ========== */


#inventory-page #backpack #card-list .scrollable .item-card {
    width: 30.72%;
    margin: 0px;
    float: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-left: 1.76%;
    margin-bottom: calc(100vw/2560*11);
}

#inventory-page #backpack #card-list #card-list-sidebar {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 0px;
    height: 30%;
    width: 7%;
}
@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23){
    #inventory-page #backpack #card-list #card-list-sidebar {
        width: 5%;
    }
}

#inventory-page #backpack #card-list #card-list-sidebar .filter {
    display: flex;
    flex-direction: column;
    width: 2vw;
}
@media (max-aspect-ratio: 17 / 23) {
    #inventory-page #backpack #card-list #card-list-sidebar .filter {
        width: 4vh;
    }
}

.scrollbar-render-container {
    /* height: 60%; */
    /* background-color: rgba(255, 0, 255, 0.44); */
}

.scrollbars .vertical-scroll-container {
    position: absolute;
    /* margin-right: 10px; */
    /* margin-left: 10px; */
    width: 10px;
    /* right: 2px; */
    /* bottom: 2px; */
    /* top: 2px; */
    border-radius: 3px;
    /* top: 40%; */
    bottom: 0%;
    height: 54%;
    right: 13px;
    z-index: 4;
    background-color: rgb(98, 48, 43);
    border: 1px solid #3f2320;
}
@media (max-aspect-ratio: 17 / 23) {
    .scrollbars .vertical-scroll-container {
        right: 7vw;
        top: 40%;
    }
}

.scrollbars .vertical-scroll-container .begunok {
    position: relative;
    display: block;
    transform: translateY(196px);
    cursor: pointer;
    border-radius: inherit;
    background-color: rgb(163, 86, 80);
    border: 1px solid #3f2320;
    /* height: 6px !important; */
    width: 14px !important;
    margin-left: -3px;
}

.scrollbars .parent-sidebar-container {
    position: absolute;
    width: 6px;
    height: 55%;
    background-color: rgba(255, 236, 187, 0.48);
}

/*================================================*/

.filter-button {
    cursor: pointer;
    border: none;
    /*border-left: 3px solid crimson;*/
    padding: 0;
    width: 100%;
    height: 3vw;
    margin-top: 10%;
    margin-bottom: 10%;
}
@media (max-aspect-ratio: 17 / 23) {
    .filter-button {
        height:6vh;
    }
}

.filter-button.RED {
    background-color: rgba(227,48,84,0.2);
    border-right: 30px solid rgba(227, 48, 84, 0.2);
}

/* .filter-button.RED.active {
    border-right: 20px solid rgb(227, 48, 84);
} */

.filter-button.BLUE {
    background-color: rgba(34,194,208,.2);
    border-right: 30px solid rgba(34, 194, 208, 0.2);
}

/* .filter-button.BLUE.active {
    border-right: 20px solid #22c2d0;
} */

.filter-button.GREEN {
    background-color: hsla(68,76%,67%,.2);
    border-right: 30px solid rgba(218, 235, 109, 0.2);
}

/* .filter-button.GREEN.active {
    border-right: 20px solid rgb(218, 235, 109);
} */

.refresh-button {
    display: flex;
    border: none;
    background-color: #a6bcde;
    width: 100%;
    height: 100%;
    margin-top: 20%;
    margin-bottom: 20%;
    cursor: pointer;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 15%;
    font-size: 0.8vw;
}
@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23){
    .refresh-button {
        font-size: 1.2vw;
    }
}

.refresh-button:hover {
    background-color: #c4dafd;
}

@media screen and (max-aspect-ratio: 425 / 538) {
    #inventory-page #left-side,
    #arena-picker-page #left-side,
    #arena-passive-list #left-side {
        width: 100%;
        justify-content: center;
    }
    #inventory-page #left-side #backpack-horizontal-wrap,
    #arena-picker-page #left-side #vertical-pre-wrap,
    #arena-passive-list #left-side #vertical-pre-wrap { 
        width: 90%;
        margin-right: 0;
        margin-left: 0;
    }
}
.filter-button.RED.active {
    border-right: 1.4vw solid rgb(227, 48, 84);
}
.filter-button.BLUE.active {
    border-right: 1.4vw solid #22c2d0;
}
.filter-button.GREEN.active {
    border-right: 1.4vw solid rgb(218, 235, 109);
}
@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23) {
.filter-button.RED.active {
    border-right: 1.4vw solid rgb(227, 48, 84);
}
.filter-button.BLUE.active {
    border-right: 1.4vw solid #22c2d0;
}
.filter-button.GREEN.active {
    border-right: 1.4vw solid rgb(218, 235, 109);
}
}


@media screen and (max-aspect-ratio: 17 / 23) {
    #inventory-page #backpack #card-list .scrollable #pre-minted-items h4{
        font-size: 4.5vw;
    }
    #inventory-page #backpack .refresh-button {
        font-size: 2.4vw;
    }
    #inventory-page #backpack #card-list-tabs .tab {
        font-size: 3vw;
    }  
}

@media screen and (max-aspect-ratio: 17 / 23){
    #inventory-page #left-side, #arena-picker-page #left-side, #arena-passive-list #left-side {
        height: 45vh;
    }
    #inventory-page #backpack #card-list .scrollable .item-card {
        /*width: 45%;*/
    }
    .filter-button.RED.active {
        border-right: 3vh solid rgb(227, 48, 84);
    }
    .filter-button.BLUE.active {
        border-right: 3vh solid #22c2d0;
    }
    .filter-button.GREEN.active {
        border-right: 3vh solid rgb(218, 235, 109);
    }
}



@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23){
    #inventory-page #backpack #card-list .scrollable .item-card {
        /*width: 33%;*/
    }
}


