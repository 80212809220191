
.allowance-container {
    flex: 1;
    margin-top: 20px;
}

.allowance-container .allowance-label-container {
    flex: 3 1;
    align-items: flex-end;
    padding-right: 25px;
}
.allowance-container .allowance-label-container label{
    white-space: nowrap;
    text-align: center;
    line-height: 35px; /* TODO fix!!! line height in px? that's a no-no*/
}

.allowance-container .allowance-input-container {
    flex: 4 1;
}

.settings-row {
    height: 35px;
    padding: 10px 0;
    align-items: center;
    align-content: center;
}

.settings-row input {
    border: 3px solid #00000000;
    line-height: 35px;
    border-radius: 10px;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    font-weight: bold;
    padding: 0 7px ;
    margin: auto 13px auto 0;
}

.settings-row input.changed, .settings-row input.changed {
    border: 3px solid #febd4e;
    color: #3f2320;
}