@font-face {
    font-family: 'MusticaPro';
    src: url(
    './MUSTICAPROSEMIBOLD.OTF'),
    url("https://asset-host.b-cdn.net/assets/global/MUSTICAPROSEMIBOLD.OTF")
;
}

@font-face {
    font-family: 'PassionOne';
    src: url(
    './PassionOne-Regular.ttf'),
    url("https://asset-host.b-cdn.net/assets/global/PassionOne-Regular.ttf");
;
}

@font-face {
    font-family: 'PassionOneBold';
    src: url(
    './PassionOne-Bold.ttf'),
    url("https://asset-host.b-cdn.net/assets/global/PassionOne-Bold.ttf");
;
}

@font-face {
    font-family: 'PassionOneBlack';
    src: url(
    './PassionOne-Black.ttf'),
    url("https://asset-host.b-cdn.net/assets/global/PassionOne-Black.ttf");
;
}

/*@todo there's a problem with CORS protection being on for font file integration on CDN, using local for now*/

.App {
    max-height: 100%;
    height: 100%;
    text-align: center;
    background-repeat: no-repeat;
    overflow-y: hidden; /* TODO : fix this!! there's like 8px overflow for now reason??? */
    overflow-x: hidden; /* TODO : I believe this fixes 8px overflow caused by invisible x axis slider */
}

img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#browser-router {
    display: flex;
    background: #141414;
    border-bottom: 3px solid #f1cc5b;
    border-bottom: 3px solid #febd4e;
    padding: 5px 0px 5px 5px;
    height: 55px;
    min-height: 55px;
    color: #a8a8a8;
    z-index: 800;
    justify-content: center;
    font-size: 2rem;
}

#browser-router #header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 300px;
    position: absolute;
    top: 2px;
    background-size: 100% auto;
    max-width: 380px;
    height: 187px;
    left: 50%;
    left: calc(50% - 133px);
    pointer-events: none;
}

@media only screen and (max-width: 1400px) {

    #browser-router {
        height: 38px;
        min-height: 38px;
    }

    #browser-router #header-logo {
        height: 139px;
        width: calc(139px / 187 * 266);
        left: 50%;
        left: calc(50% - 100px);
        right: calc(50% - 100px);
    }

}

@media only screen and (max-width: 830px) {
    #browser-router #currency-header {
        margin-left: 0;
        margin-right: auto;
    }
}

@media only screen and (max-width: 600px) {
    #browser-router #header-logo {
        height: 100px;
        width: calc(100px / 187 * 266);
        left: 49%;
        left: calc(50% - 71px);
        right: calc(50% - 71px);
        top: 14px;
    }
}

#browser-router #menu {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

#browser-router #menu div {
    margin: 10px;

}


#browser-router #menu div .menu-link {
    text-decoration: none;
    font-weight: 600;
    color: #8e9fc4;
    background-size: 100%;
    cursor: pointer;
}

#mobile-menu ,
#browser-router #menu a.menu-link .mobile {
    display: none;
}

.menu .mobile-menu {
    display: flex;
    margin: 3px 10px 5px;
}

.mobile-menu a {
    text-decoration: underline;
    color: rgb(142, 159, 196);
}

.popup-content .menu {
    padding: 10px 5px;
}

#menu .menu-link .bncp-gov-icon.currency-icon-32 {
    width: 3.2rem;
    height: 3.2rem;
}


@media screen and (max-width: 1150px) {
    #browser-router #menu div.desktop-menu,
    #browser-router #menu a.menu-link .desktop,
    .menu a.menu-link .desktop {
        display: none;
    }


    #browser-router #mobile-menu,
    #browser-router #menu a.menu-link .mobile {
        display: flex;
    }

    #browser-router #mobile-menu #mobile-small-menu {
        display: none;
    }

    .header-right {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    #browser-router #mobile-menu #mobile-big-menu {
        display: none;
    }

    #browser-router #mobile-menu #mobile-small-menu {
        display: flex;
    }

    @media screen and (max-width: 550px) {
        #browser-router #menu div {
            margin: 10px 0px 10px 5px;
        }

    }
}


#browser-router #menu div .menu-link:hover {
    color: #a8b1c4;
}

#browser-router #menu div .menu-link.active {
    color: #cfcece;
}

#route-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

#browser-router a {
    margin: initial;
    display: flex;
}

/*@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 17 / 23) {*/
/*    #browser-router a {*/
/*        margin: 10px;*/
/*    }*/
/*}*/
/**/
/*@media screen and (max-aspect-ratio: 17 / 23) {*/
/*    #browser-router a {*/
/*        margin: auto 5px;*/
/*    }*/
/*}*/

/* CONTAINERS */
.full-width {
    width: 100%;
    display: flex;
    min-width: 230px;
}

.full-width .container-1600 {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    /*align-items: center;*/
    justify-content: center;
}

.split-10 {
    display: flex;
    flex: 1;
}

.split-20 {
    display: flex;
    flex: 2;
}

.split-30 {
    display: flex;
    flex: 3;
}

.split-40 {
    display: flex;
    flex: 4;
}

.split-50 {
    display: flex;
    flex: 5;
}

.split-60 {
    display: flex;
    flex: 6;
}

.split-70 {
    display: flex;
    flex: 7;
}

.split-80 {
    display: flex;
    flex: 8;
}

.split-90 {
    display: flex;
    flex: 9;
}

a {
    color: beige;
    margin: auto 1%;
}

.icon {
    max-height: 100%;
}

span.red {
    color: rgb(227, 48, 84);
}

span.green {
    color: rgb(218, 235, 109);
}

span.blue {
    color: #22c2d0;
}

span.yellow {
    color: #f1cc5b;
    /*color: #ffd052;*/
}

span.bold {
    font-weight: 900;
}

button.btn {
    width: 90px;
    height: 40px;
    color: #1f222a;
    border-radius: 5px;
    padding: 10px 25px;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 20px 0px rgba(0, 0, 0, 0.1);
    outline: none;
    background: #FFAA49;
    background: linear-gradient(
            0deg,
            rgba(255, 170, 73, 1) 0%,
            rgba(255, 221, 85, 1) 100%
    );
    border: none;
    border: 2px solid #c2664a;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    button.btn {
        border-radius: 3px;
    }
}

button.btn.secondary {
    background: none;
    border: none;
    box-shadow: none;
    width: auto;
    height: auto;
    color: #a8a8a8;
    text-decoration: underline;
    font-size: 1.3rem;
}

button.btn.primary.disabled {
    background: linear-gradient(0deg, rgb(207, 183, 153) 0%, rgb(226, 215, 175) 100%);
}

button.btn.secondary.disabled {
    opacity: 0.5;
}

button.btn:hover {
    background: rgb(0, 3, 255);
    background: linear-gradient(
            0deg,
            rgba(255, 179, 79, 1) 0%,
            rgba(255, 228, 86, 1) 100%
    );
}

button.btn.secondary:hover {
    background: none;
    color: rgba(255, 228, 86, 1);
}

button.btn.secondary.disabled:hover {
    color: rgba(255, 228, 86, 0.6);
}

.error-message {
    width: 100%;
    background-color: rgb(227 48 84 / 56%);
    color: white;
    font-weight: 900;
    font-size: 1vw;
    display: flex;
    /*justify-content: space-evenly;*/
    align-items: center;
    align-content: center;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    text-decoration: underline;
    cursor: pointer;
    padding: 5px 0px 5px 0px;
}

.error-message svg {
    margin: auto 0.7rem;
}

.arrows.arrow-right,
.arrows.arrow-left {
    background-image: url(https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;
    text-align: left;
    color: #b4890d;
    width: 30px;
    height: 30px;
}

.arrows.arrow-left {
    transform: scaleX(-1);
}

.info-tooltip {
    display: flex;
    margin-top: 0;
    margin-bottom: auto;
    cursor: pointer;
}

.info-tooltip:hover {
    color: #fff;
}

.fading-line {
    height: 2px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, #d2baac 50%, rgba(0, 0, 0, 0) 90%);
    width: 100%;
}

#new-shop-page .divider,
#inventory .divider {
    /*width: 100%;*/
    height: 3px;
    min-height: 3px;
    background: rgb(241, 225, 211);
    background: linear-gradient(90deg, rgba(255, 252, 240, 0) 12.5%, rgb(241, 225, 211) 51%, rgba(255, 252, 240, 0) 87.5%);
}

.react-icons {
    vertical-align: middle;
}

.flex-row, .flex-column, .flex-container-columns, .flex-container-rows, .flex-all-center {
    display: flex;
}

.flex-container-rows {
    flex-direction: column;
}

.flex-container-columns {
    flex-direction: row;
}

.flex-all-center {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.clearfix::after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    /*IE7 compatibility : */
    *zoom: expression( this.runtimeStyle['zoom'] = '1', this.innerHTML += '<div class="ie7-clear"></div>' );
}

/*IE7 compatibility : */
.ie7-clear {
    display: block;
    clear: both;
}


.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.page-container {
    width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*    .App-logo {*/
/*        animation: App-logo-spin infinite 20s linear;*/
/*    }*/
/*}*/

h3 {
    align-items: center;
    align-content: center;
    text-align: center;
}

.App-link {
    color: #61dafb;
}


#coco {
    position: absolute;
    pointer-events: none;
}

#coco:hover {
    opacity: 0.8;
}

#coco-notification {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 778;
}

#coco-notification.hidden {
    display: none;
}

/***************** CURRENCY ICON *****************/

.yv-currency-icon-div {
    background-image: url("https://asset-host.b-cdn.net/assets/global/logos/logo_diamond_64x64.png");
    background-size: 100% 100%;
}

.currency-icon-64 {
    width: 6.4rem;
    height: 6.4rem;
}

.currency-icon-48 {
    width: 4.8rem;
    height: 4.8rem;
}

.currency-icon-32 {
    width: 3.8rem;
    min-width: 10px;
    min-height: 10px;
    height: 3.8rem;
}

@media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1700px) {
    .currency-icon-32 {
        width: 2.6rem;
        height: 2.6rem;
    }

    @media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1500px) {
        .currency-icon-32 {
            width: 2.3rem;
            height: 2.3rem;
        }

        @media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1200px) {
            .currency-icon-32 {
                width: 1.8rem;
                height: 1.8rem;
            }

            @media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1050px) {
                .currency-icon-32 {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }
    }
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {

    .currency-icon-32 {
        width: 3.6rem;
        height: 3.6rem;
    }

    @media screen and (max-width: 1200px) {
        .currency-icon-32 {
            width: 3rem;
            height: 3rem;
        }
    }
}

@media screen and (max-aspect-ratio: 425 / 538) {
    .currency-icon-32 {
        width: 5.8rem;
        min-width: 0;
        min-height: 0;
        height: 5.8rem;
    }

    @media screen and (max-width: 800px) {
        .currency-icon-32 {
            width: 3.8rem;
            min-width: 0;
            min-height: 0;
            height: 3.8rem;
        }

    }
    @media screen and (max-width: 540px) {
        .currency-icon-32 {
            width: 2.3rem;
            height: 2.3rem;
        }
    }
    @media screen and (max-width: 450px) {
        .currency-icon-32 {
            width: 1.7rem;
            height: 1.7rem;
        }
    }
}

.currency-icon-24 {
    width: 2.4rem;
    height: 2.4rem;
}

.currency-icon-20 {
    width: 2rem;
    height: 2rem;
}

.currency-icon-16 {
    width: 1.6rem;
    height: 1.6rem;
}

/*******************TRUE RANDOM******************/
#powered-by-true-random-sol {
    width: 20%;
    min-width: 150px;
    max-width: 230px;
    margin-right: 0;
    margin-left: auto;
}

#powered-by-true-random-sol img {
    max-width: 100%;

}

/*****************HC*****************/
#health-check-page {
    background-color: #d5984f;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/************ANIMATION***********/


@-webkit-keyframes spinning /* Safari and Chrome */
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinning {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinning {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
}

/* ENDLESS ROTATE */
.rotate {
    animation: rotate 4.5s linear infinite;
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: inset -2px 0 0 2px #0bf;
}

.transparent {
    opacity: 0;
}

.parent:hover .child {
    /*display: block;*/

    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-aspect-ratio: 384 / 265) and (max-height: 850px) and (min-aspect-ratio: 426 / 538) {
    .currency-icon-32 {
        width: 3rem;
        height: 3rem;
    }

    @media screen and (max-width: 1270px) {
        .currency-icon-32 {
            width: 3.2rem;
            height: 3.2rem;
        }
    }
    @media screen and (max-width: 1060px) {
        .currency-icon-32 {
            width: 3rem;
            height: 3rem;
        }

        @media screen and (max-width: 810px) {
            .currency-icon-32 {
                width: 2.1rem;
                height: 2.1rem;
            }

        }
    }
}