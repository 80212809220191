

#store-page #right-side {
    display: flex;
    margin-right: 0;
    margin-left: auto;
    z-index: 6;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

#store-page #right-side .lower-part {
    /*height: 575px;*/
    /*margin-top: 2vh;*/
}

#right-side .right-side-content {
    flex: 1;
    color: #3e1a22;
}

#store-page #right-side .upper-part .upper-spacer{
 display: none;
}

#store-page #right-side .upper-part .purchase-total-container {
    align-content: center;
    justify-content: center;
    align-items: center;
}

#store-page #right-side .timer.faction-box-refresh {
    margin-top: 4vh;
    font-size: 3rem;
    margin-bottom: 7vh;
    background-color: rgba(131, 97, 100, 0.5);
    color: #ffc594;
    text-shadow: 1px 1px #0c1426;
    padding: 3px 6px;
    border-radius: 50vh;

    /*margin-left: 0;*/
    /*margin-right: auto;*/
}
@media screen and (max-width: 1500px) {
    #store-page #right-side .timer.faction-box-refresh {
        font-size: 2.4rem;
        margin-top: 5.5vh;
        margin-bottom: 6vh;
    }
    @media screen and (max-width: 1200px) {
        #store-page #right-side .timer.faction-box-refresh {
            font-size: 2.0rem;
        }
    }
}

@media screen and (max-height: 840px) {
    #store-page #right-side .timer.faction-box-refresh {
        margin-top: 5.5vh;
        margin-bottom: 4vh;
    }

    #right-side .lootbox-pricing-container {
        overflow-y: hidden;
    }

}


#store-page #right-side .upper-part .purchase-total-container .btn{
    width: 20rem;
    flex: 1 1;
    padding: 5px 24px;
    font-size: 6.5rem;
    margin-bottom: 10px;
    border-radius: 15px;
}
@media screen and (max-width: 1560px) {
    #store-page #right-side .upper-part .purchase-total-container .btn{
        width: 13rem;
        flex: 1 1;
        padding: 5px 24px;
        font-size: 4rem;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 1200px) {
        #store-page #right-side .upper-part .purchase-total-container .btn{
            flex: 1 1;
            padding: 5px 24px;
            font-size: 3.4rem;
            margin-bottom: 10px;
            border-radius: 10px;
        }
        #store-page #right-side .timer.faction-box-refresh {
            font-size: 2.0rem;
        }
    }
}

#store-page #right-side .upper-part .purchase-total-container #purchase-total-label{
    font-family: "MusticaPro", sans-serif, Serif;
    font-size: 4.8rem;
}
@media screen and (max-width: 1200px) {
    #store-page #right-side .upper-part .purchase-total-container #purchase-total-label{
        font-size: 3rem;
    }
}
#store-page #right-side .upper-part .purchase-total-container #purchase-total-label .yv-currency-icon-div{
    margin: auto 0 auto 15px;
}
@media screen and (max-width: 1560px) {
    #store-page #right-side .upper-part .purchase-total-container #purchase-total-label .yv-currency-icon-div{
        margin: auto 0 auto 15px;
        width: 4rem;
        height: 4rem;
    }
    @media screen and (max-width: 1200px) {
        #store-page #right-side .upper-part .purchase-total-container #purchase-total-label .yv-currency-icon-div{
            margin: auto 0 auto 15px;
            width: 3rem;
            height: 3rem;
        }
    }
}

#store-page #right-side .lootbox-container {
    height: 80%;
    width: 31%;
    /*background : radial-gradient(circle, rgba(65, 28, 55, 0.86) 30%, rgba(7, 3, 10, 0.17) 76%, rgba(7, 3, 10, 0) 92%, rgba(0, 0, 0, 0) 100%);*/
}

#store-page #right-side .lootbox-container .control-panel .controls {

    padding-top: 1vh;
    padding-bottom: 1vh;
}

#store-page #right-side .bottom-divider {
    width: 100%;
    height: 2px;
    margin-top: 1.5vh;
    background: rgb(255,255,255);
    background: linear-gradient(173deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);
}

#store-page #right-side .red .bottom-divider {
    background: linear-gradient(90deg, rgba(255,255,255,0) 85%, rgba(255,255,255,1) 100%);
}

#store-page #right-side .blue .bottom-divider {
    background: linear-gradient(173deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%);
}

#store-page #right-side .upper-part .bottom-divider {
    background: linear-gradient(173deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 80%);

}
@media screen and (max-aspect-ratio: 425 / 538){
    @media screen and (min-height:600px){
        #store-page #right-side .upper-part .bottom-divider {
            margin-top: 4%;
    
        }
    }

}
#store-page #right-side .lootbox-container .control-panel .controls {
    margin-top: 16.66%;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
}



#store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
    width: 30%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: "MusticaPro", sans-serif, Serif;
    font-weight: 900;
    color: #48230f;
    font-size: 3.6rem;
}

#store-page #right-side .lootbox-container .control-panel .arrows {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    opacity: 90%;
    height: 4vh;
    width: 2vw;
}

#store-page #right-side .lootbox-container .control-panel .arrows:hover {
    opacity: 95%;
}

#store-page #right-side .lootbox-container .control-panel .arrows.arrow-right{
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
    text-align: left;
    padding-left: 0px;
    color: #b4890d;
    width: 15%;

}


#store-page #right-side .lootbox-container .control-panel .arrows.arrow-left{
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    text-align: right;
    padding-right: 0px;
    color: #b4890d;
    width: 15%;

}

#store-page #right-side .lootbox-container .control-panel .arrows.arrow-right:hover,
#store-page #right-side .lootbox-container .control-panel .arrows.arrow-left:hover {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_hower.png");
}

#store-page #right-side .lootbox-container .control-panel .arrows.arrow-left.disabled,
#store-page #right-side .lootbox-container .control-panel .arrows.arrow-right.disabled
{
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_disable.png");
}

#store-page #right-side .lootbox-pricing-container {
    justify-content: center;
    margin: 0 auto 0 auto;
}

#store-page #right-side .lootbox-pricing-container .small-lootbox-container {
    margin-top: 0.5vh;
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: top 20% center;
    width: 94%;
    width: calc(94% - 3px);
}

.small-lootbox-container .lootbox-pricing-calc {
    justify-content: center;
    margin-bottom: 3%;
}

.small-lootbox-container .lootbox-pricing {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.small-lootbox-container .lootbox-pricing span.price {
    font-size: 3.2rem;
}


#store-page #right-side .yv-currency-icon-div {
    margin-left: 3px;
}

#store-page #right-side .lootbox-pricing-container .vertical-divider {
    height: 100%;
    width: 2px;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4374124649859944) 75%, rgba(255,255,255,0) 100%);
}

#store-page #right-side .lootbox-pricing-container .vertical-divider.right {
    height: 0;
    width: 0;
    background: none;
}


.lootbox-purchase-info-tooltip {
    height: 4.4rem;
    font-size: 4.4rem;
    line-height: 2.6rem;
    margin: 1rem auto auto 1.2rem;
    cursor: pointer;
    color: #3e1a22;
    opacity: 0.6;
}

@media screen and (max-width: 1500px) {
    .lootbox-purchase-info-tooltip {

    font-size: 3rem;
    }
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 1.6rem;
    }
    #store-page #right-side .yv-currency-icon-div {
        width: 2rem;
        height: 2rem;
        margin-left: 0;
    }
    #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
        font-size: 3rem;
    }

    #store-page #right-side .lootbox-pricing-container .small-lootbox-container {
        margin-top: 5px;
        /*align-items: flex-start;*/
    }
}
@media screen and (max-height: 910px) and (max-width: 1460px) {
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 2rem;
    }
}

@media screen and (max-aspect-ratio: 425 / 538) {
    .lootbox-purchase-info-tooltip {

        font-size: 2rem;
        }
        .small-lootbox-container .lootbox-pricing span.price {
            font-size: 1.4rem;
        }
        #store-page #right-side .yv-currency-icon-div {
            width: 1.4rem;
            height: 1.4rem;
            margin-left: 0;
        }
        @media screen and (max-aspect-ratio: 426 / 539){
            #store-page #right-side .yv-currency-icon-div {
                width: 3.4rem;
                height: 3.4rem;
                margin-left: 0;
            }
        }
        .small-lootbox-container .lootbox-pricing span.pcs,
        .small-lootbox-container .lootbox-pricing span.total {
            font-size: 3rem;
        }
        #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
            font-size: 2.4rem;
        }
        
    
}
@media screen and (max-width: 1200px) {
    .lootbox-purchase-info-tooltip {

    font-size: 2rem;
    }
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 1.4rem;
    }
    #store-page #right-side .yv-currency-icon-div {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0;
    }
    @media screen and (max-aspect-ratio: 426 / 539){
        #store-page #right-side .yv-currency-icon-div {
            width: 3.4rem;
            height: 3.4rem;
            margin-left: 0;
        }
    }
    .small-lootbox-container .lootbox-pricing span.pcs,
    .small-lootbox-container .lootbox-pricing span.total {
        font-size: 1rem;
    }
    #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
        font-size: 2.4rem;
    }
    

}
@media screen and (max-width: 850px) {
    .lootbox-purchase-info-tooltip {
    font-size: 1.3rem;
    }
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 1rem;
    }
    #store-page #right-side .yv-currency-icon-div {
        width: 1.4rem;
        height: 1.4rem;
        margin-left: 0;
    }
    @media screen and (max-aspect-ratio: 426 / 539){
        #store-page #right-side .yv-currency-icon-div {
            width: 2.4rem;
            height: 2.4rem;
            margin-left: 0;
        }
    }
    .small-lootbox-container .lootbox-pricing span.pcs,
    .small-lootbox-container .lootbox-pricing span.total {
        font-size: 0.6rem;
    }
}
#store-page #right-side .lootbox-representation {
    aspect-ratio: 262/261;
    height: auto;
    width: 100%;
    background-size: 75% auto;
    background-position-x: center;
    background-position-y: center;
}

#store-page #right-side .lootbox-representation .lootbox-counter {
    display: none;
}



/* .lootbox-container.red .small-lootbox-container {

    width: 11.5vw;
}
.lootbox-container.green .small-lootbox-container {

    width: 11.5vw;
}
.lootbox-container.blue .small-lootbox-container {

    width: 11.5vw;
} */
.small-lootbox-container {
    width: 11.5vw;
}
.lootbox-pricing.increase-amount-container, .lootbox-pricing.decrease-amount-container {
    display: none;
    width: auto;
    aspect-ratio: 1;
}

.lootbox-pricing.increase-amount,
.lootbox-pricing.decrease-amount {
    border-radius: 50%;
    height: 90%;
    width: auto;
    aspect-ratio: 1;
}

@media screen and (max-width: 1850px) and (min-width:1501px){
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 2.6rem;
    }
}
    @media screen and (max-width: 1850px) and (min-width: 1200px){
    .small-lootbox-container .lootbox-pricing span.pcs,
    .small-lootbox-container .lootbox-pricing span.total {
        font-size: 1.6rem;
    }
}
@media screen and (max-width: 1850px) and (min-width:1500px){
    #store-page #right-side .yv-currency-icon-div {
        width: 2.6rem;
        height: 2.6rem;
        margin-left: 0;
    }
}

@media screen and (max-height: 1440px) and (max-width: 2560px){
    #store-page #right-side .upper-part .purchase-total-container {
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}

/*tablet view*/
@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) and ( min-width: 1025px) {
    .small-lootbox-container .lootbox-pricing span.price {
        font-size: 3rem;
    }

    .small-lootbox-container .lootbox-pricing span.pcs,
    .small-lootbox-container .lootbox-pricing span.total {
        font-size: 2.0rem;
    }

    #store-page #right-side .yv-currency-icon-div {
        width: 3.2rem;
        height: 3.2rem;
        margin-left: 3px;
    }

    #store-page #right-side .lootbox-representation {
        background-size: contain;
        width: auto;
        height: 90%;
        margin: auto 0;
    }

    #store-page #right-side .lootbox-representation .lootbox-counter {
        display: flex;
        font-size: 1.5rem;
        padding: 2px 1px 0 0;
        border-radius: 50vh;
        aspect-ratio: 1;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin: 0 auto auto 0;
    }

    .lootbox-purchase-info-tooltip{
        display: none;
    }

    .lootbox-purchase-info-tooltip .question-circle {
        width: 2.4rem;
        height: 2.4rem;
        margin-top: 12.5%;
        display: none; /* TODO */
    }

    #store-page #right-side .lootbox-pricing-container .small-lootbox-container {
        width: 95%;
        background: none;
        margin-left: 5%;
        height: 90%;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

}
/*TODO: WRONG CONDITIONING, either scale up with min-width or scale down with max-width
   */
@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) and ( max-width: 1024px) {
    .small-lootbox-container .lootbox-pricing span.price,
    #store-page #right-side .yv-currency-icon-div{
        font-size: 2.4rem;
    } 
    .small-lootbox-container .lootbox-pricing span.pcs,
    .small-lootbox-container .lootbox-pricing span.total {
        font-size: 1.4rem;
    }
    #store-page #right-side .yv-currency-icon-div {
        width: 2.4rem;
        height: 2.4rem;
    }
    @media screen and ( max-width: 630px) {
        .small-lootbox-container .lootbox-pricing span.price,
        #store-page #right-side .yv-currency-icon-div{
            font-size: 1.6rem;
        }
        .small-lootbox-container .lootbox-pricing span.pcs,
        .small-lootbox-container .lootbox-pricing span.total {
            font-size: 1rem;
        }
        #store-page #right-side .yv-currency-icon-div {
            width: 2rem;
            height: 2rem;
        }
        @media screen and ( max-width: 560px) {
            .small-lootbox-container .lootbox-pricing span.price,
            #store-page #right-side .yv-currency-icon-div{
                font-size: 1rem;
            }
            .small-lootbox-container .lootbox-pricing span.pcs,
            .small-lootbox-container .lootbox-pricing span.total {
                font-size: 0.6rem;
            }
            #store-page #right-side .yv-currency-icon-div {
                width: 1.4rem;
                height: 1.4rem;
            }
        }
    }




}

@media screen  and (max-aspect-ratio: 425 / 538) and (max-width: 490px) {
    #store-page #right-side .small-lootbox-container .lootbox-representation {
        margin-right: 5px;
    }
}
@media screen and  (max-width: 424px){
    #store-page #right-side .lootbox-pricing-container .vertical-divider {
        display: none;
    
    }

}
@media screen and (max-aspect-ratio: 425 / 538)  {
    @media screen and (max-width: 1070px) {
        .small-lootbox-container .lootbox-pricing span.price {
            font-size: 3rem;
        }

        .small-lootbox-container .lootbox-pricing span.pcs, .small-lootbox-container .lootbox-pricing span.total {
            font-size: 2rem;
        }
        @media screen and (max-width: 700px) {
            .small-lootbox-container .lootbox-pricing span.price {
                font-size: 2rem;
            }
    
            .small-lootbox-container .lootbox-pricing span.pcs, .small-lootbox-container .lootbox-pricing span.total {
                font-size: 1rem;
            }
        }
    }
}
#store-page #right-side .red .bottom-divider {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
}

#store-page #right-side .blue .bottom-divider {
    background: linear-gradient(173deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 15%);
}

#store-page #right-side .bottom-divider {
    width: 100%;
    height: 2px;
    margin-top: 1.5vh;
    background: rgb(255, 255, 255);
    background: linear-gradient(173deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 1) 100%);
}

.small-lootbox-container .lootbox-pricing-calc {
    margin-right: auto;
    margin-left: auto;
    border-radius: 50vh;
    justify-content: space-around;
    background-color: #f0ffff33;

}
@media screen and (min-aspect-ratio: 384 / 265){
    .small-lootbox-container .lootbox-pricing-calc {
        /*margin-right: 0;*/
        /*margin-left: 0;*/
        border-radius: 50vh; /* TODO: half of element height */
        justify-content: center;
        background-color: #ffffff00;
    }
}
@media screen and (max-aspect-ratio: 384 / 265) {
    .small-lootbox-container .lootbox-pricing-calc .decrease-amount-container,
    .small-lootbox-container .lootbox-pricing-calc .increase-amount-container {
        display: flex;
        height: 100%;
        aspect-ratio: 1;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .small-lootbox-container .lootbox-pricing-calc .decrease-amount-container button,
    .small-lootbox-container .lootbox-pricing-calc .increase-amount-container button{
        display: flex;
        font-size: 2.4rem;
        font-weight: bold;
    }

    .small-lootbox-container .lootbox-pricing-calc {
        margin-bottom: 0;
        align-items: center;
        align-content: center;

    }
}

#store-page #right-side .lootbox-pricing-container {
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    margin: 0 0 0 0;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 270px;
    overflow-x: clip;
    /*TODO : solve overflow scroll on displays shorter than 730px */
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) and (max-width: 1024px) {
    #right-side .small-lootbox-container {
        width: 100%;
        background: none;
        margin-left: 5%;
        height: 90%;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    #store-page #right-side .lootbox-representation {
        height: 95%;
        width: auto;

    }
    #store-page #right-side .lootbox-representation .lootbox-counter {
        display: flex;
        font-size: 1.2rem;
        padding: 1px 0px 0 0;
        margin: 5px auto auto 5px;
    }
}
@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {
#store-page #right-side .lootbox-pricing-container .vertical-divider {
    height: 2px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(173deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 75%);
    position: absolute;
    bottom: 0%;
}
}
@media screen and (max-aspect-ratio: 426 / 538) {
    #store-page {
        /*background-image: none;*/
    }

    #store-page #right-side .lootbox-purchase-info-tooltip {
        display: none;
    }

    #store-page #right-side .lootbox-representation .lootbox-counter {
        display: flex;
        font-size: 1.9rem;
        height: 2.4rem;
        width: 2.4rem;
        padding: 1px 1px 1px 0px;
        margin: 0 auto auto 0;
    }
    #store-page #right-side .lootbox-pricing-container {
        height: 100%;
        justify-content: space-between;
        margin: 0;
        position: relative;
        align-items: center;
    }

    #store-page #right-side .lootbox-representation {
        background-size: contain;
        height: 100%;
        width: auto;
        margin-right: 30px;
    }
    
    @media screen and (max-width: 580px) {
        #store-page #right-side .lootbox-representation {
            height: 75%;
        }
        #store-page #right-side .lootbox-pricing-container .small-lootbox-container {
            padding: 0 5%;
        }
    }
    #store-page #right-side .lootbox-container .control-panel {
        display: none;
    }
    #store-page #right-side .lootbox-container {
        height: 80%;
        width: 100%;
        position: relative;
    }

    #store-page #right-side .upper-part .purchase-total-container #purchase-total-label {
        /*font-size: 2.8rem;*/
    }

    #store-page #right-side .lootbox-pricing-container .vertical-divider {
        display: none;
    }
    #store-page #right-side .lootbox-pricing-container .small-lootbox-container {
        justify-content: center;
        margin: 0 auto;
        background: none;
        height: 60%;
        flex-direction: row;
        padding: 0 10%;
        align-items: center;
    }
    #store-page #right-side .upper-part .bottom-divider {
        background: none;
    }

    #store-page #right-side .bottom-divider {
        width: 70%;
        height: 2px;
        margin-top: 0;
        left: 15%;
        background: rgb(255, 255, 255);
        background: linear-gradient(173deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%);
        /*position: absolute;*/
    }
    @media screen and (max-height: 820px) {
        #store-page #right-side .lower-part {
            height: 575px;

        }
        #store-page #right-side .lootbox-container {
            height: 30%;
        }
    }

    #store-page #right-side .lootbox-container .control-panel {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    #store-page #right-side .blue .bottom-divider {
        background: none;
    }

    #store-page #right-side .red .bottom-divider {
        background: linear-gradient(173deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%);
    }
    #store-page #right-side .upper-part .purchase-total-container .btn {
        width: auto;
        border-radius: 15px;
        flex: 1 1;
        padding: 10px 24px;
        font-size: 5.5rem;
        margin-bottom: 10px;
    }
    #store-page #right-side {
        display: flex;
        width: 100%;
    } 
    #store-page #right-side .upper-part .purchase-total-container {
        align-content: center;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }
    @media screen and (min-height: 600px){
        #store-page #right-side .upper-part .purchase-total-container {
            align-content: center;
            justify-content: center;
            align-items: center;
            margin-top: 10%;
            margin-bottom: 10%;
        }
    }
    #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
        display: none;
    }
    
}
@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {
    #store-page #right-side .upper-part .bottom-divider {
        display: none;
    }

    #store-page #right-side .lootbox-container {
        height: 30%;
        width: 100%;
        justify-content: center;
        position: relative;
    }
    #store-page #right-side .lootbox-pricing-container {
        min-height: 0;
        flex-direction: column;
        flex: 1;
    }

    #store-page #right-side .lootbox-container .control-panel  {
        display: none;
    }


    #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
        width: 30%;
        display: none;
        align-content: center;
        justify-content: center;
        align-items: center;
        font-family: "MusticaPro", sans-serif, Serif;
        font-weight: 900;
        color: #48230f;
    }

}