#arena-picker-page,
#arena-passive-list {
    background-color: #2a0f17;
    background: url("https://asset-host.b-cdn.net/assets/inventory/plants_top_left.png") left top no-repeat,
    url("https://asset-host.b-cdn.net/assets/inventory/plants_top_right.png") right top no-repeat,
    url("https://asset-host.b-cdn.net/assets/arena-picker/BG_pre_batle.png") center top no-repeat;
    /*background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;*/
    background-size: auto, auto, cover;
    /*background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;*/
    background-size: auto, auto, cover;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

/*#arena-picker-page #left-side #vertical-pre-wrap {*/
/*    height: 4.654545454545455%;*/
/*}*/
/*#arena-picker-page #left-side #vertical-post-wrap {*/
/*    height: 16.29090909090909%;*/
/*}*/

/*#arena-picker-page #left-side,*/
/*#arena-passive-list #left-side {*/
/*    height: 100%;*/
/*    margin-left: 2.150943396226415%;*/
/*    margin-right: 0;*/
/*    background-color: #2e4308;*/
/*}*/
/*#arena-picker-page #right-side {*/
/*    margin-right: -1.6015625%;*/
/*}*/

#arena-picker-page #portal-outer-container,
#arena-passive-list #portal-outer-container {
    height: 77.81818181818182%;
    background: rgb(65, 28, 55);
    background: radial-gradient(circle, rgba(65, 28, 55, 0.86) 30%, rgba(7, 3, 10, 0.86) 76%, rgba(7, 3, 10, 0.86) 92%, rgba(0, 0, 0, 0.86) 100%);
    border-radius: 5%;
}

#portal-picker-default-container,
#pooled-bananas-list {
    padding: 5% 1.25% 17px;
    height: 100%;
    /*background-color: #93b4d9;*/
    /*background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/rectangle.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100% 100%;*/
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/frame.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

}


#portal-picker-custom-container {
    height: 20%;
    padding: 0 10%;
    background-color: rgba(147, 180, 217, 0.27);
}

.vertical-divider {
    background: rgb(244, 244, 244);
    background: linear-gradient(0deg, rgba(244, 244, 244, 0) 15%, rgba(250, 250, 250, 0.64) 30%, rgba(255, 255, 255, 0.75) 50%, rgba(251, 251, 251, 0.64) 70%, rgba(251, 251, 251, 0) 85%);
    width: 3px;
    height: 100%;
}

@media screen and (max-aspect-ratio: 425 / 538) {
    .vertical-divider {
        display: none;
    }
}

#arena-picker-page #left-side .portal {
    margin: 0 0.15%;
    width: 33%;
    /*background-color: rgba(133, 65, 62, 0.22);*/
    height: 90%;
    color: #cecece;
}

#arena-picker-page #portal-picker-custom-container .portal {
    height: 80%;
    width: 50%;
}

#arena-picker-page #left-side .portal {
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    /*border: 10px solid darkblue;*/
    width: 70%;
}

#arena-picker-page #left-side .portal:hover {
    /*border: 10px solid lightblue;*/
    /*cursor: pointer;*/
}

#arena-picker-page #left-side .portal.disabled {
    opacity: 0.6;
}

#arena-picker-page #left-side .portal.disabled:hover,
#arena-picker-page #left-side .portal.disabled:hover button {
    /*border: 10px solid darkblue;*/
    cursor: not-allowed;
}


#arena-picker-page #left-side .portal.selected {
    /*border: 10px solid lightcyan;*/
}

.portal .pool-title {
    font-size: 9rem;
    margin: 10px auto 0px;
    padding: 0;
    text-align: center;
    font-family: "PassionOne", sans-serif, Serif;
    color: #cfcece;
}

.portal:hover .pool-title {
    /*color: #ffffff;*/
    /*text-shadow: #a8a8a8 0 0 15px;*/
}

.portal.wood .pool-title {
    color: #a15855;
}

.portal.wood:hover .pool-title {
    color: #be6e6b;
}

.portal.silver .pool-title {
    color: #b9c1cf;
}

.portal.silver:hover .pool-title {
    color: #dae1ee;
}

.portal.gold .pool-title {
    color: #f4cd58;
}

.portal.gold:hover .pool-title {
    color: #ffe595;
}

/*.portal.gold:hover .pool-title {*/
/*    color: #d5d2ca;*/
/*}*/
/*.portal.wood:hover .pool-title {*/
/*    color: #dad4ce;*/
/*}*/

.portal:hover .btn {
    box-shadow: 0 0 3px #000000;
}


.portal .info .fees {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 15pt;
    /*margin-top: 15px;*/
}

.portal-fee-tooltip {
    display: flex;
}

.portal-fee-tooltip p span {
    display: inline;
    color: #f1cc5b;
    align-content: center;
    align-items: center;
}

.portal .info .fees .label {
    width: 58.78%;
    min-width: 58.78%;
}

.portal .fee-container,
.portal .reward-container {
    width: 100%;
    margin: 8% auto;
    align-content: center;
    align-items: center;
    /*justify-content: space-around;*/
    justify-content: space-between;
}
.portal .info {
    height: 89%;
    align-content: center;
    align-items: center;
}

.portal .icon-container {
    height: 47%;
    width: 87%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.portal.wood .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/wood_inactive.png");
}

.portal.wood:hover .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/wood_active.png");
}

.portal.wood.disabled .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/wood_disabled.png");
}

.portal.silver .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/silver_inactive.png");
}

.portal.silver:hover .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/silver_active.png");
}

.portal.silver.disabled .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/silver_disabled.png");
}

.portal.gold .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/gold_inactive.png");
}

.portal.gold:hover .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/gold_active.png");
}

.portal.gold.disabled .icon-container {
    background-image: url("https://asset-host.b-cdn.net/assets/arena-picker/gold_disabled.png");
}

.portal .pool-info-container h5 {
    font-size: 15pt;
    color: #cecece;
    margin: 7pt auto;

    display: flex;
    align-content: center;
    align-items: center;

}

.portal .pool-info-container h5 span {
    /*display: inline;*/
    color: #f1cc5b;
    margin: auto 5px;
}

.portal .pool-info-container h5 span.valueless {
    margin: auto;
}

/*.passive-container, active-container {*/
/*    display: flex;*/
/*}*/

.passive-container {
    margin-top: 5%;
}

.portal .btn {
    width: 100%;
    height: auto;
    margin: auto;
    font-size: 15.6pt;
    padding: 6.5px 0px

}


#info-container #pool-current-info span,
#info-container #pool-basic-info span {
    margin: 0 1.5%;
    justify-content: center;
    align-items: center;
    display: flex;
}

#arena-picker-page #coco {
    bottom: 2.727273%;
    width: 17.8515625%;
    left: 28.7%;
    z-index: 100;
}

#portal-picker-mobile-container {
    display: none;
}

.coco_arena {
    pointer-events: none;
}


/* descktop */

@media screen and (max-width: 2460px) {

    .portal .info .fees .label {
        font-size: 1.7rem;
    }

    #arena-picker-page .content {
        font-size: 1.8rem;
    }

    .portal .pool-info-container h5 {
        font-size: 1.6rem;
    }

    .portal .btn {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 2160px) {

    .portal .info .fees .label {
        font-size: 1.4rem;
    }

    #arena-picker-page .content {
        font-size: 1.5rem;
    }

    .portal .pool-info-container h5 {
        font-size: 1.4rem;
    }

    .portal .btn {
        font-size: 1.4rem;
    }

    .portal .pool-title {
        font-size: 6rem;
    }
}

@media screen and (max-height: 1250px) {
    @media screen and (max-width: 2550px) {

        .portal .info .fees .label {
            font-size: 1.4rem;
        }

        #arena-picker-page .content {
            font-size: 1.4rem;
        }

        .portal .pool-info-container h5 {
            font-size: 1.6rem;
        }

        .portal .btn {
            font-size: 1.6rem;
        }

        @media screen and (max-width: 1860px) {
            .portal .info .fees .label {
                font-size: 1.1rem;
            }

            #arena-picker-page .content {
                font-size: 1.1rem;
            }

            .portal .pool-info-container h5 {
                font-size: 1.3rem;
            }

            .portal .btn {
                font-size: 1.3rem;
            }
        }
        @media screen and (max-height: 880px) {
            @media screen and (max-width: 1500px) {
                .portal .info .fees .label {
                    font-size: 0.9rem;
                }

                #arena-picker-page .content {
                    font-size: 0.9rem;
                }

                .portal .pool-info-container h5 {
                    font-size: 1rem;
                }

                .portal .btn {
                    font-size: 1rem;
                }

                @media screen and (max-width: 1200px) {
                    .portal .info .fees .label {
                        font-size: 0.7rem;
                    }

                    #arena-picker-page .content {
                        font-size: 0.7rem;
                    }

                    .portal .pool-info-container h5 {
                        font-size: 0.9rem;
                    }

                    .portal .btn {
                        font-size: 0.9rem;
                    }

                }
            }
        }
        @media screen and (max-height: 1000px) {
            .portal .pool-title {
                margin: 0;
            }
        }
        @media screen and (max-height: 700px) {
            @media screen and (max-width: 1300px) {
                .portal .info .fees .label {
                    font-size: 0.6rem;
                }

                #arena-picker-page .content {
                    font-size: 0.8rem;
                }

                .portal .pool-info-container h5 {
                    font-size: 0.9rem;
                }

                .portal .btn {
                    font-size: 0.9rem;
                }
            }
        }
        @media screen and (max-height: 700px) {
            @media screen and (max-width: 1600px) {
                .portal .pool-title {
                    font-size: 3rem;
                    margin: 0;
                }
            }
        }
        @media screen and (max-width: 1060px) {
            .portal .info .fees .label {
                font-size: 0.9rem;
            }

            #arena-picker-page .content {
                font-size: 1rem;
            }

            .portal .pool-info-container h5 {
                font-size: 1rem;
            }

            .portal .btn {
                font-size: 1rem;
            }

            @media screen and (max-width: 1260px) {
                .portal .info .fees .label {
                    font-size: 0.5rem;
                }

                #arena-picker-page .content {
                    font-size: 0.5rem;
                }

                .portal .pool-info-container h5 {
                    font-size: 0.6rem;
                }

                .portal .btn {
                    font-size: 0.6rem;
                }
            }

        }
    }
}


/* tablet */


@media screen and (max-aspect-ratio: 384 / 265) {
    #arena-picker-page #coco {
        left: 27.7%;
    }

    #arena-picker-page #footer {
        display: flex;
    }

    .portal .pool-title {
        margin: 0;
    }

    #portal-picker-default-container {
        display: none;
    }

    #portal-picker-mobile-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-image: url(https://asset-host.b-cdn.net/assets/arena-picker/frame.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .portal .info .fees .label {
        font-size: 3.7rem;
    }

    #arena-picker-page .content {
        font-size: 3.7rem;
    }

    .portal .pool-info-container h5 {
        font-size: 3.4rem;
    }

    .portal .btn {
        font-size: 3.4rem;
    }

    .portal .info .fees-wrapper {
        width: 98%;
    }

    @media screen and (max-width: 2000px) {
        .portal .info .fees .label {
            font-size: 3rem;
        }

        #arena-picker-page .content {
            font-size: 3rem;
        }
    }
    @media screen and (max-width: 1820px) {
        .portal .pool-info-container h5 {
            font-size: 3.3rem;
        }

        .portal .btn {
            font-size: 3.3rem;
        }
    }
    @media screen and (max-width: 1670px) {
        .portal .pool-info-container h5 {
            font-size: 3rem;
        }

        .portal .btn {
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 1520px) {
        .portal .info .fees .label {
            font-size: 2.5rem;
        }

        #arena-picker-page .content {
            font-size: 2.5rem;
        }

        .portal .pool-info-container h5 {
            font-size: 2.6rem;
        }

        .portal .btn {
            font-size: 2.6rem;
        }
    }
    @media screen and (max-width: 1360px) {
        .portal .pool-info-container h5 {
            font-size: 2.3rem;
        }

        .portal .btn {
            font-size: 2.3rem;
        }
    }
    @media screen and (max-width: 1295px) {
        .portal .info .fees .label {
            font-size: 2rem;
        }

        #arena-picker-page .content {
            font-size: 2rem;
        }

        .portal .pool-info-container h5 {
            font-size: 2rem;
        }

        .portal .btn {
            font-size: 2rem;
        }
    }
    @media screen and (max-width: 1060px) {
        .portal .info .fees .label {
            font-size: 1.5rem;
        }

        #arena-picker-page .content {
            font-size: 1.5rem;
        }

        .portal .pool-title {
            font-size: 5rem;
            margin: 0;
        }

        .portal .btn {
            font-size: 1.7rem;
        }

        .portal .pool-info-container h5 {
            font-size: 1.4rem;
            margin: 0;
        }

        @media screen and (max-width: 810px) {
            .portal .info .fees .label {
                font-size: 1.1rem;
            }

            .portal .pool-title {
                font-size: 4rem;
                margin: 0;
            }

            .portal .btn {
                font-size: 1.1rem;
            }

            .portal .pool-info-container h5 {
                font-size: 1rem;
                margin: 0;
            }

            @media screen and (max-width: 650px) {
                .portal .info .fees .label {
                    font-size: 0.9rem;
                }

                .portal .btn {
                    font-size: 1.3rem;
                }

                .portal .pool-info-container h5 {
                    font-size: 1rem;
                }

                @media screen and (max-width: 500px) {
                    .portal .info .fees .label {
                        font-size: 0.7rem;
                    }

                    .portal .btn {
                        font-size: 1rem;
                    }

                    .portal .pool-info-container h5 {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}


/* mobile */


@media screen and (max-aspect-ratio: 425 / 538) {
    #arena-picker-page .footer {
        display: flex;
    }

    #arena-picker-page #coco {
        display: none;
    }

    #arena-picker-page #left-side,
    #arena-passive-list #left-side {
        height: 60%;
        width: 90%;
        margin: 0;
    }

    .portal .info .fees {
        margin: 0;
    }

    .portal .fee-container {
        margin: 2% 0 0;
    }

    .portal .reward-container {
        margin: 2% 0 0;
    }

    #arena-picker-page #portal-outer-container,
    #arena-passive-list #portal-outer-container {
        height: 100%;
    }

    .portal .info {
        height: 90%;
    }

    @media screen and (max-width: 1070px) {
        .portal .info .fees .label {
            font-size: 3rem;
        }

        #arena-picker-page .content {
            font-size: 3rem;
        }

        .portal .pool-info-container h5 {
            font-size: 3rem;
        }

        @media screen and (max-width: 760px) {
            .portal .info .fees .label {
                font-size: 2rem;
            }

            #arena-picker-page .content {
                font-size: 2rem;
            }

            .portal .pool-info-container h5 {
                font-size: 2rem;
            }

            @media screen and (max-width: 510px) {
                .portal .info .fees .label {
                    font-size: 1.4rem;
                }

                #arena-picker-page .content {
                    font-size: 1.4rem;
                }

                .portal .pool-info-container h5 {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: 370px) {
                    .portal .info .fees .label {
                        font-size: 1rem;
                    }

                    #arena-picker-page .content {
                        font-size: 1rem;
                    }

                    .portal .pool-info-container h5 {
                        font-size: 1rem;
                    }

                }
            }
        }
    }
}