#inventory {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/BG_dim.png");
    font-family: "MusticaPro", sans-serif, Serif;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    overflow-x: hidden;
    overflow-y: auto;
}


#inventory .divider {
    height: 3px;
    min-height: 3px;
    background: rgb(241, 225, 211);
    background: linear-gradient(90deg, rgba(255, 252, 240, 0) 12.5%, rgb(241, 225, 211) 51%, rgba(255, 252, 240, 0) 87.5%);
}


#inventory #top-manipulator {
    width: 1000px;
    z-index: 7;
    /*background-color: magenta;*/
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 auto 0;
}

#inventory #inventory-showcase {
    width: 100%;
}

#inventory #banana-profile {
    flex: 1300;
    /*background-color: rgba(255, 0, 255, 0.27);*/
    z-index: 3;
}

#inventory #set-wrapper {
    width: 100%;
    display: flex;
    flex: 1;
}

#inventory #banana-profile #carousel-wrapper {
    display: flex;
    flex: 1000;
    align-content: center;
    justify-content: center;
    align-items: center;
    /*background-color: #00bbff;*/
}

#inventory #banana-profile #carousel-faction-damage {
    display: flex;
    flex: 300;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#inventory #banana-profile .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#inventory #portal-manipulator {
    width: 80%;
    /*margin: 0 20% 0 0;*/
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    color: #cecece;
}

#carousel-wrapper #banan-full-size {
    flex: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


#carousel-wrapper #carousel-faction-damage {
    flex: 2;
    width: 20%;
}

#carousel-faction-damage .set-damage {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-size: 2.4rem;
}

#carousel-faction-damage .set-damage.red {
    color: #ff5b67;
}

#carousel-faction-damage .set-damage.green {
    color: #d0ff7e;
}

#carousel-faction-damage .set-damage.blue {
    color: #6cc9ff;
}

#carousel-faction-damage .set-damage {
    /*margin: 22.8px 0;*/
    margin: 3px 0;
}

#carousel-faction-damage .set-damage img {
    width: 137px;
}

#carousel-faction-damage .set-damage div {
    width: 163px;
}

#inventory .divider {
    width: 100%;
}

/* ----------------------- CARD MODULE ----------------------- */

#card-module {
    min-height: 300px;
    width: 95.3125%;
    margin: 25px 2.34375%;
    /*background-color: rgba(20, 42, 15, 0.3);*/
}

@media screen and (max-width: 1800px) {

    #inventory .container-1600 {
        width: 1200px;
    }

    #inventory #set-wrapper {
        flex-direction: column;
    }

    #inventory #inventory-showcase {
        flex-direction: column-reverse;
    }

    #inventory #banana-profile #carousel-faction-damage {
        flex-direction: row;
        width: 73%;
        margin-left: 2%;

    }

    #inventory #arsenal-selection .selected-items-list {
        flex-direction: row;

    }

    #inventory #arsenal-selection .selected-uid-info {
        width: 25%;
        margin: -176px 15px 185px auto;
    }
}

@media screen and (max-width: 1400px) {

    #inventory .container-1600 {
        width: 800px;
    }

    #inventory #card-module-manipulator {
        flex-direction: column;
    }

    #arsenal-selection .square-inventory-wrap {
        flex-direction: column;
    }

    #inventory #arsenal-selection .square-inventory-wrap .square-inventory-damage{
        text-align: center;
        margin-left: 47%;
    }


    #inventory #banana-profile #carousel-faction-damage {
        width: 100%;
        margin-left: 0;
    }

    #inventory #carousel-faction-damage .set-damage {
        width: 30%;
    }

    #inventory #arsenal-selection .selected-uid-info {
        width: 100%;
        margin: 50px auto -25px;
    }
}

@media screen and (max-width: 900px) {

    #inventory .container-1600 {
        width: 680px;
    }

    #carousel-faction-damage .set-damage div {
        text-align: center;
        margin: 0 auto;
         width: auto;
        display: flex;
    }

    #inventory #carousel-faction-damage  {
        margin-bottom: -30px;
    }
    #inventory #carousel-faction-damage .set-damage {
        flex-direction: column-reverse;
    }
    #inventory #carousel-faction-damage .set-damage{
        width: 24%;
    }
    #carousel-faction-damage .set-damage img {
        width: 100px;
    }
    #carousel-faction-damage .set-damage span {
        display: none;
    }

}


@media screen and (max-width: 725px) {
    #inventory .container-1600 {
        width: 550px;
    }

    #filter-cards {
        flex-direction: column;
    }

    #filter-groups {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    /*#inventory #set-wrapper {*/
    /*    flex-direction: row;*/
    /*}*/
    /*#inventory #inventory-showcase {*/
    /*    flex-direction: column-reverse;*/
    /*}*/
    /*#inventory #banana-profile #carousel-faction-damage {*/
    /*    flex-direction: column;*/
    /*    margin-left: 0;*/
    /*}*/
    /*#inventory #banana-profile {*/
    /*    width: 420px;*/
    /*}*/
}


@media screen and (max-width: 620px) {
    #inventory .container-1600 {
        width: 500px;
    }
    #arsenal-selection .square-inventory-wrap, .duel-selected-set .square-inventory-wrap {
        width: 20%;
    }

    #inventory-showcase {
        margin-top: 37px;
    }
}

@media screen and (max-width: 510px) {
    #inventory .container-1600 {
        width: 350px;
    }


    #inventory #arsenal-selection .square-inventory-wrap .square-inventory-damage, .duel-selected-set .square-inventory-wrap .square-inventory-damage {
        font-size: 1.6rem;
        margin-left: 115%;
    }

    #filter-groups {
        flex-direction: column;
    }

    .card-manipulator-top-level {
        flex-direction: column;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #c3cace;
    }
}
@media screen and (max-width: 370px) {

    #inventory .container-1600 {
        width: 100%;
    }

}
/*#inventory-showcase {*/
/*    background-color: rgba(10, 59, 6, 0.43);*/
/*}*/