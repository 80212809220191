#wallet-connect-component {
    margin-top: auto;
    margin-right: 10px;
    margin-bottom: auto;
    margin-left: 0;
    border-radius: 22px;
    background-color: indianred;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 60%;
}

#wallet-connect-component #wallet-connected-address {
    padding: 0 10px;
    color: white;
    font-weight: bold;
    background-color: #182239;
    border-radius: 20px;
    display: flex;
    height: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 2rem;
}
@media screen and (max-width: 1500px) {
    #wallet-connect-component #wallet-connected-address {

        font-size: 2rem;
    }
}




@media screen and (max-width: 1024px) {
    #wallet-connect-component #wallet-connected-address {
        font-size: 1.3rem;
    }
}

#wallet-connect-component div.wallet-icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 25px 0 15px;
    margin-right: -20px;
    background-color: #324263;
    color: #cfcece;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

#wallet-connect-component div.wallet-icon div {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#wallet-connect-component button {
    border: none;
    background-color: transparent;
    height: 100%;
    padding: 0 15px 0 30px;
    margin-left: -20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2rem;
}
@media screen and (max-width: 1024px){
    #wallet-connect-component button {
        font-size: 1rem;
    }
    
}

#wallet-connect-component button:hover {
    color: #85413e;
    cursor: pointer;
}

#wallet-connect-component .login-button-container {
    float: right;
    margin: auto 5px auto auto;
}

#wallet-connect-component .login-button {
    float: right;
    margin: auto 0 auto auto;
}

.login-popup {
    width: 280px;
    min-width: 250px;
    max-width: 290px;
}

.login-popup-content {
    flex-direction: column;
}

.login-popup img {
    width: 100%;
    height: auto;
}

.login-popup-content .flex-container-columns {

    align-items: center;
}

#copy-pair-string {
    margin-left: 1%;
    transform: rotateY(180deg);
}
