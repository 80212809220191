#upper-bound-manipulator {
    margin: 40px auto 0;
    width: 880px;
    background: rgb(102,102,102);
    background: linear-gradient(0deg, rgba(102,102,102,1) 2%, rgba(45,45,45,1) 7%, rgba(23,23,23,1) 45%);
    height: 40px;
    z-index: 9;
    position: absolute;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

#duel-result #upper-bound-manipulator {
    width: 1600px;
    margin: 0px auto 0;
}

#inventory #top-manipulator #upper-bound-manipulator,
#arena-picker #top-manipulator #upper-bound-manipulator {
    width: 880px;
    margin: 40px auto 0;
}

#upper-bound-manipulator:before,
#upper-bound-manipulator:after {

    content: "";
    display: block;
    position: absolute;
    width: 58px;
    height: 61px;
    z-index: 8;
    background: rgb(102,102,102);
    background: linear-gradient(0deg, rgba(218, 15, 15, 0) 51.99%,  rgba(102,102,102,1) 52%, rgba(45,45,45,1) 57%, rgba(23,23,23,1) 95%);
}

#upper-bound-manipulator:before {
    margin-left: -50.5px;
    margin-top: -10px;
    transform: rotate(45deg);
}
#upper-bound-manipulator:after {
    margin-left: 872px;
    /*margin-right: -50.5px;*/
    margin-top: -50px;
    transform: rotate(-45deg);
}
#duel-result #upper-bound-manipulator:after {
    margin-left: 1592px;
    /*margin-right: -50.5px;*/
    margin-top: -50px;
    transform: rotate(-45deg);
}

#portal-carousel-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex: 1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

#portal-carousel-actions button {
    color: #ffdf81;
    text-decoration: none;
    font-size: 2.0rem;
    padding: 5px;
}

#duel-result #top-manipulator {
    height: 40px;
}

#upper-bound-manipulator .banana-warrior-label {
    color: #c3cace;
    font-size: 2.0rem;
    flex: 1;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1810px) {
    #duel-result #upper-bound-manipulator {
        width: 1200px;
    }

    #duel-result #upper-bound-manipulator:after {
        margin-left: 1192px;
    }
}

@media screen and (max-width: 1400px) {
    #inventory #top-manipulator {
        width: 800px;
    }

    #inventory #top-manipulator #upper-bound-manipulator {
        width: 760px;
    }
    #upper-bound-manipulator:after {
        margin-left: 753px;
    }

    #portal-carousel-actions {
        justify-content: space-between;
    }

    #portal-carousel-actions button {
        margin: 0 20px;
    }

    #duel-result #duel-detail-box {
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 1326px) {
    #duel-result #upper-bound-manipulator {
        width: 950px;
    }

    #duel-result #upper-bound-manipulator:after {
        margin-left: 942px;
    }

    #upper-bound-manipulator .banana-warrior-label.left {
        margin-right: 12.5%;
    }
    #upper-bound-manipulator .banana-warrior-label.right {
        margin-left: 12.5%;
    }
}

@media screen and (max-width: 1100px) {
    #duel-result #upper-bound-manipulator {
        width: 100%;
    }
    #duel-result #top-manipulator {
        width: 100%;
    }

    #duel-result #upper-bound-manipulator:after,
    #duel-result #upper-bound-manipulator:before {
        display: none;
    }


}

@media screen and (max-width: 900px) {
    #inventory #top-manipulator {
        width: 650px;
    }

    #inventory #top-manipulator #upper-bound-manipulator {
        width: 600px;
    }
    #upper-bound-manipulator:after {
        margin-left: 593px;
    }

    #portal-carousel-actions {
        justify-content: space-between;
    }

    #portal-carousel-actions button {
        margin: 0 20px;
    }
}

@media screen and (max-width: 835px) {

    #duel-result #top-manipulator #upper-bound-manipulator {
        height: 75px;
        /*margin-top: 70px;*/
    }
    #duel-result #top-manipulator {
        height: 75px;
    }
}

@media screen and (max-width: 725px) {
    #inventory #top-manipulator {
        width: 550px;
    }
    #inventory #top-manipulator #upper-bound-manipulator {
         width: 500px;
     }
    #upper-bound-manipulator:after {
        margin-left: 492px;
    }

    #portal-carousel-actions button {
        font-size: 1.6rem;
        margin: 0 16px;
    }
}


@media screen and (max-width: 620px) {
    #inventory #top-manipulator {
        width: 100%;
    }

    #inventory #top-manipulator #upper-bound-manipulator {
        width: 100%;
        height: 75px;
        margin-top: 70px;
    }

    #upper-bound-manipulator:after,
    #upper-bound-manipulator:before {
        display: none;
    }
}

@media screen and (max-width: 535px) {
    #duel-result #upper-bound-manipulator .banana-warrior-label {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 400px) {
    #duel-result #upper-bound-manipulator .banana-warrior-label {
        font-size: 1.4rem;
        margin-top: 28px;
    }

    #duel-result #top-manipulator,
    #duel-result #top-manipulator #upper-bound-manipulator {
        height: 95px;
    }
}


@media screen and (max-width: 340px) {
    #duel-result #upper-bound-manipulator .banana-warrior-label {

        /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
        -ms-word-break: break-all;
        word-break: break-all;
        /* Non standard for webkit */
        word-break: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    #upper-bound-manipulator .banana-warrior-label.right {
        margin-left: 22%;
    }
}