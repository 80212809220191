#investor-page {
    padding-top: 150px; /* adjust padding based on logo height*/
    height: 100%;
    background: #dba11c;
}

.rewards-list-container {
    width: 100%;
    overflow-x: auto;
    align-items: baseline;
}
.rewards-list-container {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.rewards-list-container::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.rewards-list {
    width: auto;
    overflow-x: hidden;
}
.rewards-list-scrollbars .view {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 250px;
}

.investor-payout-sample {
    padding-right: 20px;
    margin-right: 10px;
    border-right: 1px dotted grey;
    width: 300px;
}

.investor-payout-sample .reward-timestamp {
    color: grey;
    font-size: 10pt;
}

.investor-payout-sample .investor-payout-sample-amount {
    font-size: 18pt;
    font-weight: 700;
}
.investor-payout-sample label {
}
.investor-payout-sample .yv-currency-icon {
    margin-left: 3px;
}

.investor-payout-sample span {
    align-content: center;
    align-items: center;
    margin-left: 3px;
    font-weight: 700;
}

.gov-token-main-container {
    margin-top: 100px;

}

.gov-token-chart-container {
    margin: 0 auto 0 0;
    width: 700px;
    height: 750px;
}

.gov-token-chart {
    width: 700px;
    height: 700px;
}

.gov-token-chart #tradingview_5e0fa {
    height: 700px;
}

.token-info-container {

    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}
.token-info-container-row {
    width: 100%;
    align-items: flex-start;
}

.token-info-container a.buy-gov-token {
    width: 100%;
    text-decoration: none;
}

.token-info-container .button.buy-gov-token {
    font-size: 24pt;
    padding: 5pt 10pt;
    width: 80%;
    height: auto;
    text-decoration: none;
}