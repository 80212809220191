.currency-form-inputs {
    flex: 1;
    display: flex;
    align-content: center;
    align-items: center;
    color: #e7e6e7;
    text-shadow: 0px 1px 5px rgba(18, 20, 52, 1);
    font-size: 1.9rem;
    /*font-family: "Inclusive Sans", "MusticaPro", sans-serif, Serif;*/
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

.currency-form-inputs .icon {
    width: 24px;
    height: 24px;
}

.currency-form-inputs .upper-balance {
    display: flex;
    width: 80%;
    align-content: center;
    align-items: center;
}

.USD-to-YVC.currency-form-inputs .upper-balance {
    margin-left: 0;
    margin-right: 20%;
}
.YVC-to-USD.currency-form-inputs .upper-balance {
    margin-right: 0;
    margin-left: 20%;
}
.YVC-to-USD.currency-form-inputs .upper-balance h3 {
    text-align: right;
    margin-right: 0;
    margin-left: auto;
}

.currency-form-inputs .amount-selector-container {
    width: 90%;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.USD-to-YVC.currency-form-inputs .amount-selector-container {
    margin-left: 0;
    margin-right: 10%;
}

.YVC-to-USD.currency-form-inputs .amount-selector-container {
    margin-right: 0;
    margin-left: 10%;
}

.currency-form-inputs .amount-selector-slider {
    display: flex;
    flex: 9;
}

.currency-form-inputs .amount-selector-input {
    display: flex;
    flex: 4;
    align-content: center;
    align-items: center;
}

.currency-form-inputs .amount-selector-input input {
    width: calc(80% - 50px);
    margin: auto 0 auto auto ;
    border: none;
    /* border-bottom: 2px solid white; */
    border-radius: 10px;
    padding-right: 1rem;
    color: #e7e6e7;
    text-shadow: 0px 1px 5px rgba(18, 20, 52, 1);
    height: 40px;
    padding-right: 46px;
    margin-right: -40px;
    text-align: end;
    /*width: 200px;*/
    font-size: 1.9rem;
    background-color: rgba(206, 206, 206, 0.2);
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

.currency-form-inputs .amount-selector-input .icon{
    width: 32px;
    height: 32px;
    margin-right: 6px;
}

.currency-form-inputs .action-flex {
    width: 90%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.USD-to-YVC.currency-form-inputs .action-flex  {
    margin-left: 0;
    margin-right: 10%;
}

.YVC-to-USD.currency-form-inputs .action-flex  {
    margin-right: 0;
    margin-left: 10%;
}

.currency-form-inputs .action-flex p {
    flex: 6;
}

.currency-form-inputs .action-flex span {
    min-height: 46px;
    display: flex;
    flex: 2;
    align-items: center;
    align-content: center;
    /*justify-content: space-between;*/
    padding: 0 9px;
    border-radius: 9px;
    background-color: rgba(206, 206, 206, 0.2);
}

.currency-form-inputs .action-flex span .icon {
    /*width: 24px;*/
    /*height: 24px;*/
    /*margin-right: 6px;*/
    width: 3.8rem;
    min-width: 10px;
    min-height: 10px;
    height: 3.8rem;
}

.currency-form-inputs .action-flex .actions {
    display: flex;
    flex: 4;
    align-content: center;
    align-items: center;
    height: 100%;
}
.currency-form-inputs .action-flex .actions button {
    /*width: 160px*/
    display: flex;
    width: 80%;
    height: 46px;
    border-radius: 9px;
    margin: auto 0 auto auto ;
}