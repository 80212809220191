#rookie-box-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /*font-family: "MusticaPro", sans-serif, Serif;*/
}

#rookie-box-open img {
    width: auto;
    max-width: 100%;
}

#rookie-box-open button.primary {
    max-width: 300px;
}

#rookie-box-open p {
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    display: flex;
    /*flex-direction: row;*/
    color: #ffddc1;
    text-shadow: 0px 2px 4px rgba(24,43,58,0.45);
    text-shadow: 0px 1px 3px rgba(18,20,52,1);
    text-shadow: 0px 1px 5px rgba(18,20,52,1);
    font-size: 1.6rem;
}

#rookie-box-open p span {
    color: #fa4969;
    margin: auto 9px auto 3px;
}
