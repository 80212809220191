
#arena-picker-module {
    display: flex;
    justify-content: center;
    border-top: 3px solid #c4dafd;
    padding-top: 10px;
    margin-top: 5px;
    padding-bottom: 150px;
}

#arena-picker-module .portal {
    /*background-color: magenta;*/
    height: 380px;
    width: 305px;
    margin: auto 30px;
}

#arena-picker-module .portal h4 {
    font-size: 3.4rem;
    /*color: #ffd75f;*/
    text-shadow: 0px 2px 4px rgba(24, 43, 58, 0.45);
    font-weight: 500;
    font-family: "PassionOne", sans-serif, Serif;
    margin: 9px auto 3px;
}


#arena-picker-module .portal .info .fees-wrapper {
    width: 88%;
    padding-left: 2%;
    display: flex;
    flex-direction: row;
    color: #c3cace;
}

#arena-picker-module .portal .fee-container {
    margin: 0px auto;
}

#arena-picker-module .portal .reward-container {
    margin: 0px auto;
}


#arena-picker-module .portal .fees span {
    color: #c3cace;
    font-size: 1.6rem;
    margin: auto 3px;
}

@media screen and (max-width: 1800px) {
    #arena-picker-module {
        margin-top: 40px;
    }
}

@media screen and (max-width: 900px) {
    #arena-picker-module {
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    #arena-picker-module .portal {

        border-bottom: 1px solid #c3cace;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}

