#arena-matchmaking {
    background-color: #006699;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background:
        /*url("https://asset-host.b-cdn.net/assets/inventory/plants_top_left.png") left top no-repeat,*/
        /*url("https://asset-host.b-cdn.net/assets/inventory/plants_top_right.png")  right top no-repeat,*/
            url("https://asset-host.b-cdn.net/assets/inventory/BG.png") center top no-repeat;
    /*background: url(img_flwr.gif) right bottom no-repeat, url(paper.gif) left top repeat;*/
    background-size: auto, auto, cover;
    flex: 1;
    /*flex-direction: column;*/
    overflow-x: hidden;
    overflow-x: clip;
    /*overflow: clip;*/

}

#matchmaking-info {
    height: 15%;
    color: #0c1426;
    display: flex;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    /*text-shadow: 1px 1px 3px #000;*/
    font-size: 16pt;
}

.matchmaking-info-header {
    color: rgba(255, 209, 98);
    font-size: 23pt;
    margin: 3% auto;
}

.matchmaking-info-header h3 {
    display: inherit;
    margin: 0 auto;
}

.pool-info-last-updated {
    margin-top: 1%;
}

.box{
    background-color: rgba(177, 216, 228, 0.08);
    width: 90%;
    height: 80%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    box-shadow: inset 0 0 20px #155;
    /*border-radius: 20px;*/
    border: 2px solid #a9c4f9;
    overflow: hidden;
}

.box .background-text {
    font-size: 60pt;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    /*font-weight: bold;*/
    /*font-family: "PassionOne", sans-serif;*/
    color: rgba(13, 19, 39, 0.55);

}

.box #dvd-coco {
    display: block;
    width: 277px;
    height: 279px;
    /*border-radius: 50%;*/
    /*background-color: #999999;*/
    /*box-shadow: inset -5px -5px 5px rgba(0,0,0,.6), 15px 15px 2px rgba(0,0,0,.04);*/
    position: absolute;
    -webkit-animation: moveX 6s linear 0s infinite alternate, moveY 10.2s linear 0s infinite alternate;
    -moz-animation: moveX 6s linear 0s infinite alternate, moveY 10.2s linear 0s infinite alternate;
    -o-animation: moveX 6s linear 0s infinite alternate, moveY 10.2s linear 0s infinite alternate;
    animation: moveX 6s linear 0s infinite alternate, moveY 10.2s linear 0s infinite alternate;
}

@-webkit-keyframes moveX {
    from { left: 0; -webkit-transform: rotate(0deg); } to { left: calc(100% - 277px); -webkit-transform: rotate(360deg); }
}
@-moz-keyframes moveX {
    from { left: 0; -moz-transform: rotate(0deg);} to { left: calc(100% - 277px);  -moz-transform: rotate(360deg);}
}
@-o-keyframes moveX {
    from { left: 0; -o-transform: rotate(0deg);} to { left: calc(100% - 277px); -o-transform: rotate(360deg); }
}
@keyframes moveX {
    from { left: 0; transform: rotate(0deg);} to { left: calc(100% - 277px); transform: rotate(360deg);}
}

@-webkit-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 279px); }
}
@-moz-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 279px); }
}
@-o-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 279px); }
}
@keyframes moveY {
    from { top: 0; } to { top: calc(100% - 279px); }
}