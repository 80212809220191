#duel-result {
    background-image: url("https://asset-host.b-cdn.net/assets/d/BG.png");
    background-size: cover;
    background-position: center;
    min-height: 100%;
    overflow-y: auto;
}

#duel-result #upper-block {
    display: flex;
    align-content: center;
    align-items: normal;
    justify-content: flex-start;
}

#duel-result #banana-sets-showcase {
    margin: 0 auto;
    width: 100%;
    height: 675px;
    display: flex;
    /*background-color: #0a3b06;*/
    justify-content: space-between;
    align-content: center;
}


#duel-result .banana-set {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    /*background-color: rgba(10, 59, 6, 0.64);*/
}

#duel-result .banana-set#left-arena-portal {
    transform: rotateY(180deg);
}

#duel-result .carousel-wrapper {
    display: flex;
    flex: 800;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    /*background-color: rgba(0, 187, 255, 0.63);*/
}

#duel-result .carousel-wrapper #banan-full-size,
#duel-result .carousel-wrapper #banan-full-size .assembly {
    /*width: 762px;*/
    height: 722px;
    /*height: calc(762px / 103 * 115);*/
    width: calc(722px / 115 * 103);
    animation: none;
}

#duel-result .banana-set .coconut {
    height: 201px;
    margin-top: -104px;
    margin-left: 75px;
    margin-right: auto;

}

#duel-result #duel-detail-box {
    border-top: 3px solid #cecece;
    padding-top: 15px;
    display: flex;
    height: 500px;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

#duel-result #duel-resolution-box {
    flex: 1000;
}

#duel-result .duel-selected-set {
    height: 500px;
}

@media screen and (min-width: 1811px) and (min-aspect-ratio: 1811 / 1305) {
    #duel-result .duel-selected-set {
        padding-bottom: 90px;
    }
}

@media screen and (max-width: 1810px) {
    #duel-result.full-width .container-1600 {
        width: 1200px;
    }

    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        height: 670px;
        width: calc(670px / 115 * 103);
    }

    #duel-result .duel-selected-set {
        padding-bottom: 75px;
    }
}

@media screen and (max-width: 1326px) {
    #duel-result.full-width .container-1600 {
        width: 950px;
    }

    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 475px;
        height: calc(475px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 475px;
        height: calc(475px / 565 * 543);
        margin-top: 0;
        margin-bottom: -572px;
        background-position-y: top;
        background-size: contain;
    }

    #duel-result #banana-sets-showcase {
        height: 586px;
    }
}

@media screen and (max-width: 1100px) {

    #duel-result.full-width .container-1600 {
        width: 100%;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 980px) {

    .duel-selected-set .square-inventory-wrap {
        flex-direction: column;
    }

    .duel-selected-set .square-inventory-wrap .square-inventory-damage {
        text-align: center;
        margin-left: 60%;
    }

    .duel-selected-set .square-inventory-wrap .square-inventory-damage span {
        display: none;
    }

    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 380px;
        height: calc(380px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 380px;
        height: calc(380px / 565 * 543);
        margin-top: 0;
        margin-bottom: -450px;
    }

    #duel-result #banana-sets-showcase {
        height: 446px;
    }

    #duel-result .banana-set .coconut {
        height: 131px;
    }
}

@media screen and (max-width: 768px) {
    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 290px;
        height: calc(290px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 290px;
        height: calc(290px / 565 * 543);
        margin-top: 0;
        margin-bottom: -320px;
    }
    #duel-result #banana-sets-showcase {
        height: 386px;
    }

}


@media screen and (max-width: 675px) {
    #duel-result {
        overflow-y: hidden;
        overflow-x: hidden;
        min-height: auto;
        height: calc(100vh - 45px);
    }

    #duel-result #duel-detail-box {
        flex-direction: column;
        overflow-y: auto;
        overflow-x: clip;
        display: flex;
        height: auto;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
    }

    #duel-result #duel-detail-box #right-selected-set-items {
        display: flex;
        flex-direction: column-reverse;
    }

    #duel-result .duel-selected-set {
        align-content: center;
        align-items: center;
        padding-bottom: 20px;
        padding-top: 20px;
    }

    #duel-result .duel-selected-set#left-arena-portal {
        padding-top: 10px;
    }

    #duel-result .duel-selected-set#right-arena-portal {
        padding-bottom: 10px;
    }

    #duel-result .duel-selected-set .selected-items-list {
        flex-direction: row;
    }

    #duel-result  .duel-selected-set .square-inventory-wrap {
        width: 24.5%;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    #duel-result .duel-selected-set .square-inventory-wrap img {
        width: 95%;
        width: calc(100% - 10px);
        margin-left: 10px;
    }
    .duel-selected-set .square-inventory-wrap .square-inventory-damage {
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }

    .duel-selected-set .square-inventory-wrap .square-inventory-damage span {
        display: block;
        opacity: 0;
        content: '';
        width: 39%;
    }

    #duel-result .duel-selected-set .selected-uid-info {
        display: flex;
        padding: 15px;
    }
}

@media screen and (max-width: 585px) {
    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 240px;
        height: calc(240px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 240px;
        height: calc(240px / 565 * 543);
        margin-top: 0;
        margin-bottom: -280px;
    }
    #duel-result #banana-sets-showcase {
        height: 293px;
    }

    #duel-result .banana-set .coconut {
        height: 101px;
        margin-left: 35px;
    }
}

@media screen and (max-width: 490px) {
    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 190px;
        height: calc(190px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 190px;
        height: calc(190px / 565 * 543);
        margin-top: 0;
        margin-bottom: -230px;
    }
    #duel-result #banana-sets-showcase {
        height: 253px;
    }

    #duel-result .banana-set .coconut {
        height: 85px;
        margin-left: 20px;
    }

    #duel-result .duel-selected-set .selected-items-list {
        width: 100%;
        flex-wrap: wrap;
    }

    #duel-result .duel-selected-set .square-inventory-wrap {
        width: 49%;
    }
}

@media screen and (max-width: 400px) {
    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 155px;
        height: calc(155px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 155px;
        height: calc(155px / 565 * 543);
        margin-top: 0;
        margin-bottom: -185px;
    }

    #duel-result #banana-sets-showcase {
        height: 200px;
    }

    #duel-result .duel-resolved-block h1 {
        font-size: 7.5rem;
    }

    #duel-result .banana-set .coconut {
        height: 70px;
        margin-left: 20px;
    }
}

@media screen and (max-width: 320px) {
    #duel-result .carousel-wrapper #banan-full-size, #duel-result .carousel-wrapper #banan-full-size .assembly {
        width: 135px;
        height: calc(135px / 103 * 115);
    }

    #duel-result .carousel-wrapper #portal-front {
        width: 135px;
        height: calc(135px / 565 * 543);
        margin-top: 0;
        margin-bottom: -155px;
    }

    #duel-result #banana-sets-showcase {
        height: 180px;
    }

    #duel-result .banana-set .coconut {
        height: 55px;
        margin-left: 20px;
    }
}