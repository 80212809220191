#history-panel {
    /*width: 31.25%;*/
    /*margin: 0 -7.984375%; !*-8.984375*!*/
    margin: 0 auto; /*-8.984375*/
    z-index: 30;
}

.history-popup-content {
    width: 37.12142152146238%;
    width: calc(84.4vh - 65px);
    height: auto;
}

.banana-id-wrapper {
    margin-right: 15px;
    margin-left: auto;
    margin-top: 5%;
    background-color: rgba(59, 10, 10, 0.37);
    color: #ce4540;
    font-size: 17pt;
    padding: 1px 7px;
    font-family: "MusticaPro", sans-serif, Serif;
}

#middle-buffer-top {
    height: 0;
    width: 100%;
    /*background-color: rgba(255, 255, 255, 0.2);*/
}

#history-panel-contents {
    width: 100%;
    /*aspect-ratio: 398 / 495;*/
    /*background-image: url("https://asset-host.b-cdn.net/assets/arena-res/arena-popup/popup_end_game.png");*/
    /*background-size: 100% 100%;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    margin: 8% auto auto auto;
    font-family: MusticaPro, courier, sans-serif, Serif;
}

#history-panel-contents .flex-row {
    width: 100%;
}

#history-panel-contents #game-history-title {
    height: 205px;
    /*background-color: rgba(138, 43, 226, 0.42);*/
}

#history-panel-contents #game-history-title h2 {
    font-family: "PassionOne", sans-serif, Serif;
    font-weight: normal;
    text-align: center;
    font-size: 75pt;
    margin: auto;
    /*margin-top: 1%;*/
    padding-top: 5%;
    flex: 1 1 0%;
    background: -webkit-linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background: -moz-linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background: linear-gradient(0deg, rgb(246, 136, 92), rgb(255, 206, 113));
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}
#history-panel-contents .damage-circles-middle {
    flex: 1;
}


#history-panel-contents .divider {
    height: 3px;
    background: rgb(255,252,240);
    background: linear-gradient(90deg, rgba(255,252,240,0) 12.5%, rgba(255,185,100,1) 51%, rgba(255,252,240,0) 87.5%);
}


#history-panel-contents .duel {
    flex: 1;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    height: 50px;
    margin: 3px 15px;
    padding: 1px 5px;
    border-radius: 5px;
    font-size: 16pt;
}

#history-panel-contents .duel div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    height: 100%;
}

#history-panel-contents .duel .duel-id ,
#history-panel-contents .duel .blockchain-icon {
    /*flex: 1;*/
    justify-content: center;
    align-items: center;
    align-content: center;
    /*height: 50px;*/
    margin: auto 3px;
    /*padding: 1px 5px;*/
    /*border-radius: 5px;*/
}

#history-panel-contents .duel .icon {
    height: 25px;
    width: 25px;
    margin: auto 3px;
}

#history-panel-contents .duel .battle-players-info {
    flex: 4;
    justify-content: space-around;
}

#history-panel-contents .duel.defeat {
    background-color: rgba(255, 74, 74, 0.34);
}
#history-panel-contents .duel.victory {
    background-color: rgba(74, 255, 74, 0.34);
}

#history-panel-contents span.highlighted {
    color: gold;
}

#history-panel-contents .duel .battle-actions {
    flex: 1;
    height: 50px;
    justify-content: space-around;
}

.duel .new-icon {
    color: #E33054;
    /*height: 18px;*/
    /*width: 18px;*/
    /*margin-left: -5px;*/
    /*margin-top: -15px;*/
}

#history-panel-contents .battle-list  {
    height: 305px;
}

#history-panel-contents .pagination.actions {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#history-panel-contents .pagination.actions .pagination-icon{
    width: 64px;
}
#history-panel-contents .pagination.actions .pagination-button-container{
    width: 45%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#history-panel-contents .pagination.actions .pagination-loader-container,
#history-panel-contents .pagination.actions button.secondary {
    font-size: 2.0rem;
}

@media screen and (max-width: 768px) {
    #history-panel-contents .duel {
        flex-direction: column;
        height: 120px;
    }


    #history-panel-contents #game-history-title h2 {
        font-size: 50pt;
    }

    #history-panel-contents #game-history-title {
        height: 114px;
    }

    #history-panel-contents .battle-list {
        height: 55vh;
        overflow-y: auto;
    }

    .history-popup-content {
        width: 90vw;
        height: 80vh;
    }

    @media screen and (max-height: 820px) {
        #history-panel-contents .battle-list {
            height: 45vh;
        }
    }

    /*#history-panel-contents .duel {*/
    /*    font-size: 12pt;*/
    /*}*/
    /*#history-panel-contents .duel .battle-players-info {*/
    /*    flex: 3;*/
    /*}*/
    /*#history-panel-contents .duel .battle-actions {*/
    /*    flex: 1;*/
    /*}*/
    /*#history-panel-contents .duel .battle-actions .new-icon {*/
    /*    height: 15px;*/
    /*    width: 15px;*/
    /*    margin-left: -5px;*/
    /*    margin-top: -15px;*/
    /*}*/
}
@media screen and (max-width: 520px) {
    #history-panel-contents .duel {
        flex-direction: column;
        height: 180px;
    }

    #history-panel-contents #game-history-title h2 {
        font-size: 28pt;
    }

    #history-panel-contents .duel .battle-players-info {
        flex-direction: column;
    }
}