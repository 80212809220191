#homepage {
    /*background-color: rgba(255, 0, 0, 0.16);*/
    height: 100%;
    width: 100%;
    min-width: 250px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    background-image: url("https://asset-host.b-cdn.net/assets/homepage/BG.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-x: hidden;
}

#homepage .full-width {
    /*width: 100%;*/
    /*display: flex;*/
    min-width: 250px;
}

#homepage p, #homepage span {
    color: #fffbdc;
    text-shadow: 0px 3px 3px #000000;
}

#homepage span {
    display: inline;
    font-weight: bold;
    color: #f6c44e;
}

#homepage .full-width .container-1600 {
    /*width: 1600px;*/
    /*margin: 0 auto;*/
    /*display: flex;*/
    /*align-content: center;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}


#homepage-spoiler {
    height: 100%;
    width: 100%;
    background-image: url("https://asset-host.b-cdn.net/assets/homepage/main_screen_BG.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

hr.homepage-splitter {
    height: 11px;
    width: 100%;
    margin: 0;
    background-color: #9d514b;
    border: none;
    border-top: solid 3px #421a18;
    border-bottom: solid 3px #421a18;
}

#homepage h2, #homepage h3, #homepage h1 {
    background: #FFBF62;
    background: linear-gradient(to top, #FFBF62 0%, #FFF167 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #ff9c40;
    font-size: 4.6rem;
}

#homepage h3 {
    font-size: 3.2rem;
    text-shadow: none;
    -webkit-text-stroke: 0.5px #ff9c40;
}


.info-box {

    background-color: rgba(16, 28, 51, 0.3);
    border-radius: 6px;
    border: solid 2px #fce96c;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}
#homepage h1 {
    font-size: 6.2rem;
    /*text-shadow: none;*/
    -webkit-text-stroke: 0.5px #ff9c40;
    /*margin-top: 70px;*/
    height: 85px;
    margin: 0 auto 25px;
}

#homepage-main-image {
    /*aspect-ratio: 21/9;*/
    /*height: calc(100vw / 16 * 9);*/
    width: 100%;
    padding-top: 135px;
}
#homepage-main-image.full-width .container-1600 {
    justify-content: flex-start;
}

#homepage-main-image #main-img-wrapper {
    height: calc(100% - 120px);
    max-height: 60vh;
    width: 100%;
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
    align-content: center;

}

#homepage-main-image #main-img-wrapper img {
    /*width: calc(100);*/
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    /*object-fit: cover;*/
}

#homepage-main-image .actions {
    display: flex;
    justify-content: space-around;
    margin-top: -115px;
}

/* COCONUTS SECTION */

#homepage-three-cocos {
    padding: 100px 0 50px;
    /*background-image: url("https://asset-host.b-cdn.net/assets/homepage/BG.png");*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#homepage-three-cocos div.container-1600 {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
}


#homepage-three-cocos .coconut-section {
    /* flex: auto 1; */
    /*background-color: rgba(255, 165, 0, 0.32);*/
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
    justify-content: space-between;
}

#homepage-three-cocos .coconut-section.coco-1 {
    flex: 485;
}
#homepage-three-cocos .coconut-section.coco-2 {
    flex: 582;
}
#homepage-three-cocos .coconut-section.coco-3 {
    flex: 394;
}

#homepage-three-cocos .coconut-section img {
    max-width: 100%;
}
#homepage-three-cocos .coconut-section .info-box {
    width: 80%;
    height: 500px;
    margin: 0 auto -50%;
}
#homepage-three-cocos .coconut-section .info-box p {
    padding: 0 5%;
    font-size: 1.9rem;
}
#homepage-three-cocos .coconut-section.coco-2 .info-box p {
    font-size: 2rem;
}

#homepage-three-cocos .coconut-section .info-box h2 {
    background: #FFBF62;
    background: linear-gradient(to top, #FFBF62 0%, #FFF167 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #ff9c40;

    font-size: 3.2rem;
}

/* DOCS SECTION */
#homepage-docs-block {
    padding: 50px 0 25px;
    /*background-image: url("https://asset-host.b-cdn.net/assets/homepage/BG.png");*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


#homepage-docs-block .container-1600 {
    height: auto;
    flex-wrap: nowrap;
    justify-content: space-around;
}

#homepage-docs-block .container-1600 .content {
    justify-content: space-evenly;
}

#homepage-docs-block .container-1600 h2 {
    font-size: 4.2rem;

}

#homepage-docs-block .actions {
    width: 100%;
    display: flex;
}

#homepage-docs-block .actions a {
    display: flex;
    justify-content: space-around;
}
#homepage-docs-block .actions .primary {
    flex: 3;
    text-decoration: none;
}
#homepage-docs-block .actions .secondary {
    flex: 2;
}

#homepage-docs-block .actions button {
    width: auto;
    /*flex: 1;*/
}



#homepage-docs-block .yt-video {
    width: 45%;
    /*background-color: aqua;*/
    height: auto;
    margin-top: 0;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#homepage-docs-block .container-1600 p {
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#homepage-docs-block .yt-video video {
    width: 100%;
    max-width: 100%;
    border: solid 2px #fce96c;
}

#homepage #homepage-docs-block .gitbook img {
    display: flex;
    max-width: 100%;
}

#homepage #homepage-docs-block .gitbook {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}


#homepage #homepage-docs-block .gitbook .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-content: center;
}

#homepage-docs-block .yt-video p .finger-point {
    font-size: 2.4rem;

}
#homepage-docs-block .yt-video iframe {
    display: flex;
    width: 80%;
    height: auto;
    aspect-ratio: 16/9;
    border: solid 2px #fce96c;
}

/* BANANAS IN DUEL FORMAT */

#homepage-banana-battle {
    padding: 15px 0 15px;
    /*background-image: url("https://asset-host.b-cdn.net/assets/homepage/BG.png");*/
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#homepage #homepage-banana-battle .container-1600 {
    aspect-ratio: 1586/1156;
    flex-wrap: nowrap;
    background-image: url("https://asset-host.b-cdn.net/assets/homepage/sector_3.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

#homepage-banana-battle .info-box {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 55%;
}

#homepage-banana-battle .info-box .text-box {
    flex: 4;
    align-content: center;
    align-items: center;
}

#homepage-banana-battle .info-box .text-box h3 {
    margin: 3.2rem auto 1.6rem;
}
#homepage-banana-battle .info-box .text-box p {
    margin: 0.2rem auto 1.6rem;
    padding: 0 2rem;
}

#homepage-banana-battle .info-box .actions {
    display: flex;
    flex: 2 1;
    margin-top: auto;
    margin-bottom: 25px;
    justify-content: space-between;
    min-width: 55%;
}

#homepage-banana-battle .info-box .actions a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    text-decoration: none;
    flex: 2;
}
#homepage-banana-battle .info-box .actions a.primary {

    justify-content: flex-start;
    flex: 3;
}
#homepage-banana-battle .info-box .actions a .btn {
    width: auto;
}

#homepage-arena-battle .arena-content {
    padding-bottom: 50px;
}
#homepage-arena-battle .arena-content .text-box {
    display: flex;
    flex: 6;
    justify-content: flex-start;
    flex-direction: column;
    /*padding: 10% 0;*/
}

#homepage-arena-battle .arena-content .text-box p {
    width: 80%;
    margin: 0.5rem 10% 2rem;
    text-align: left;
    font-size: 1.83rem;
}
#homepage-arena-battle .arena-content .preview {
    display: flex;
    flex: 10;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://asset-host.b-cdn.net/assets/homepage/sector_4.png");
    aspect-ratio: 507/275;
}
/*#homepage-arena-battle .arena-content .preview img {*/
/*    width: 100%;*/
/*    height: auto;*/
/*}*/

/* MECHANICS */


#homepage-battle-mechanics h2 {
    font-size: 4.6rem;
}

#homepage-battle-mechanics .container-1600 {
    align-content: center;
    align-items: center;
}

#homepage-battle-mechanics img#battle-mechanics-symbols {
    max-width: 1200px;
    width: 75%;
}

#homepage-battle-mechanics a#mechanics-link {
    margin: 35px auto 30px;
}

#homepage-intro-sets .container-1600 {

    align-content: center;
    align-items: center;

}

#homepage-intro-sets .container-1600 img#set-stack {
    max-width: 1344px;
    width: 84%;
}

#homepage-intro-items .container-1600 {

    align-content: center;
    align-items: center;

}

#homepage-intro-items .container-1600 img#item-stack {
    max-width: 1424px;
    width: 89%;
}

#homepage-intro-items .container-1600 img#currency-stack {
    max-width: 1141px;
    width: 71.3125%;
}

#gov-token-presale {
    width: 37%;
    margin: 25px 19% -70px auto;
}

#gov-token-stack {
    margin-bottom: -12px;
    max-width: 1321px;
    width: 82.5625%;
}

#homepage-more-text a#roadmap-ref {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 50px;
}
#homepage-more-text a#roadmap-ref img {
    width: 100%;
}

.full-width .iterations h2 {
    margin: 1.5rem auto 0rem;
}

/* FOOTER */

#homepage #homepage-footer {
    padding: 50px 0 0;
    min-height: 250px;
    background-color: #0c1426;
}

#homepage #homepage-footer .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    /*height: 100%;*/
    /*background-color: #421a18;*/
    /*border-top: solid 3px #9d514b;*/
    /*border-bottom: solid 3px #9d514b;*/
}

#homepage #homepage-footer .socials .social-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    margin: 0 auto;
}

#homepage #homepage-footer .socials .social-box .socials-icon {
    font-size: 14.6rem;
    width: 160px;
    height: 160px;
}

#homepage #homepage-footer .socials .social-box .socials-icon.discord {
    color: #7289da;
}

#homepage #homepage-footer .socials .social-box .socials-icon:hover {
    color: #fffbdc;
    text-shadow: 0px 3px 3px #000000;
}

#homepage #homepage-footer .copyright .container-1600 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#homepage #homepage-footer .copyright {
    background-color: #090313;
    width: 100%;
    padding: 15px 0 15px;
    margin-top: 25px;
    margin-bottom: 0;
    color: #cecece;
}

.socials-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    /*font-size: 14.6rem;*/
    /*width: 160px;*/
    /*height: 160px;*/
}

.social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}


@media screen and (max-width: 1700px) {
    #homepage .full-width .container-1600 {
        width: 1200px;
    }

    #homepage-three-cocos .coconut-section .info-box p {
        font-size: 1.7rem;
    }
    #homepage-three-cocos .coconut-section.coco-2 .info-box p {
        font-size: 1.8rem;
    }

    #homepage-docs-block .yt-video {
        width: 60%;
    }

    #homepage-banana-battle .info-box {
        width: 60%;
    }
    #homepage-arena-battle .arena-content .text-box p {
        font-size: 1.6rem;
    }

    .container-1600.iterations img {
        margin-top: 25px;
    }
}

@media screen and (max-width: 1470px) {
    #homepage .full-width .container-1600 {
        width: 900px;
    }

    #homepage-docs-block .yt-video {
        width: 70%;
    }

    #homepage-banana-battle .info-box {
        width: 90%;
    }

    #homepage-banana-battle h2{
        margin: 0 auto;
    }

    #homepage-arena-battle .arena-content .text-box {
        flex: 8;
    }
    #homepage-arena-battle .arena-content .text-box p {
        margin: 0.5rem auto 0.5rem;
        font-size: 1.5rem;
    }

    #homepage-arena-battle .arena-content .preview {
        flex: 9;
    }

    .container-1600.iterations img {
        margin-top: 35px;
    }
    #homepage #homepage-footer .socials .social-box {
        width: 50%;
    }
}

@media screen and (max-width: 1024px) {
    #homepage .full-width .container-1600 {
        width: 600px;
    }
    #homepage-three-cocos .coconut-section .info-box h2 {
        font-size: 2.4rem;
    }

    #homepage-main-image {
        padding-top: 95px;
    }
    #homepage-three-cocos .coconut-section .info-box {
        height: 385px;
        width: 95%;
    }

    #homepage-three-cocos .coconut-section .info-box p {
        font-size: 1.4rem;
    }
    #homepage-three-cocos .coconut-section.coco-2 .info-box p {
        font-size: 1.5rem;
    }

    #homepage #homepage-docs-block .gitbook p{
        font-size: 1.2rem;
        margin: 0;
    }

    #homepage #homepage-docs-block .gitbook .actions .btn {
        padding: 7px 15px;
    }

    #homepage #homepage-docs-block .gitbook {
        font-size: 1.4rem;
    }

    #homepage-banana-battle h2 {
        font-size: 3.2rem;
    }

    #homepage #homepage-banana-battle .container-1600 {
        background-image: url("https://asset-host.b-cdn.net/assets/homepage/sector_3_mobile.png");
    }

    #homepage-arena-battle .arena-content {
        flex-direction: column;
    }
    #homepage-arena-battle .arena-content  .text-box {
        margin-bottom: 25px;
    }

    #homepage-main-image .actions .btn {
        font-size: 1.6rem;
        padding: 0.5rem 1.2rem;
    }

    #homepage-main-image .actions .btn .btn-text {
        font-size: 1.6rem;
    }

    #homepage-main-image .actions .btn .btn-icon {
        font-size: 2rem;
    }

}
@media screen and (max-width: 650px) {
    #homepage .full-width .container-1600 {
        width: 280px;
    }

    #homepage-three-cocos div.container-1600 {
        flex-direction: column;
    }

    #homepage-docs-block .container-1600 .content {
        flex-direction: column;
        display: flex;
        align-items: center;
        align-content: center;
    }
    #homepage #homepage-docs-block .yt-video,
    #homepage #homepage-docs-block .gitbook {
        width: 90%;
        display: flex;
        align-items: center;
        align-content: center;
    }

    #homepage #homepage-docs-block .yt-video svg,
    #homepage #homepage-docs-block .gitbook p
    {
        display: none;
    }
    #homepage .full-width .iterations h2 {
        font-size: 3.2rem;
    }
    #gov-token-presale {
        width: 100%;
        margin: 25px auto -39px;
    }
    #gov-token-stack {
        margin-bottom: -4px;
    }

    #homepage #homepage-footer .socials,
    #homepage #homepage-footer .copyright .container-1600{
        flex-direction: column;
    }
}