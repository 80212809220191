#store-page {
    background: url("https://asset-host.b-cdn.net/assets/store/colomn_left.png") left top no-repeat,
    url("https://asset-host.b-cdn.net/assets/store/colomn_right.png") right top no-repeat,
    url("https://asset-host.b-cdn.net/assets/store/BG.png") center top no-repeat;
    background-size: auto, auto, cover;
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
}

#store-page .lower-part {
    height: 66.98%;
    width: 100%;

}


#store-page #footer {
    height: 32%;
    margin-top: auto;
    bottom: 0;
    position: absolute;
    width: 100%;
    background-image: url("https://asset-host.b-cdn.net/assets/store/land.png");
    background-repeat: no-repeat, no-repeat, no-repeat;

    background-size: 100% 100%;
    background-position-y: bottom;
    background-position: bottom center;
    z-index: 2;
}

@media screen and (max-aspect-ratio: 425 / 538) {
    #store-page #footer {
        display: none;
    }

    #store-page {
        overflow-y: auto;
        overflow-x: clip;
        background: url(https://asset-host.b-cdn.net/assets/store/BG.png) center top no-repeat;
        background-size: cover;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

#store-page #coco {
    bottom: 10.109091%;
    width: 15.382813%;
    left: 8.7%;
    z-index: 3;
}

#store-page #left-side {
    width: 62.9296875%;
    height: 100%;
}

#store-page #right-side {
    width: 36.9140625%;
    height: 100%;
}

@media screen and (max-height: 910px) and (max-width: 1460px) {
    #store-page #left-side {
        width: 56%;
        height: 100%;
    }

    #store-page #right-side {
        width: 43%;
        height: 100%;
    }
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {
    #store-page {
        background: url(https://asset-host.b-cdn.net/assets/store/BG.png) center top no-repeat;
        background-size: cover;
    }

    #footer, #store-page #coco {
        display: none;
    }


    @media screen and ( max-width: 1024px) {
        #store-page #left-side {
            width: 38%;
        }

        #store-page #right-side {
            width: 62%;
        }
    }

    @media screen and ( max-width: 1250px) and (min-width: 1025px) {
        #store-page #left-side {
            width: 45%;
        }

        #store-page #right-side {
            width: 55%;
        }
    }

    @media screen and (min-width: 1251px) {
        #store-page #left-side {
            width: 50%;
        }

        #store-page #right-side {
            width: 50%;
        }
    }
}
/*PHONE*/
@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {
    #store-page {
        overflow-y: auto;
        overflow-x: clip;
        /*background: url(https://asset-host.b-cdn.net/assets/store/BG.png) center top no-repeat;*/
        /*background-size: cover;*/
    }
}

