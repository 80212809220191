#distribution-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /*justify-content: center;*/
    height: auto;
    min-height: 100vh;
    overflow-x: auto;
    width: 100vw;
    background-color: #222222;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    color: #ffffff;
}

#distribution-page h1 {
    color: #ffcc51;
    margin: 135px auto -5px auto;
    border-bottom: 2px solid #ffcc51;
    padding-bottom: 15px;
}

#contract-box-preview {
    /*background-color: rgba(142, 159, 196, 0.32);*/
    margin-bottom: 100px;
}

.contract-box {
    border: 2px solid #ffffff;
    /*background-color: rgba(255, 99, 71, 0.37);*/
    border-radius: 25px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.contract-box .gist-link {
    width: 80%;
    font-size: 1.8rem;
    height: 60px;
    display: flex;
}

.contract-box .gist-link svg {
    margin: 0 8px;
}

.contract-box p span {
    display: inline;
}

.contract-box p .bncp-gov-icon {
    width: 2rem;
    height: 2rem;
    margin: 0 5px;
}

.contract-box p {
    text-align: center;
}

#token-distribution {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    /*width: 100%;*/
    /*flex: 1;*/
    text-align: center;
}

.distribution-form,
#token-distribution-title {
    width: 80%;
    display: flex;
    flex: 14;
}

.distribution-form .form-row {
    /*background-color: rgba(255, 255, 255, 0.26);*/
    border: none;
    border-right: 3px solid white;
}

#token-distribution-title {
    flex: 1;
}

.distribution-form h3 {
    text-align: right;
    justify-content: flex-end;
    padding-right: 9px;
}

.pairing-token-picker {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.pairing-options-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.pairing-token-option {
    border: 2px solid rgb(206, 206, 206);
    background-color: rgba(206, 206, 206, 0.09);
    border-radius: 9px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 5px 3px;
    cursor: pointer;
}

.pairing-token-option:hover {
    background-color: rgba(206, 206, 206, 0.99);
}

.pairing-token-option.selected {
    background-color: rgba(206, 206, 206, 0.69);
}

.pairing-token-option .icon {
    width: 4.6rem;
    height: 4.6rem;
    margin: auto 3px;
}

.pairing-token-option .label {
    /*width: 9.2rem;*/
    padding-left: 9px;
    /*margin-left: 9px;*/
    padding-right: 18px;
}

.pairing-token-option span.hts {
    color: rgb(224, 176, 5);
}

.distribution-form-inputs .amount-selector-container {
    display: flex;
    align-content: center;
    align-items: center;
}

.distribution-form-inputs .rc-slider {
    display: flex;
    flex: 6;
    margin: 0 10px;
}

.distribution-form-inputs .amount-selector-input {
    display: flex;
    align-items: center;
    align-content: center;
}

.distribution-form-inputs input {
    flex: 1;
    width: auto;
    background: none;
    border: none;
    /*border-bottom: 2px solid white;*/
    border-radius: 10px;
    font-size: 2.5rem;
    padding-right: 1rem;
    color: #ffd052;
    height: 40px;
    text-align: end;
    width: 200px;
    font-size: 2.5rem;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
}

.distribution-form-inputs .icon.selected-token {
    width: 35px;
    height: 35px;
    margin-right: -35px;
}

/*.distribution-form-inputs .rc-slider-handle:active, */
.distribution-form-inputs .rc-slider-rail,
.distribution-form-inputs .rc-slider-track {
    /*border: 3px solid black*/
    height: 10px;
}

.distribution-form-inputs .rc-slider-handle {
    top: 5px;
    width: 21px;
    height: 21px;
}

.distribution-form-inputs .rc-slider-handle.rc-slider-handle-dragging {
    /*border-color: #57c5f7;*/
    border: 2px solid #ffd152;
    box-shadow: 0 0 0 5px #ffffff;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

#form-calculation {
    color: #cecece;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#form-calculation .icon {
    width: 32px;
    height: 32px;
}

#math-calculation {
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
}

.distribution-form span.spoiler {
    text-align: right;
    display: flex;
    align-content: end;
    justify-content: flex-end;
    font-style: italic;
    padding-top: 25px;
}

.distribution-form .actions {
    margin-top: 5%;
}

#buy-button {
    display: flex;
    width: 340px;
    font-size: 2rem;
}

#buy-button div {
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.davincigraph-lock {
    margin-bottom: 50px;
}
