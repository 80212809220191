#currency-header {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    color: #a8a8a8;
    font-weight: 700;
    font-family: "MusticaPro", sans-serif, Serif;
    margin-left: auto;
    margin-right: 2vw;
    font-size: 2.0rem;
}

#currency-header #yv-currency-label-header {
    margin: 0 1vw;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

#currency-header #yv-currency-label-header .yv-currency-icon {
    width: 3.2rem;
    height: 3.2rem;
    margin: auto 10px;
}

@media only screen and (max-width: 1024px){
    #currency-header #yv-currency-label-header .yv-currency-icon {
        width: 2rem;
        height: 2rem;
        margin: auto 10px;
    }
}

/* .yv-currency-icon {
    -webkit-animation: flipHeads 3s ease-out forwards;
    -moz-animation: flipHeads 3s ease-out forwards;
    -o-animation: flipHeads 3s ease-out forwards;
    animation: flipHeads 3s ease-out forwards;
}
#coin.tails {
    -webkit-animation: flipTails 3s ease-out forwards;
    -moz-animation: flipTails 3s ease-out forwards;
    -o-animation: flipTails 3s ease-out forwards;
    animation: flipTails 3s ease-out forwards;
} */

@-webkit-keyframes flipHeads {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
}
@-webkit-keyframes flipTails {
    from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
    to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
}

#lootbox-label-header, #lootbox-label-header #lootbox-count-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media screen and (max-aspect-ratio: 384 / 265) {
    @media screen and (max-width:1900px){
        #lootbox-label-header, #lootbox-label-header #lootbox-count-header {
            font-size: 3rem;
        }
        @media screen and (max-width:1024px){
            #lootbox-label-header, #lootbox-label-header #lootbox-count-header {
                font-size: 1.5rem;
            }
    }
}
}
#lootbox-label-header #lootbox-count-header {
    margin-left: 1vw;
}

#lootbox-label-header #lootbox-count-header div {
    border-right: 3px dotted #070707;
    margin-right: 5px;
    padding-right: 4px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
}


#lootbox-label-header #lootbox-count-header .lootbox-click {
    cursor: pointer;
}

#lootbox-label-header #lootbox-count-header .lootbox-click:hover {
    text-shadow: #a8a8a8 0 0 15px;
}

#lootbox-label-header #lootbox-count-header div.red,
.lootbox-header-open-tooltip p span.red {
    color: rgb(227, 48, 84);
}

#lootbox-label-header #lootbox-count-header div.green,
.lootbox-header-open-tooltip p span.green {
    color: rgb(218, 235, 109);
}


#lootbox-label-header #lootbox-count-header div.blue,
.lootbox-header-open-tooltip p span.blue {
    color: #22c2d0;
}

#lootbox-label-header #lootbox-count-header div img {
    height: 4rem;
    margin-right: 3px;
}

#lootbox-count-header .lootbox-header-open-tooltip {

}

.lootbox-header-open-tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}


.lootbox-header-open-tooltip p span {
    display: inline;
}



.lootbox-header-open-tooltip .btn {
    width: 90%;
}
@media screen and (max-aspect-ratio: 384 / 265) {
    @media screen and (max-width:1900px){


    }
}
@media screen and (max-width:1024px){
    #lootbox-label-header #lootbox-count-header div img {
        height: 2rem;

    }
    #lootbox-label-header #lootbox-count-header div {
        font-size: 1rem;
    }
}