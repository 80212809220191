
.popup-content {
    display: flex;
    margin: auto;
    background: rgba(16, 10, 31, 0.86);
    border-radius: 20px;
    width: 50%;
    padding: 5px;
    /*border: 1px solid #eed4c1;*/
    color: #eaeaea;
    font-family: MusticaPro;
}

[role=tooltip].popup-content {
    max-width: 24%;
    width: auto;
    /*min-width: 200px;*/
    background: rgba(16, 10, 31, 0.96);
    box-shadow: 0 0 3px rgba(0, 0, 0, .16);
    border-radius: 5px;
    padding: 7px;
}

.popup-overlay {
    background: rgba(27, 22, 46, 0.32);
}

[data-popup=tooltip].popup-overlay {
    background: transparent
}

.popup-arrow {
    /*-webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));*/
    /*filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));*/
    color: rgba(16, 10, 31, 0.86);
    /*stroke-width: 2px;*/
    /*stroke: #d7d7d7;*/
    /*stroke-dasharray: 30px;*/
    /*stroke-dashoffset: -54px;*/
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

[role=tooltip].popup-content .popup-arrow {
    color: rgba(16, 10, 31, 0.96);
}

.modal {
    font-size: 12px;
    display: flex;
    flex: 1;
}

.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    margin-left: -5px;
}

.modal > .content {
    width: 100%;
    padding: 10px 5px;
}

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.action {
    cursor: pointer;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    color: #100a1f;
    border-radius: 18px;
    /*border: 1px solid #cfcece;*/
}

.modal button .yv-currency-icon {
    margin: auto 4px;
}

.modal h3 { /*assuming its a head title*/
    color: #ebd394;
    font-size: 2.2rem;
    /*margin-top: 0;*/
}

.modal .fading-line {
    height: 2px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 10%, #d2baac 50%, rgba(0, 0, 0, 0) 90%);
    width: 100%;
}

.modal span.highlighted {
    color: #ebd394;
}

.modal span.highlighted-bg {
    background-color: rgba(255, 228, 196, 0.2);
    /*display: flex;*/
    align-items: center;
    padding: 1px 3px;
    border-radius: 3px;
}

.modal span.highlighted-bg .yv-currency-icon {
    margin: auto 4px;
}

.modal .process-icon {
    color: #a8a8a8;
    margin: auto 5px;
    height: 1.1em;
    width: 1.1em;
}

.modal .process-icon.checkmark {
    color: #28e711;
}

.modal .process-icon.x-mark {
    color: #E33054;
}

.modal .process-icon.warning {
    color: #e3bf30;
}

.modal .process-icon.loader {
    color: #00bbff;
}

/* UNPACK_BOX MODAL */

.popup-content.unpack_box-content .content-container .text p span {
    display: inline;
}

.popup-content.unpack_box-content {
    width: auto;
}

.popup-content.unpack_box-content .modal .content {
    text-align: center;
}

.modal.unpack_box {
    padding: 25px;
    min-width: 30%;
}

.modal.unpack_box .content-container,
.modal.unpack_rookie .content-container {
    width: 100%;
    padding-top: 20px;
    font-size: 1.6rem;
}


.modal.unpack_box .content-container .card-container .item-card {
    max-width: 100%;
    width: 250px;
}

.modal.unpack_box .content-container .card-container .placeholder {
    width: 246px;
    margin-right: 36px;
}

.modal.unpack_box .content-container .card-container,
.modal.unpack_rookie .content-container .card-container {
    display: flex;
    width: 300px;
    margin-right: 4%;
    text-align: center;
    align-items: center;
    align-content: center;

}

.modal.unpack_rookie .content-container .card-container {
    display: flex;
    flex-wrap: wrap;
    width: 530px;
    padding-left: 9px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
}

.modal.unpack_rookie .content-container .card-container .item-card {
    width: 246px;
    height: 312px;
    /*margin-right : 36px;*/
    /*margin-top: 20px;*/
    /*padding-bottom: 10px;*/
    /*background: none;*/
    /*cursor: default;*/
    margin: 3px 0 12px;
}

.modal.unpack_rookie .item-card-semi-img span.id,
.modal.unpack_rookie .item-card-semi-img span.damage-number {
    /*font-size: 12pt;*/
}

.modal.unpack_rookie .content-container .card-container div img {
    width: 100%;
}

.modal.unpack_box .content-container .unpack-log a {
    color: #a8a8a8;
}

.modal.unpack_box .content-container .unpack-log {
    width: auto;
    flex: 1;
}

.modal.unpack_box .content-container .unpack-log .actions {
    margin: auto auto 15px;
    width: 100%;
}

.modal.unpack_box .content-container .unpack-log .actions button.btn.primary {
    width: 61%;
    margin: auto;
}


.modal.unpack_box .content-container .unpack-log .actions button.btn.secondary {
    width: 30%;
    margin: auto 10% auto auto;
}

.modal.unpack_box .content-container {

}

.modal.unpack_box {

}

.modal-battle-info-content .content-container .card-container {
    flex: 1;
}

.modal-battle-info-content .content-container .middle-text {
    flex: 2;
}

.modal-battle-info.modal-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}

/* ENTER PASSIVE POOL MODAL */

.modal.entry-passive-pool {
    font-size: 15pt;
}

.modal .content-after-x {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex: 1;
}

.modal div.selected-pool {
    height: 10%;
    align-content: center;
    align-items: center;
    font-size: 22pt;
    padding: 1% 0;
    /*flex: 1;*/
}

.modal span.selected-serial, .modal span.selected-pool {
    margin-left: 9px;
}

.modal .nft-details, .modal .entry-configuration {
    width: 45%;
    margin: 0 3% 2% 2%;
}

.modal .nft-details img.selected-card {
    width: 100%;
}

.modal .entry-configuration .battle-input-container {
    width: 45%;
    margin-right: 3%;
}

.modal .entry-configuration .total-fees {
    justify-content: space-between;
}

.modal .entry-configuration .total-reward {
    margin-top: 7px;
    justify-content: space-between;
}

.modal .entry-configuration .total-fees span,
.modal .entry-configuration .total-reward span {
    display: flex;
    margin-left: 1%;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 2%;
    border-radius: 7%;
}

.entry-passive-pool button#pool-entry {
    width: 55%;
    margin: 1% auto;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 12pt;
}


.entry-passive-pool button#pool-entry span.finance {
    align-content: center;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: 4%;
}

.entry-passive-pool .battle-container {
    padding-top: 20%;
    width: 100%;
    height: 30%;
}

/*** MINT CARD NFT ***/

.mint-set-content {
    width: 1089px;
}

#mint-set-from-inventory-modal {
    min-height: 680px;
    /*width: 650px;*/
    /*flex-direction: ;*/
}

#mint-set-from-inventory-modal .content-container {
    margin-top: 3%;
    flex: 1;
}

#mint-set-from-inventory-modal .set-row {
    margin-top: 3%;
    flex: 1;
}

#mint-set-from-inventory-modal .mint-popup-left {
    width: 48%;
    margin: 0;
}

#mint-set-from-inventory-modal #mint-popup-left .item-card {
    margin: 0 0 5% 0;
}

#mint-set-from-inventory-modal #mint-popup-right .item-card {
    width: 116px;
    cursor: default;
}

#mint-set-from-inventory-modal #mint-popup-right .item-card span {
    font-size: 12pt;
}

#mint-set-from-inventory-modal #mint-popup-right .actions .btn.secondary {
    flex: 1 1;
}

div.placeholder {
    background-color: transparent;
}

.placeholder,
img.set {
    position: relative;
    left: 0;
    max-height: none;
    max-width: none;
    width: 100%;
    opacity: 1;
}

.mint-popup-content {
    width: 37.12142152146238%;
    width: calc(84.4vh - 65px);
    height: auto;
}

.content-container {
    width: 100%;
    padding: 0;
}

.content-container .flex-row {
    width: 92.5%;
    /*padding-top: 2%;*/
    padding-right: 4%;
    padding-left: 3.5%;
    /*padding-bottom: 3%;*/
}

.flex-row.text,
.flex-row .text {
    font-size: 1.6rem;
}

#mint-popup-left,
.mint-popup-left {
    width: 31.19843342036554%;
    margin: 0 0 12px 11px;
}

.item-card-container {
    height: auto;
    width: 100%;
}

#mint-popup-left .item-card {
    width: 100%;
    margin: 0px 0px 12px 11px;
    float: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.mint-popup-content .flex-container-columns #mint-popup-right {
    flex: 1;
    justify-content: center;
}

.mint-popup-content #mint-popup-right .yv-currency-icon {
    width: 32px;
    height: 32px;
    margin: auto 5px;
}

.mint-popup-content #mint-popup-right .actions .btn {
    flex: 3;
}

.mint-popup-content #mint-popup-right .actions .btn.secondary {
    flex: 2;
}


/*#mint-from-inventory-modal .content-container {*/
/*    padding: 10px 25px;*/
/*}*/

.modal .flex-row-full span.item-card-id {
    display: inline-block;
    color: #ebd394;
    font-family: "MusticaPro", sans-serif, Serif;
}

.log-entry {
    display: flex;
    margin: 0.4em;
    align-items: center;
}

.action-log {
    font-size: 12pt;
    /*margin: 10px;*/
    display: flex;
    flex: 1;
    /*align-content: center;*/
    /*align-items: center;*/
    /*justify-content: center;*/
}

.actions {
    margin-top: auto;
    margin-bottom: 5%;
    justify-content: center;
}

.actions button {
    height: 100%;
    margin: auto 7px;
}

@media screen and (max-width: 1650px) {
    .popup-content.unpack_rookie-content,
    .popup-content.entry-passive-pool-popup-content {
        width: 75%;
    }

    .entry-passive-pool button#pool-entry {
        width: 65%
    }
}

@media screen and (max-width: 1340px) {
    .entry-passive-pool button#pool-entry {
        width: 75%
    }
}

@media screen and (max-width: 1140px) {
    .popup-content.unpack_rookie-content,
    .popup-content.entry-passive-pool-popup-content {
        width: 90%;
    }
}

@media screen and (max-width: 945px) {
    .entry-passive-pool button#pool-entry {
        width: 90%
    }
}

@media screen and (max-width: 845px) {
    .modal.unpack_rookie .content-container .card-container {
        width: 65%;
    }

    .modal.unpack_rookie .content-container .card-container .item-card {
        width: 45%;
        height: auto;
        aspect-ratio: 246/312;
    }

    .popup-content.entry-passive-pool-popup-content div.selected-pool {
        font-size: 2.0rem;
    }
}

@media screen and (max-width: 768px) {
    .entry-passive-pool button#pool-entry {
        width: 95%
    }
}

@media screen and (max-width: 620px) {
    .unpack_box .paired-content {
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .modal.unpack_box .content-container .card-container .placeholder {
        margin-left: auto;
        margin-right: auto;
    }

    .modal.unpack_box .content-container .unpack-log .actions button.btn.secondary {
        width: 100%;
        margin: 5px 0;
    }

    .unpack_box .log-entry {
        flex-wrap: wrap;
    }

    .modal.unpack_box .content-container, .modal.unpack_rookie .content-container {
        max-height: 60vh;
        overflow-y: auto;
    }
    .modal.unpack_rookie .content-container {
        overflow-x: clip;
    }

    .modal .log-entry,
    .modal .log-entry a {
        /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
        -ms-word-break: break-all;
        word-break: break-all;
        /* Non standard for webkit */
        word-break: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    .content-container .flex-row {
        overflow-y: auto;
    }

    #mint-from-inventory-modal .content-after-x .content-container {
        max-height: 80vh;
        overflow-y: auto;
    }

    #mint-from-inventory-modal .content-after-x .content-container .text.flex-row.flex-row-full {
        height: 100px;
        padding: 15px 0 15px 15px;
    }

    #mint-from-inventory-modal .content-after-x .content-container .text.flex-row.flex-row-full p {
        margin: 0;
    }

    #mint-from-inventory-modal .content-after-x .content-container .log-entry span.bx-serial {
        width: 45%;
        align-content: center;
        align-items: center;
        justify-content: center;
        word-break: keep-all;
        flex-wrap: nowrap;
    }

    #mint-from-inventory-modal .content-after-x .content-container .log-entry span.bx-serial svg {
        font-size: 1.6rem;
    }

    #mint-from-inventory-modal .content-after-x .actions {
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    #mint-from-inventory-modal .content-after-x .actions button {
        width: 90%;
        margin: 5px 0;
    }
}

@media screen and (max-width: 520px) {
    .popup-content.entry-passive-pool-popup-content .modal-pair-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        width: 95%;
    }

    .popup-content.entry-passive-pool-popup-content .nft-details,
    .popup-content.entry-passive-pool-popup-content .entry-configuration {
        width: 95%;
        margin: 15px auto;
    }

    .popup-content.entry-passive-pool-popup-content .nft-details p {
        display: flex;
        margin: 0 0 15px;
    }

    .popup-content.entry-passive-pool-popup-content div.selected-pool {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 500px) {
    /*.modal.unpack_box .content-container, */
    .modal.unpack_rookie .content-container {
        flex-direction: column-reverse;
    }

    .modal.unpack_rookie .content-container .card-container .flex-container-columns {
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;
    }

    .modal.unpack_rookie .content-container .card-container {
        flex-direction: row;
        width: 100%;
        aspect-ratio: 246/650;
        /* padding-top: 400px; */
        justify-content: center;
        align-content: flex-start;
        align-items: center;
        display: flex;
    }

    .modal.unpack_rookie .content-container .card-container .flex-container-columns.full-width {
        flex-direction: row;
    }

    .modal.unpack_rookie .content-container .card-container .item-card {
        width: 45.72%;
    }

    .mint-popup-content .flex-container-columns #mint-popup-right {
        width: 65%;
    }
}

@media screen and (max-width: 450px) {
    .popup-content.entry-passive-pool-popup-content .log-entry {
        font-size: 1.5rem;
    }
}
