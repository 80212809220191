/* PALM BRANCH */

#banatural-selection {

    height: 100%;
    max-height: 100%;

    margin-right: calc(-100% / 1558 * 487);

    margin-left: 0;

    width: 42.42618741976893%;
    width: calc(100% / 1558 * 661); /* 661px on 1558px width banana block */

    position: relative;
    /*flex-direction: initial;*/
    z-index: 4;
}

#banatural-selection #palm-overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/palm_outfit_new.png");
    background-size: 100% 100%;
    z-index: 5;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}


#inventory-page #inventory-portal,
#arena-picker-page #inventory-portal,
#arena-passive-list #inventory-portal {
    position: relative;
    margin-left: 0;
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/portal_base_new_gpl.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    z-index: 3;
}


#inventory-portal {
    width: 84.08215661103979%;
    width: calc(100% / 1558 * 1310);
    /*width: calc(100vh / 1440 * 1310);*/
    height: 100%;
    aspect-ratio: 1310/1311;
    flex-direction: row;
    /*padding-right: 3.854545454545455vh; !* (106px / 13.75) base divided by current height adjustment *!*/
}

#carousel-wrapper {
    /*background-color: #00bbff;*/
}

#carousel-faction-damage .set-damage {
    margin: 3px auto;
}

#carousel-info-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    flex: 1;
}

#carousel-info-bar span {
    text-align: center;
    font-size: 2.0rem;
}

#portal-manipulator {
    width: 100%;
}
#portal-manipulator div {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
}


/* DESKTOP */
@media (min-aspect-ratio: 340/185) {

    #inventory-page #inventory-portal,
    #arena-picker-page #inventory-portal,
    #arena-passive-list #inventory-portal {
        width: auto;
        height: 100%;
        aspect-ratio: 1310/1311;
        /* TODO : for some reason setting width to 100vh / 1440 * 1310 does not work so quick fixing for now */
    }


    #banatural-selection {
        margin-right: calc(-100% / 1558 * 413);
    }
}


#inventory-portal #damage-numbers {
    height: 100%;
    width: 30.06644518272425%;
    margin-right: -30.06644518272425%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: auto;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

#inventory-portal #damage-numbers .damage-number {
    font-size: 1.2vw;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

#inventory-portal #damage-numbers .damage-number.pre-red {
    height: 15.63691838291381%;

}

#inventory-portal #damage-numbers .damage-number.red {
    width: 79.83425414364641%;
    height: 23.173074%;
    /*margin-top: 14%;*/
    margin-left: auto;
    /*margin-right: 4px;*/
    /*background-color: rgba(220, 20, 60, 0.44);*/
    color: #4a1219;
}

#inventory-portal #damage-numbers .damage-number span {
    align-content: center;
    justify-content: center;
    align-items: center;
    display: flex;
}

#inventory-portal #damage-numbers .damage-number.red span {
    position: relative;
    width: 37%;
    height: 40%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 1.5%;
    margin-left: 0;
    /*background-color: rgba(220, 20, 60, 0.44);*/
}

#inventory-portal #damage-numbers .damage-number.green {
    /*margin-top: 36.14545454545455%;*/
    margin-left: 0;
    margin-right: auto;
    height: 27.68878718535469%;
    width: 31.911602%;
    /*background-color: rgba(0, 255, 0, 0.38);*/
    color: #2e4308;
}

#inventory-portal #damage-numbers .damage-number.green span {
    height: 32.240275%;
    width: 98%;
    margin-top: -14.1%;
    /*background-color: rgba(0, 255, 0, 0.38);*/
}

#inventory-portal #damage-numbers .damage-number.blue {
    width: 45.85635359116022%;
    height: 16.21818181818182%;
    margin-left: 11.04972375690608%;
    /*margin-right: 18%;*/
    /*margin-bottom: 42%;*/
    /*margin-left: auto;*/
    /*background-color: rgba(0, 255, 255, 0.38);*/
    color: #0b3440;
}

#inventory-portal #damage-numbers .damage-number.blue span {
    position: relative;
    width: 66.5%;
    height: 57%;
    margin-top: 6%;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: 0;
}

#inventory-portal #stone-wrapper {
    height: 100%;
    /*width: 83.10344827586207%;*/
    width: 100%;
    display: flex;
    margin-left: auto;
    position: relative;
    flex-direction: column;
}


#inventory-portal #banan-full-size .assembly {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 100% 100%;
}

#inventory-portal #banan-full-size .assembly .aspect-ratio {
    position: relative;
    left: 0;
    max-height: none;
    max-width: none;
    width: 100%;
    opacity: 0;
}

#inventory-portal #banan-full-size .assembly .aspect-ratio#selection-overlay {
    position: absolute;
    /*opacity: 1;*/
    z-index: 10;
}

#inventory-portal #banan-full-size .assembly .assembly-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /*background-color: #f0f8ff26;*/
    /*TODO: assembly-container is 4px taller than aspect ratio img, can't figure out why*/
}

#banatural-selection #palm-overlap {
    position: absolute;
    height: 100%;
    display: block;
}

#banatural-selection .item-circle {
    text-align: center;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-family: /*"MusticaPro", "Lato", */sans-serif, Serif;
    font-weight: bold;
    font-size: 2.0vw;
}

#banatural-selection .item-circle.item-faction-red,
#inventory button.secondary.red {
    color: rgb(227, 48, 84);
}

#banatural-selection .item-circle.item-faction-green,
#inventory button.secondary.green {
    color: rgb(218, 235, 109);
}

#banatural-selection .item-circle.item-faction-blue,
#inventory button.secondary.blue {
    color: #22c2d0;
}

#banatural-selection .item-circle span.text-over-text {
    /*position: absolute;*/
    opacity: 0;
}

#banatural-selection .item-circle.unfinished {
    cursor: pointer;
}

#banatural-selection .item-circle.unfinished span.text-over-text {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
    opacity: 0;
    font-size: 1.9vw;
}

#banatural-selection .item-circle.unfinished:hover span.text-over-text
{
    /*display: block;*/

    -webkit-animation: fadeInFromNone 0.5s ease-out;
    -moz-animation: fadeInFromNone 0.5s ease-out;
    -o-animation: fadeInFromNone 0.5s ease-out;
    animation: fadeInFromNone 0.5s ease-out;
    opacity: 1;
}

#banan-full-size {
    /*display: flex;*/
    /*width: 85.47717842323651%;*/
    /*width: calc(100% / 1310 * 1030);*/
    /*height: 87.71929824561404%;*/
    /*height: calc(100% / 1311 * 1150);*/
    /*margin-top: 0.9153318077803203%;*/
    /*margin-left: 2.074688796680498%;*/
    /*margin-right: auto;*/
    animation: 6s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s infinite normal none running bananMoveNoPause;
}

@keyframes bananMove {
    12.5% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(1.45%);
    }

    87.5% {
        transform: translateY(0%);
    }
}

@keyframes bananMoveNoPause {
    /*12.5% {*/
    /*    transform: translateY(0%);*/
    /*}*/

    50% {
        transform: translateY(1.45%);
    }

    /*87.5% {*/
    /*    transform: translateY(0%);*/
    /*}*/
}

@keyframes portalFlicker {
    12.5% {
        opacity: 100%;
    }

    50% {
        opacity: 60%;
    }

    87.5% {
        opacity: 100%;
    }
}

/* FULL SIZE */

#banan-full-size, #banan-full-size .assembly {
    width: 380px;
}

#banan-full-size .assembly {
    height: 550px;
    position: relative;
    /*background-color: rgba(147, 180, 217, 0.61);*/
}

#banan-full-size .assembly img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    transition: all 0.6s ease; /* DOESNT WORK AND ITS A GOOD THING */
}

#banan-full-size .assembly img.SKIN.full,
#banan-full-size .assembly img.WEAPON.full,
#banan-full-size .assembly img.BODY.full,
#banan-full-size .assembly img.HEAD.full {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#banan-full-size .assembly img.SKIN.full {
    z-index: 1;
}

#banan-full-size .assembly img.WEAPON.full {
    z-index: 4;
}

#banan-full-size .assembly img.BODY.full {
    z-index: 2;
}

#banan-full-size .assembly img.HEAD.full {
    z-index: 5;
}

/*portal*/

#banan-full-size #portal-effects-under {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: bottom;
    z-index: 1;
}

#banan-full-size #portal-effects-under.blue {
    /*background-image: url("../../../public/assets/portal/particles/blue_bottom.png");*/
    /*background-image: url("https://asset-host.b-cdn.net/assets/inventory/blue_bottom.png");*/
}

#banan-full-size #portal-effects-under.green {
    /*background-image: url("../../../public/assets/portal/particles/green_bottom.png");*/
    /*background-image: url("https://asset-host.b-cdn.net/assets/inventory/green_bottom.png");*/
}

#banan-full-size #portal-effects-over {
    width: 360px;
    height: 340px;
    top: 210px;
    left: 10px;
    z-index: 7;
}


#banatural-selection #palm-render {
    width: auto;
    height: 100%;
    /*position: relative;*/
}

#banatural-selection /*#palm-render*/ img.aspect-ratio {
    height: 100%;
    width: auto;
    position: relative;
    /*left: auto;*/
    max-height: 100%;
    max-width: 100%;
    opacity: 0.1;
}

#banatural-selection .item-circle-div {
    position: absolute;
    /*width: 23.48484848484848%; !*155px*!*/
    /*height: 11.27272727272727%; !*155px*!*/
}

#right-side #palm-extension {
    display: none;
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/palm_equipped_part_A.png");
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 6;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}


/*======= PORTAL =====*/

#portal-button-wrapper {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/button_MINT_ARENA_full.png");
    display: flex;
    position: absolute;
    /*top: 100px;*/
    right: 8vh;
    width: 30vh;
    height: 30vh;
    background-position: 200% 100%;
    background-size: 200% 100%;
    z-index: 22;
    margin-top: 55px;
    /* background-position: 100% 200%; */
}


#portal-button-wrapper.complete {
    /*background-image: url("https://asset-host.b-cdn.net/assets/inventory/button_MINT_ARENA_active_2.png");*/
    background-position-x: 100%;
}

#portal-button-wrapper button {
    display: flex;
    flex: 1 1;
    margin-bottom: 13.1%;
    margin-top: 32.9%;
    margin-left: 26%;
    margin-right: 14%;
    border-radius: 50%;
    /*background-color: rgba(255, 164, 0, 0.22);*/
    /*font-weight: bold;*/
    font-size: 6vh;
    color: #bbd0ff;
    border: none;
    align-items: center;
    justify-content: center;
    font-family: "PassionOne", sans-serif;
    background: none;
    box-shadow: none;
    text-shadow: none;
    overflow: clip;
    z-index: 9;
}

#portal-button-wrapper button:disabled {
    background-color: rgba(255, 255, 255, 0);
}

#portal-button-wrapper button.complete {
    /*color: #cfcece;*/
    color: rgba(255, 228, 86, 1);
    cursor: pointer;
}

#portal-button-wrapper button.incomplete {
    /*color: #cecece;*/
    font-size: 38pt;
    padding: 0 7%;
    cursor: default;
}

#portal-button button:hover {

    transition: all 0s 0.15s;
    transform: rotateY(180deg);

}

#inventory #carousel-bar-actions {
    display: flex;
    width: 100%;
    height: 75px;
    margin-top: -75px;
    margin-bottom: 15px;
    align-content: center;
    align-items: center;
    justify-content: center;

}


#inventory #carousel-bar-actions .btn {
    height: 45px;
    font-size: 2.6rem;
    width: auto;
    border-radius: 7px;
    z-index: 12;
}

#manipulator-help-tooltip-content {
    max-width: 800px;
    padding: 25px 50px;
}

#manipulator-help-tooltip-content img {
    width: 100%;
    height: auto;
}


@media (max-aspect-ratio: 17 / 23) {
    #portal-button-wrapper {
        margin: 0;
        width: 25vw;
        height: 25vw;
        right: 5px;
    }

    #portal-button-wrapper button {
        font-size: 7vw;
    }

    #inventory-portal #damage-numbers .damage-number {
        font-size: 3vw;
    }

}

/* TABLET VIEW */
@media (max-aspect-ratio: 340/185) and (min-aspect-ratio: 17 / 23) {


    #right-side #palm-extension {
        display: flex;
        width: calc(50vw / 1558 * 967); /* 967px / 15.58 base  divided by current height */
        height: calc(50vw / 1558 * 792);
        margin-bottom: calc(-50vw / 1558 * 772);
        margin-right: 0;
        margin-left: auto;
        margin-top: calc(-50vw / 1558 * 20);
    }

    /* #portal-button-wrapper {
        display: none !important; /* TODO: fix sizing later in InventoryRightSide.css
    } */
}

/* MOBILE VIEW */
@media (max-aspect-ratio: 17 / 23) {
    #right-side {
        width: 92vw;
        max-width: 92vw;
        min-width: 92vw;
        height: 80vw; /*1375px / 15.58 base divided by current width ratio */
        min-height: 80vw; /*1375px / 15.58 base divided by current width ratio */
        margin-top: auto;
        margin-bottom: 0;
        margin-right: auto;
        margin-left: auto;
    }

    #inventory-portal { /* TODO: put it into appropriate file */
        /*min-width: 880px;*/
        width: 83.10344827586207%;
        height: 100%;
        flex-direction: row;
        /*padding-right: 6.803594351732991vw; !* 106px / 15.58 base divided by current height adjustment*!*/
    }

    #right-side #palm-extension {
        /*display: flex;*/
        display: none;
        width: 60.06675224646983vw; /* 967px / 15.58 base  divided by current height */
        height: 50.83440308087291vw;
        margin-bottom: -48.834403vw;
        margin-right: 0;
        margin-left: auto;
        margin-top: -2vw;
    }

    #right-side {
        margin-top: auto;
        margin-bottom: -5.134788189987163vw; /* 80px / 15.58 base divided by current width ratio */
    }
}

@media screen and (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23) {
    #right-side {
        margin-top: auto;
    }
}

@media screen and (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23) {
    #portal-button-wrapper {
        margin: 0;
        width: 13vw;
        height: 13vw;
        right: 3vw;
        top: 2vw;
    }

    #portal-button-wrapper button {
        font-size: 2.5vw;
    }

    #inventory-portal #damage-numbers .damage-number {
        font-size: 1.6vw;
    }
}