#inventory-page #right-side,
#arena-picker-page #right-side,
#arena-passive-list #right-side {
    display: flex;
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
}

#arena-picker-page #right-side,
#inventory-page #right-side {
    margin-left: auto;
    width: calc(100vh / 1440 * 1558); /* (1558px / 13.75) base divided by current height */
    height: calc(100% - (100vw/2560*76)); /* 60px is the height of the hood */
    /*height: 88.8vh;*/
    /*margin-top: auto;*/
    margin-bottom: calc(100vw / 2560 * 76); /*76px from bottom of the screen */
    /*margin-top: -68px; !*<------------------------- conflicts with hood(63px) and crawls under it*!*/
    margin-right: -7.709090909090909vh; /* (106px / 13.75) base divided by current height adjustment */
}


@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23) {

    #arena-picker-page #right-side,
    #inventory-page #right-side {
        width: 50vw;
        min-width: 50vw;
        max-width: 50vw;
        height: calc(50vw / 1558 * 1311 );
        margin-bottom: calc(100vw / 2560 * 76); /*76px from bottom of the screen */
        margin-right: calc(50vw / 1558 * -30);
    }
}

@media (max-aspect-ratio: 17 / 23) {
    #arena-picker-page #right-side,
    #inventory-page #right-side {
        width: 75vw;
        min-width: 75vw;
        max-width: 75vw;
        height: calc(75vw / 1558 * 1311 );
        min-height: calc(75vw / 1558 * 1311 );
        margin-bottom: calc(100vw / 2560 * 76); /*76px from bottom of the screen */
        margin-right: calc(50vw / 1558 * -30);
    }
}


@media (max-width: 1024px) {
    #right-side {
        margin-top: -27px;
    }
}

/* JUNK */

#arena-picker-page #inventory-portal,
#inventory-page #inventory-portal {
    position: relative;
}