#resolver-form,
#resolver-finisher-form {
    width: 100%;
    display: flex;
}

#resolver-form .form-row,
#resolver-finisher-form .form-row {
    font-size: 1.8rem;
    margin: 13px 2.5% 7px;
    width: 95%;
    display: flex;
    align-content: center;
    align-items: center;
}

#resolver-form .form-row.text-only,
#resolver-finisher-form .form-row.text-only {
    justify-content: flex-start;
}

#resolver-form .form-row label,
#resolver-finisher-form .form-row label {
    display: flex;
    width: 35%;
    text-align: right;
    justify-content: flex-end;
}

#resolver-form .form-row input,
#resolver-finisher-form .form-row input,
#resolver-form .form-row select {
    display: flex;
    border: 3px solid #00000000;
    line-height: 35px;
    border-radius: 10px;
    font-family: "MusticaPro", "Lato", sans-serif, Serif;
    font-weight: bold;
    padding: 0 7px;
    margin: auto 3% auto 2%;
    width: 55%;
    height: 41px;
}

#resolver-form .form-row select {
    width: 57%;
}

#resolver-finisher-form .form-row .button.btn.primary,
#resolver-form .form-row .button.btn.primary {
    width: 176px;
    margin: auto;
    margin-left: 37%;
    border-radius: 9px;
}

#resolver-form input:-webkit-autofill,
#resolver-form input:-webkit-autofill:focus,
#resolver-finisher-form input:-webkit-autofill,
#resolver-finisher-form input:-webkit-autofill:focus {
    /*-webkit-box-shadow:0 0 0 50px #9d1010 inset; !* Change the color to your own background color *!*/
    -webkit-text-fill-color: #232323;
}

#resolver-finisher-form .form-row .log-entry {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .history-popup-content.resolver-popup-content {
        position: relative;
        margin: auto;
        pointer-events: auto;
        max-height: 80vh;
        height: auto;
        overflow-y: auto;
        overflow-x: clip;
    }

    .history-popup-content .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -3px;
        top: -5px;
        /* margin-top: -20px; */
        font-size: 24px;
        background: #ffffff;
        color: #100a1f;
        border-radius: 18px;
        /* border: 1px solid #cfcece; */
        z-index: 2011;
    }
}

@media screen and (max-width: 330px) {
    #resolver-finisher-form .form-row .button.btn.primary,
    #resolver-form .form-row .button.btn.primary {
        width: 100%;
        margin-left: 0;
    }
}
