#store-page #left-side {
    display: flex;
    margin-left: 0;
    margin-right: auto;
    z-index: 5;
    flex-direction: column;
}

#store-page #left-side .upper-part {
    align-items: center;
    margin-top: 10%;
    margin-bottom: 5%;
}

#store-page #left-side #purchase-currency-content {
    height: 100%;
    background-color: sandybrown;
    border-radius: 30px;
    align-items: center;
    align-content: center;
    justify-content: center;
}

#store-page #left-side #purchase-currency-wrap {
    width: 30%;
    justify-content: center;
    align-items: center;
}

#store-page #left-side #purchase-currency-content #purchase-currency-balance {
    font-size: 3rem;
}

@media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1500px) {
    #store-page #left-side #purchase-currency-content #purchase-currency-balance {
        font-size: 2rem;
    }

    @media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1200px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-balance {
            font-size: 1.5rem;
        }

    }
}

#store-page #left-side #purchase-currency-content #purchase-currency-plus {
    min-width: 13px;
    min-height: 13px;
    height: 3.8rem;
    width: 3.8rem;
    color: rgba(197, 126, 115, 0.7);
    cursor: pointer;
}

@media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1500px) {
    #store-page #left-side #purchase-currency-content #purchase-currency-plus {
        height: 2.8rem;
        width: 2.8rem;
    }

    @media screen and (min-aspect-ratio: 384 / 265) and (max-width: 1200px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-plus {
            height: 1.8rem;
            width: 1.8rem;
        }
    }
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 538) {
    #store-page #left-side #purchase-currency-content #purchase-currency-plus {
        height: 3.6rem;
        width: 3.6rem;
    }
}

@media screen and (max-aspect-ratio: 425 / 538) {
    #store-page #left-side #purchase-currency-content #purchase-currency-plus {
        height: 5.8rem;
        width: 5.8rem;
    }

    @media screen and (max-width: 540px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-plus {
            height: 2.3rem;
            width: 2.3rem;
        }
    }
    @media screen and (max-width: 450px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-plus {
            height: 1.7rem;
            width: 1.7rem;
        }
    }
}

#store-page #left-side .lower-part {
    margin-right: 10vw;
    margin-left: 24.85vw;
    margin-bottom: 0;
    width: 53%;
    justify-content: space-around;
}

#store-page #left-side .lower-part .unpack-container {
    height: 100%;
    width: auto;
}

#store-page #left-side .lower-part #unpack-item-wrapper {
    height: 100%;
    width: auto;
}

#store-page #left-side .lower-part .unpack-contents {
    align-content: center;
    background-position: center;
    background-size: 100% auto;
    height: 100%;
    display: flex;
    align-items: center;
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {

    #store-page #left-side .lower-part .unpack-contents {
        justify-content: center;
    }
}

.red .lootbox-representation {
    background-image: url("https://asset-host.b-cdn.net/assets/store/red_box.png");
}

.green .lootbox-representation {
    background-image: url("https://asset-host.b-cdn.net/assets/store/green_box.png");
}

.blue .lootbox-representation {
    background-image: url("https://asset-host.b-cdn.net/assets/store/blue_box.png");
}

.rookie .lootbox-representation {
    background-image: url("https://asset-host.b-cdn.net/assets/store/rookie_box.png");
}

.lootbox-representation {
    background-repeat: no-repeat;
    background-size: 100% auto;
}

#store-page #left-side .lower-part .unpack-contents .lootbox-representation {
    height: 14.55vw;
    min-height: 100px;
    width: 12.7vw;
    min-width: 100px;
}

#left-side .lootbox-representation .lootbox-purchase-info-tooltip,
.lootbox-representation .lootbox-counter {
    display: flex;
    width: 66px;
    height: 66px;
    margin-left: 0;
    margin-top: 0;
    flex: initial;
}

.lootbox-representation .lootbox-counter {
    align-content: center;
    align-items: center;
    background-color: rgba(62, 26, 34, .6);
    border-radius: 50%;
    color: #fefefe;
    display: flex;
    font-family: MusticaPro, sans-serif, Serif;
    font-size: 2.4rem;
    height: 3rem;
    width: 3rem;
    justify-content: center;
    line-height: 2.6rem;
    margin: auto 20% 10% auto;
    padding: 2px 3px 3px 2px;
    vertical-align: center;
}

.bottom-driver-small-lootbox {
    display: none;
}

.unpack-contents .lootbox-purchase-info-tooltip .question-circle {
    height: 100%;
    width: 100%;
    cursor: pointer;
    color: #3e1a22;
    opacity: 0.6;
}

.lootbox-representation img.lootbox-image {
    width: 100%;
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents.red .lootbox-representation span {
    color: rgb(227, 48, 84);
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents.green .lootbox-representation span {
    color: rgb(218, 235, 109);
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents.blue .lootbox-representation span {
    color: #22c2d0;
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents .lootbox-representation img {
    width: 100%;
}

#store-page #left-side .lower-part .unpack-contents .control-panel {
    display: flex;
    background-size: 100% auto;
    max-height: 1000px;
    max-width: 1000px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents .control-panel .unpack_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

#store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
#store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
    font-size: 2.5rem;
    width: 15rem;
    border-radius: 15px;
    min-width: 5rem;
    min-height: 2rem;
    padding: 0;
    height: 6.5rem;
    display: flex;
    flex-direction: column;
}

#store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button span,
#store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button span {
    font-size: 2.2rem;
}

@media screen and (max-width: 1560px) {
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
        font-size: 2rem;
        /*width: 10rem;*/
        /*height: 4.5rem;*/
    }

    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button span,
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button span {
        font-size: 1.9rem;
    }

    #store-page #left-side .lower-part {
        margin-right: auto;
        margin-left: 19.85vw;
        margin-bottom: 0;
        width: 60%;
        justify-content: space-around;
    }

    @media screen and (max-width: 1200px) {
        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
            font-size: 1.6rem;
            width: 9rem;
            height: 5rem;
            border-radius: 10px;
        }

        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button span {

            font-size: 1.4rem;
        }

        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button span {

            font-size: 1.3rem;
        }
    }
    @media screen and (max-height: 910px) and (max-width: 1460px) {
        #store-page #left-side .lower-part #unpack-item-wrapper,
        #store-page #left-side .lower-part #unpack-rookie-set {
            padding-top: 9%;
        }

    }
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents .control-panel .arrow-right {
    margin-right: 0;
}

#store-page #left-side .lower-part #unpack-item-wrapper .unpack-bottom-buffer {
    height: 10%;
    margin-top: auto;
    margin-bottom: 0;
}

#store-page #left-side .lower-part img#rookie-box {
    width: 67%;
    position: absolute;
    z-index: 6;
}

#store-page #left-side .lower-part #rookie-portal {
    max-height: 100%;
    height: 100%;
    width: 95%;
    margin: auto 2.5%;
}

#store-page .modal-wrapper.unpack_rookie {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain, 65% auto;
    background-repeat: no-repeat;
    width: 75%;
    height: 100%;
}

.ownership_token {
    display: none;
}

.control-panel .arrows {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    opacity: 90%;
    height: 75%;
    min-height: 15px;
    min-width: 12px;
}

#store-page .control-panel .arrows:hover {
    opacity: 95%;
}

#store-page .control-panel .arrows.arrow-right {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
    text-align: left;
    padding-left: 0px;
    color: #b4890d;
    width: 15%;
}

#store-page .control-panel .arrows.arrow-left {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    text-align: right;
    padding-right: 0px;
    color: #b4890d;
    width: 15%;
}

#store-page .control-panel .arrows.arrow-right:hover,
#store-page .control-panel .arrows.arrow-left:hover {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_hower.png");
}

#store-page .control-panel .arrows.arrow-left.disabled,
#store-page .control-panel .arrows.arrow-right.disabled {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_disable.png");
}


@media screen and (max-width: 1850px) {
    .lootbox-representation .lootbox-counter {
        font-size: 2.1rem;
        height: 2.4rem;
        width: 2.4rem;
        line-height: 2.2rem;
        margin: auto 20% 10% auto;
        padding: 1px 2px 2px 1px;
    }
}

@media screen and (max-width: 1340px) {

    .lootbox-representation .lootbox-counter {
        font-size: 1.7rem;
        height: 2.1rem;
        width: 2.1rem;
        line-height: 1.9rem;
        margin: auto 20% 10% auto;
        padding: 1px 0 0 0;
    }
}

@media screen and (max-aspect-ratio: 425 / 538) {
    #store-page #left-side #purchase-currency-wrap {

        width: 70%;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 630px) and (min-width: 541px) {
        #store-page #left-side #purchase-currency-wrap {

            width: 90%;

        }
    }
    @media screen and (max-width: 425px) {
        #store-page #left-side #purchase-currency-wrap {

            width: 100%;

        }
    }
    #store-page #left-side {
        display: flex;
        width: 100%;
        height: auto;
        align-items: center;
    }


    #store-page #left-side .lower-part .unpack-contents .lootbox-representation {
        height: auto;
        width: 50%;
        min-width: 100px;
        aspect-ratio: 1;
        padding-bottom: 2vh;
    }


    #store-page #coco {
        display: none;
    }

    #store-page .lower-part {
        height: 80%;
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    #store-page #right-side .lootbox-container .control-panel .lootbox-purchase-counter {
        display: none;
    }

    .small-lootbox-container .lootbox-pricing {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    /*h3, span {*/
    /*    display: inherit;*/
    /*    font-size: 100%;*/
    /*}*/


    #store-page #left-side #purchase-currency-content #purchase-currency-balance {

        font-size: 4.1rem;
    }

    @media screen and (max-width: 1074px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-balance {
            font-size: 3rem;
        }

        @media screen and (max-width: 540px) {
            #store-page #left-side #purchase-currency-content #purchase-currency-balance {
                font-size: 1.7rem;
            }
        }
        @media screen and (max-width: 450px) {
            #store-page #left-side #purchase-currency-content #purchase-currency-balance {
                font-size: 1rem;
            }
        }
    }

    #store-page #left-side .upper-part {
        align-items: center;
        display: flex;
        margin-left: 0;
        margin-bottom: 0;
        width: 30%;
        top: 10px;
        margin-top: 0;
    }

    #store-page #left-side #purchase-currency-content {
        height: 120%;
        min-height: 10px;
        min-width: 75px;
        background-color: sandybrown;
        border-radius: 50px;
        align-items: center;
        align-content: center;
        justify-content: center;
    }



    #store-page #left-side .lower-part {
        display: flex;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
        justify-content: space-evenly;
        width: 100%;
        height: 100%;
        flex-direction: row;
    }

    .small-lootbox-container .lootbox-purchase-info-tooltip {
        margin: 0;
        padding: 0;
    }

    #store-page .upper-part {
        position: relative;
    }

    #store-page #left-side .lower-part .unpack-contents {
        align-content: center;
        background-position: center;
        background-size: 100% auto;
        display: flex;
        justify-content: space-around;

    }


    .ownership_token {
        display: flex;
    }

    .counters {
        width: 100%;
        display: flex;
        justify-content: space-around;
        height: 20%;
    }

    @media screen and (max-width: 425px) {
        .counters {
            height: 50px;
        }
    }
    .bottom-driver-small-lootbox {
        display: flex;
    }

    .small-lootbox-container .lootbox-pricing-calc {
        margin-bottom: 0;
        background-color: #f0ffff33;
        align-content: center;
        align-items: center;
        border-radius: 50vh;
        padding: 0 2%;
    }

    .lootbox-pricing.increase-amount-container,
    .lootbox-pricing.decrease-amount-container {
        padding-bottom: 0;
    }

    .small-lootbox-container .lootbox-pricing {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }


    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
        font-size: 2.5rem;
        width: 17rem;
        height: 7rem;
        padding: 0;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
    }

    @media screen and (max-width: 1560px) {
        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
        #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
            font-size: 1.5rem;
            width: 12rem;
            height: 5rem;
            padding: 0;
            display: flex;
            flex-direction: column;
            border-radius: 1rem;
        }
    }


    #store-page #left-side .lower-part .unpack-contents .lootbox-representation {
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 22vw;
        max-height: 1000px;
        min-height: 100px;
        width: 21vw;
        max-width: 1000px;
        min-width: 100px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button span, #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button span {
        font-size: 1.3rem;
    }

    #store-page #left-side .lower-part #unpack-item-wrapper .unpack-contents .control-panel .unpack_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #store-page #left-side .lower-part #unpack-item-wrapper, #store-page #left-side .lower-part #unpack-rookie-set {
        width: 35vw;
        margin: 0 auto;
    }

    #store-page #left-side .lower-part .unpack-contents .lootbox-representation {
        background-repeat: no-repeat;
        background-size: 100% auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        aspect-ratio: 1;
        height: auto;
    }

    #store-page #left-side .lower-part .unpack-contents .control-panel {
        display: flex;
        background-size: 100% auto;
        max-height: 400px;
        min-width: 100px;
        max-width: 400px;
        align-items: center;
        justify-content: center;
        height: 20%;
    }

    .pcs {
        font-size: 1.2rem;
    }

    .total {
        font-size: 1.2rem;
    }
}


@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) {

    #store-page #left-side #purchase-currency-content #purchase-currency-balance {
        font-size: 3.4rem;
    }

    @media screen and (max-width: 1112px) {
        #store-page #left-side #purchase-currency-content #purchase-currency-balance {
            font-size: 2.4rem;
        }

    }

    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button span,
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button span {
        font-size: 1.7rem;
    }

    #store-page #left-side .lower-part .unpack-contents .lootbox-representation {
        height: auto;
        width: 50%;
        min-width: 100px;
        aspect-ratio: 1;
        padding-bottom: 2vh;
    }
    
    @media screen and (max-width: 940px) {
        #store-page #left-side .lower-part .unpack-contents .lootbox-representation {
            width: 75%;
        }
    }

    @media screen and (max-height: 570px){
        #store-page #left-side .counters {
            max-height: 70px;
        }

    }

    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_box button,
    #store-page #left-side .lower-part .unpack-contents .control-panel .unpack_rookie button {
        font-size: 2rem;
        width: 15rem;
        min-width: 50px;
        min-height: 20px;
        padding: 0;
        height: 6rem;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
    }

    #store-page #left-side .lower-part .unpack-contents .control-panel {
        display: flex;
        background-size: 100% auto;
        max-height: 1000px;
        max-width: 1000px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    #store-page .lower-part {
        display: flex;
        flex-direction: column;
        height: 66.98%;
        width: 100%;
        justify-content: space-around;
    }


    #store-page .control-panel .arrows.arrow-right {
        background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
        text-align: left;
        padding-left: 0px;
        color: #b4890d;
        width: 5%;
    }

    #store-page .control-panel .arrows.arrow-left {
        background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        text-align: right;
        padding-right: 0px;
        color: #b4890d;
        width: 5%;
    }


    .small-lootbox-container .lootbox-pricing {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }


    #store-page #left-side .lower-part {
        margin-right: auto;
        margin-left: auto;
        /*margin-bottom: 0;*/
        /*width: 100%;*/
    }

    #store-page #left-side #purchase-currency-wrap {
        width: 50%;
        justify-content: space-around;
    }

    #store-page #left-side #purchase-currency-content {
        height: 100%;
        background-color: sandybrown;
        border-radius: 30px;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) and (max-width: 1024px) {
    .lootbox-purchase-info-tooltip {
        display: none;
    }
}

@media screen and (max-height: 1440px) and (min-width: 426px) {
    #store-page #left-side .upper-part {
        align-items: center;
        margin-bottom: 10%;
        width: 100%;
    }
}

@media screen and (min-width: 5121px) {

    #browser-router #header-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: none;
        position: absolute;
        top: 2px;
        background-size: 100% auto;
        max-width: none;
        height: calc(187px * 3.55);

    }
}

@media screen and (max-aspect-ratio: 384 / 265) and (min-aspect-ratio: 426 / 539) and (max-width: 1251px) {
    #store-page #left-side .lower-part {
        margin: 0 auto;
        /* padding-left: 10%; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 80%;
    }
}

@media (max-aspect-ratio: 340/185) and (min-aspect-ratio: 17 / 23) {
    #store-page #right-side {
        min-width: 5vw;
    }
}