.faction-box-showcase.container.main {
    width: 95%;
    justify-content: center;
    height: 90%;
    background-color: #c9b5d130;
    margin: auto;
}

.faction-box-showcase.container.main .container {
    /* flex-wrap: wrap; */
    /* justify-content: end; */
}


.faction-box-showcase.container.skin div div {
    background-position: 85% 85%;
}
.faction-box-showcase.container.weapon div div {
    background-position: -70% 105%;
}
.faction-box-showcase.container.body div div {
    background-position: 80% 130%;
    background-size: 130%;
}
.faction-box-showcase.container.head div div {
    background-position: 70% -25%;
    background-size: 140%;
}
.faction-box-showcase {
    width: 100%;
    height: 100%;
    background-size: 120%;
    background-position: center;
    background-repeat: no-repeat;
}
.background-store-popup {
    height: 31%;
    width: 100%;
}
.store-popup-container{
    width: 100%;
    display: flex;
    justify-content: center;
    aspect-ratio: 3/3.1;
}
.store-popup-text {
    font-size: 1vw;
    padding: 1% 0 0 3%;
    display: flex;
}

.store-popup-driver {
    background: linear-gradient(90deg, rgba(244, 244, 244, 0) 5%, rgba(250, 250, 250, 0.64) 30%, rgba(255, 255, 255, 0.75) 50%, rgba(251, 251, 251, 0.64) 70%, rgba(251, 251, 251, 0) 95%);
    width: 100%;
    height: 3px;
    margin-top: 3%;
    margin-bottom: 4%;
}
.popup-bottom-border {
    width: 100%;
    height: 3%;
    background-color: rgba(16, 10, 31, 0.96);
}
.popup-text-color-red {
    color: #ff496b;
}
.popup-text-color-green {
    color: #b8ce67 ;
}
.popup-text-color-blue {
    color: #42daf4;
}
.popup-text {
    color: #ffddc1;
    padding-left: 1%;
}