#full-size-carousel-buttons .carousel-button {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 15%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    z-index: 12;
    opacity: 90%;
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_normal.png");
    cursor: pointer;
}

#full-size-carousel-buttons .carousel-button:hover {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_hower.png");
    opacity: 95%;
}

#full-size-carousel-buttons .carousel-button.disabled {
    background-image: url("https://asset-host.b-cdn.net/assets/inventory/arrow-portal/arrow_portal_disable.png");
    cursor: not-allowed;
}

#full-size-carousel-buttons .carousel-button#carousel-right {
    margin-right: 0;
    margin-left: auto;
}

#full-size-carousel-buttons .carousel-button#carousel-left {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#full-size-carousel-buttons .carousel-button.disabled:hover {
    opacity: 80%;
}

#carousel-wrapper #portal-front {
    width: 877px;
    height: 669px;
    margin-top: 35px;
    margin-bottom: -800px;
    z-index: 6;
    transition: all 0.6s ease;
    background-size: contain;
    background-repeat: no-repeat;
}

#duel-result .carousel-wrapper #portal-front {
    width: 565px;
    height: 543px;
    margin-top: 0;
    margin-bottom: -702px;
    z-index: 6;
    transition: all 0.6s ease;
}

#carousel-wrapper #banan-full-size,
#carousel-wrapper #banan-full-size .assembly {
    width: 762px;
    height: calc(762px / 103 * 115);
}

@media screen and (max-width: 1400px) {
    #carousel-wrapper #banan-full-size, #carousel-wrapper #banan-full-size .assembly {
        width: 565px;
        height: calc(565px / 103 * 115);
    }

    #carousel-wrapper #portal-front {
        width: 565px;
        height: 543px;
        margin-bottom: -650px;
    }
}

@media screen and (max-width: 725px){
    #carousel-wrapper #banan-full-size, #carousel-wrapper #banan-full-size .assembly {
        width: 400px;
        height: calc(400px / 103 * 115);
    }

    #carousel-wrapper #portal-front {
        width: 400px;
        height: 385px;
        margin-bottom: -460px;
    }

}

@media screen and (max-width: 725px){
    #carousel-wrapper #banan-full-size, #carousel-wrapper #banan-full-size .assembly {
        width: 340px;
        height: calc(340px / 103 * 115);
    }

    #carousel-wrapper #portal-front {
        width: 340px;
        height: calc(340px / 565 * 543);
        margin-bottom: -400px;
    }

}

@media screen and (max-width: 370px){
    #carousel-wrapper #banan-full-size, #carousel-wrapper #banan-full-size .assembly {
        width: 260px;
        height: calc(260px / 103 * 115);
    }

    #carousel-wrapper #portal-front {
        width: 260px;
        height: calc(260px / 565 * 543);
        margin-bottom: -290px;
    }
}

/*@media (max-aspect-ratio: 340 / 185) and (min-aspect-ratio: 17 / 23) {*/
    /*#full-size-carousel-buttons {
        margin-top: 22vw;
        margin-bottom: 22vw;
        margin-left: 10vw;
        height: 5.5vw;
        width: 31vw;
    }*/
/*}*/

/*@media screen and (max-aspect-ratio: 17 / 23) {*/
    /*#full-size-carousel-buttons {
        margin-bottom: auto;
        margin-top: 30vw;
        margin-left: 16.5vw;
        width: 42vw;
        height: 8.5vw;
    }*/
/*}*/